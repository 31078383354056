<template>
  <div :class="
    !['BusinessProfile', 'Home'].includes(currentRoute)
      ? ['']
      : ['col-md-12', '']
  " class="col-12 px-0">
    <div class="widget-container">
      <div class="row cats-parent">
        <div class="container px-0 my-4 text-center">
          <div class="row">
            <div class="action-prev">
              <button class="main-fav-btn in-result
                pointer " @click="swiper.slidePrev()">
                <i class="fa fa-chevron-left" @click="swiper.slidePrev()"></i>
              </button>
            </div>
            <div class="col-12 px-0">
              <Swiper class="swiper px-5" ref="categoriesSlider" :options="swiperOptions"
                :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                <SwiperSlide v-for="(cat, index) in productCategories" :key="index">
                  <div class="pointer cat-badge border-eff" :class="productCategory == cat.id ? 'active' : ''"
                    @click="chooseCat(cat.id, cat.name)">
                    <span class="cat-badge-text text-ellipsis">{{ cat.name }}</span>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div class="action-next">
              <button class="main-fav-btn in-result pointer " @click="
              swiper.slideNext()">
                <i class="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { helpers } from "../../Helpers";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { pluginOptions } from "/vue.config";

export default {
  name: "SideFilter",
  props: ["productCategories", "pageReset"],
  mixins: [helpers],
  components: {
    Swiper,
    SwiperSlide,
  },
  destroyed() {
    // const catsParent = document.querySelector('.cats-parent');
    window.removeEventListener('scroll', this.togller)
    const appHeader = document.querySelector('.app-header');
    appHeader.classList.remove('active-menu')
  },
  mounted() {
    window.addEventListener('scroll', this.togller)
    if (this.productCategories) {
      this.chooseCat(
        this.productCategories[0].id,
        this.productCategories[0].name
      );
    }
    if (this.businessHome) {
      this.chooseCat(this.outerCat.id, this.outerCat.name);
    }
  },

  watch: {
    productCategories: function (oldCats, newCats) {
      this.chooseCat(
        this.productCategories[0].id,
        this.productCategories[0].name
      );
    },
    outerCat: function (newCat, oldCat) {
      if (oldCat !== newCat) {
        this.chooseCat(newCat.id, newCat.name);
      }
    },
    $route() {
      // if (nr.name == "BusinessProducts") {
      //   window.addEventListener('scroll', this.togller)
      // } else {
      window.removeEventListener('scroll', this.togller)
      const catsParent = document.querySelector('.cats-parent');
      const appHeader = document.querySelector('.app-header');
      catsParent.classList.remove('active');
      appHeader.classList.remove('active-menu')
      // }
    }
  },
  data() {
    return {
      page: 1,
      catPage: 1,
      productCategory: 0,
      swiperOptions: {
        autoplay: false,
        slidesPerView: "auto",
        spaceBetween: 10,
      },
    };
  },

  methods: {
    ...mapActions(["getSearchResults", "getBusinessProducts"]),
    togller() {
      const appHeader = document.querySelector('.app-header');
      const cats = document.querySelector('.widget-container');
      const catsParent = document.querySelector('.cats-parent');
      if (cats && cats.getBoundingClientRect().top <= 0) {
        catsParent.classList.add('active');
        appHeader.classList.add('active-menu')
      } else {
        catsParent.classList.remove('active');
        appHeader.classList.remove('active-menu')
      }
    },
    chooseCat(id, name) {
      this.getBusinessProducts({
        business_id: this.$route.params.id,
        category_id: id,
        lang: this.$i18n.locale,
      })
        .then((res) => {
          this.productCategory = id;
          this.pageReset(name);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  computed: {
    ...mapGetters(["cities", "categories", "outerCat", "width"]),
    locale() {
      return this.$i18n.locale;
    },
    currentRoute() {
      return this.$route.name;
    },
    swiper() {
      return this.$refs.categoriesSlider.$swiper;
    },
    businessHome() {
      return pluginOptions.businesses &&
        pluginOptions.businesses[location.hostname]
        ? pluginOptions.businesses[location.hostname]
        : null;
    },
  },
};
</script>

<style scoped>
.my-toggle {
  margin: 20px;
}

@media (min-width: 992px) {
  .rtl-body .col-md-offset-2 {
    margin-right: 16.66666667%;
    margin-left: 16.66666667%;
  }
}
</style>
