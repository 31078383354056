<template>
  <div class="toast-msg px-0" v-if="msgOptions.status" :class="[
    containType,
    $i18n.locale,
    startAnimation ? 'active' : '',
    !msgOptions.close ? $i18n.locale == 'ar' ? 'pl-3' : 'pr-3' : '',
    msgOptions.condition == 3 ? 'privacy' : ''
  ]">
    <div class="row flex-column container px-0">
      <div class="msg-closer" :class="$i18n.locale" v-if="!msgOptions.close && !msgOptions.loaderClass"
        @click="closeMsgModal">
        <i class="fa fa-times" @click="closeMsgModal"></i>
      </div>
      <div class="col-12 ">
        <div
          :class="[$i18n.locale == 'ar' ? 'text-right' : 'text-left', $i18n.locale == 'ar' ? '' : 'flex-row-reverse', msgOptions.loaderClass ? 'align-items-center' : 'align-items-start']"
          class="d-flex  justify-content-end">
          <span :class="[width == 'sm' ? 'msg-desc' : 'msg-desc', 'px-1',]" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            v-if="msgOptions.condition != 3"> {{ msgOptions.text }} </span>
          <i class="fa mt-1 fa-check-circle" v-if="msgOptions.type == 'success'"></i>
          <div :class="['spinner-border', `text-${msgOptions.loaderClass}`]" v-else-if="msgOptions.type == 'loader'"
            role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <i class="fa mt-1 fa-warning" v-else-if="msgOptions.type == 'error'"></i>
          <i class="fa mt-1 fa-info-circle" v-else-if="msgOptions.type == 'warning'"></i>
        </div>
        <div v-if="msgOptions && msgOptions.condition == 3" :class="[$i18n.locale == 'ar' ? 'text-right' : 'text-left']"
          class="my-1 px-2 msg-desc" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"> {{ msgOptions.desc }}
          <!-- <div class="data-label text-underline pointer" >{{$t('LearnMore')}}</div> -->
        </div>
      </div>
      <div class="col-12 px-0" :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'">
        <div class="container mt-2 px-0 d-flex align-items-center">
          <button :class="msgOptions.condition == 3 ? 'btn-pri-sm' : 'btn-def-sm'" v-if="msgOptions.condition == 3"
            @click="$router.push({ name: 'Privacy' })"> {{ $t("privacy") }} </button>
          <button class="btn-def-sm px-0 py-1 " v-if="
            (msgOptions.condition == 6 ||
              msgOptions.condition == 4 ||
              msgOptions.condition == 5 ||
              msgOptions.condition == 7) && msgOptions.action != 'goCheckout'
          " @click="closeMsg(1)"> {{ $t("Close") }} </button>
          <button class="btn-def-sm px-0 py-1" v-if="
          
            msgOptions.condition == 4 && msgOptions.action == 'goCheckout'
          " @click="closeMsg(1); $router.push({ name: 'CheckOut' })"> {{ $t("goCheckout") }} </button>
          <button class="px-0 py-1" :class="msgOptions.condition == 3 ? 'btn-pri-sm' : 'btn-def-sm'"
            v-if="msgOptions.close || msgOptions.action == 'goCheckout'" @click="closeMsgModal"> {{ msgOptions.action ==
                'goCheckout' ? $t("clearAnyway") : $t('accept')
            }} </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import DownloadApp from "@/components/misc/DownloadApp";
import { pluginOptions } from "/vue.config";

export default {
  data() {
    return {
      expand: false,
      startAnimation: false
    };
  },
  components: {
    DownloadApp,
  },
  props: ["showModal"],

  mounted() {
    setTimeout(() => {
      this.startAnimation = true
    }, 200);
  },
  computed: {
    ...mapGetters(["msgOptions", "width"]),
    containType() {

      if (this.msgOptions.type == "success") {
        return "msg-contain-success";
      } else if (this.msgOptions.type == "error") {
        return "msg-contain-error";
      } else if (this.msgOptions.type == "warning") {
        return "msg-contain-warning";
      } else {
        return "msg-contain";
      }

    },
    businessHome() {
      return pluginOptions.businesses &&
        pluginOptions.businesses[location.hostname]
        ? pluginOptions.businesses[location.hostname]
        : null;
    },
    attachOrderRoute() {
      return this.$route.name == "AttachOrder";
    },
  },
  methods: {
    ...mapActions(["closeMsg"]),
    ...mapMutations(["showMsg"]),
    closeMsgModal() {
      this.closeMsg(this.msgOptions.condition)
        .then((res) => {
          if (res == 4) {
            $("#productModal").modal("hide");
            $("#profileModal").modal("hide");
            if (!this.businessHome && !this.attachOrderRoute && this.msgOptions.action) {
              this.$router.go(-1);
            }
          } else if (res == 6) {
            $("#orderModal").modal("hide");
          }
        })
        .catch((err) => {
          console.log(err);
          this.showMsg({
            close: false,
            text: this.$t("someWrong"),
            type: "error",
          });
        });
    },
  },
};
</script>