import Axios from 'axios';
import cookies from 'vue-cookies';

const auth = {
  state: {
    user: {},
    tempUser: {},
    loggedIn: false,
    isVerified: false,
    favourites: [],
  },

  getters: {
    loggedIn: (state) => state.loggedIn,
    isVerified: (state) => state.isVerified,
    user: (state) => state.user,
    tempUser: (state) => state.tempUser,
    favourites: (state) => state.favourites,
    
  },

  mutations: {
    setUser(state, payload) {
      state.user = {...payload};
      cookies.set('user_token', payload.api_token);
      cookies.set('is_verified', payload.is_verified);
      cookies.remove('guest');
      cookies.remove('addressId');
      cookies.remove('orders');
    },
    setTempUser(state, payload) {
      state.tempUser = {...payload};
      // cookies.set('user_token', payload.api_token);
    },
    setLoggedIn(state) {
      state.loggedIn = cookies.get('user_token') && cookies.get('user_token') != 'undefined'  ? true : false;
      state.isVerified = cookies.get('is_verified') == 1 && cookies.get('is_verified') != 'undefined' ? true : false;
    },
    setFavourites(state, payload) {
      state.favourites = [...payload];
    },
    addFavouriteItem(state, payload) {
      state.favourites.push(payload);
    },
    deleteFavouriteItem(state, payload) {
      let index = state.favourites.indexOf(state.favourites.find(el => el.id == payload));
      state.favourites.splice(index, 1);
    }
  },

  actions: {
    userLogin({commit, dispatch}, payload) {
      if (cookies.isKey('orders')) {
        var items = JSON.parse(cookies.get('orders'));
      }else {
        var items = [];
      }
      return new Promise((resolve, reject) => {
        Axios.post(`login`, {
          phone: payload.phone,
          phone_code: payload.phone_code,
          email: payload.email,
          password: payload.password
        }).then(res => {
          if (res.data.success == true) {
            if(items && items.length > 0) {
              dispatch('moveOrdersfromGuest', {
                user_id: res.data.data.id,
                api_token: res.data.data.api_token,
                order_ids: items
              }).then(response => {
              }).catch(err => console.log(err))
            }
            commit('setUser', res.data.data);
            commit('setLoggedIn');
            resolve(res.data.data);
          }else reject(res);
        }).catch(err => reject(err));
      })
    },
    retrieveUser({commit}, payload) {
      // console.log(cookies.get('user_token'));
      let api_token = payload || cookies.get('user_token');
      return new Promise((resolve, reject) => {
        Axios.get(`user`, {
          params: {
            api_token
          }
        })
        .then(res => {
          if (res.data.success == true) {
            commit('setUser', res.data.data);
            commit('setLoggedIn');
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err));
      })
    },
    userRegisterPhone({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`registerPhone`, {
          phone: payload.phone,
          phone_code: payload.phone_code,
        }).then(res => {
          if(res.data.success == true) {
            commit('setTempUser', res.data.data);
            resolve(res);
          }else reject(res)
        }).catch(err => reject(err))
      })
    },
    userRegisterEmail({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`registermail`, {
          email: payload,
        }).then(res => {
          if(res.data.success == true) {
            commit('setTempUser', res.data.data);
            resolve(res);
          }else reject(res)
        }).catch(err => reject(err))
      })
    },
    verifyUser({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`verifyAccount`, {
          otp: payload.otp,
          email: payload.email,
          phone: payload.phone,
          phone_code: payload.phone_code,
        }).then(res => {
          if(res.data.success == true) {
            commit('setUser', res.data.data)
            resolve(res);
          }else reject(res);  
        }).catch(err => reject(err));
      })
    },
    updateProfile({commit}, payload) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('api_token', payload.api_token);
        payload.password ? formData.append('password', payload.password) : '';
        // formData.append('old_password', payload.old_password);
        payload.name ? formData.append('name', payload.name) : '';
        payload.phone ? formData.append('phone', payload.phone) : '';
        payload.phone_code ? formData.append('phone_code', payload.phone_code) : '';
        payload.email && payload.oldEmail !== payload.email ? formData.append('email', payload.email) : '';
        payload.image ? formData.append('image', payload.newImage) : '';
        
      Axios.post(`register`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
        .then(res => {
          if( res.data.success == true ) {
              commit('setUser', res.data.data);
              resolve(res);
            }else reject(res)
          }).catch(err => reject(err))
      })
    },
    forgetPassword({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post()
      })
    },
    socialLogin({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post()
      })
    },
    getUserFavourites({commit}, payload) {
      return new Promise((resolve, reject) => {
        if(payload.api_token) {
          Axios.get(`favorites?api_token=${payload.api_token}&lang=${payload.locale}` ).then(res => {
            if(res.data.success == true) {
              commit('setFavourites', res.data.data)
              resolve(res);
            }else reject(res)
          }).catch(err => reject(err));
        }else resolve('no Token')
      })
    },
    isFavourite({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.get(`favorites/exist?api_token=${payload.api_token}`, {
          params: {
            business_id: payload.business_id,
            product_id: payload.product_idو          
          }
        }).then(res => {
          if(res.data.success == true) {
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err));
      });
    },
    addFavourite({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`favorites?api_token=${payload.api_token || cookies.get('user_token')}`, null, {
            params: {
              buisness_id: payload.business_id,
              product_id: payload.product_id,
            }
        })
        .then(res => {
          if(res.data.success == true) {
            commit('addFavouriteItem', res.data.data);
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err))
      })
    },
    deleteFavourite({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.delete(`favorites/${payload.id}?api_token=${payload.api_token || cookies.get('user_token')}`)
        .then(res => {
          if(res.data.success == true) {
            commit('deleteFavouriteItem', payload.id);
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err))
      })
    },
    getUserAddresses({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`listAddresses`, {
          api_token: payload || cookies.get('user_token')
        }).then(res => {
          if(res.data.success == true) {
            commit('setUserAddresses', res.data.data);
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err))
      })
    },
    
    userLogout({commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`logout`, {
          device_id: payload.device_id,
          api_token: payload.api_token || cookies.get('user_token')
        }).then(res => {
          if(res.data.success == true) {
            cookies.remove('user_token');
            cookies.remove('is_verified');
            cookies.remove('addreessId');
            cookies.remove('defaultAddressId');
            dispatch('getLoggedIn');
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err));
      })
    },
    getLoggedIn({commit}) {
      commit('setLoggedIn');
    },
    moveOrdersfromGuest({commit}, payload) {
      return new Promise((resolve, reject) => {
        if(payload.guest_token || cookies.get('guest')) {
          Axios.post(`moveOrders`, {
            to_user_id: payload.user_id,
            api_token: payload.guest_token || cookies.get('guest'),
            order_ids: [...payload.order_ids]
          }).then(res => {
            if (res.data.success == true) {
              resolve(res);
            }else reject(res);
          }).catch(err => reject(err));
        }else resolve('No Token Found');
      })
    },
  },


}

export default auth;