var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.msgOptions.status)?_c('div',{staticClass:"toast-msg px-0",class:[
  _vm.containType,
  _vm.$i18n.locale,
  _vm.startAnimation ? 'active' : '',
  !_vm.msgOptions.close ? _vm.$i18n.locale == 'ar' ? 'pl-3' : 'pr-3' : '',
  _vm.msgOptions.condition == 3 ? 'privacy' : ''
]},[_c('div',{staticClass:"row flex-column container px-0"},[(!_vm.msgOptions.close && !_vm.msgOptions.loaderClass)?_c('div',{staticClass:"msg-closer",class:_vm.$i18n.locale,on:{"click":_vm.closeMsgModal}},[_c('i',{staticClass:"fa fa-times",on:{"click":_vm.closeMsgModal}})]):_vm._e(),_c('div',{staticClass:"col-12 "},[_c('div',{staticClass:"d-flex  justify-content-end",class:[_vm.$i18n.locale == 'ar' ? 'text-right' : 'text-left', _vm.$i18n.locale == 'ar' ? '' : 'flex-row-reverse', _vm.msgOptions.loaderClass ? 'align-items-center' : 'align-items-start']},[(_vm.msgOptions.condition != 3)?_c('span',{class:[_vm.width == 'sm' ? 'msg-desc' : 'msg-desc', 'px-1' ],attrs:{"dir":_vm.$i18n.locale == 'ar' ? 'rtl' : 'ltr'}},[_vm._v(" "+_vm._s(_vm.msgOptions.text)+" ")]):_vm._e(),(_vm.msgOptions.type == 'success')?_c('i',{staticClass:"fa mt-1 fa-check-circle"}):(_vm.msgOptions.type == 'loader')?_c('div',{class:['spinner-border', ("text-" + (_vm.msgOptions.loaderClass))],attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):(_vm.msgOptions.type == 'error')?_c('i',{staticClass:"fa mt-1 fa-warning"}):(_vm.msgOptions.type == 'warning')?_c('i',{staticClass:"fa mt-1 fa-info-circle"}):_vm._e()]),(_vm.msgOptions && _vm.msgOptions.condition == 3)?_c('div',{staticClass:"my-1 px-2 msg-desc",class:[_vm.$i18n.locale == 'ar' ? 'text-right' : 'text-left'],attrs:{"dir":_vm.$i18n.locale == 'ar' ? 'rtl' : 'ltr'}},[_vm._v(" "+_vm._s(_vm.msgOptions.desc)+" ")]):_vm._e()]),_c('div',{staticClass:"col-12 px-0",class:_vm.$i18n.locale == 'ar' ? 'text-left' : 'text-right'},[_c('div',{staticClass:"container mt-2 px-0 d-flex align-items-center"},[(_vm.msgOptions.condition == 3)?_c('button',{class:_vm.msgOptions.condition == 3 ? 'btn-pri-sm' : 'btn-def-sm',on:{"click":function($event){return _vm.$router.push({ name: 'Privacy' })}}},[_vm._v(" "+_vm._s(_vm.$t("privacy"))+" ")]):_vm._e(),(
          (_vm.msgOptions.condition == 6 ||
            _vm.msgOptions.condition == 4 ||
            _vm.msgOptions.condition == 5 ||
            _vm.msgOptions.condition == 7) && _vm.msgOptions.action != 'goCheckout'
        )?_c('button',{staticClass:"btn-def-sm px-0 py-1 ",on:{"click":function($event){return _vm.closeMsg(1)}}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")]):_vm._e(),(
        
          _vm.msgOptions.condition == 4 && _vm.msgOptions.action == 'goCheckout'
        )?_c('button',{staticClass:"btn-def-sm px-0 py-1",on:{"click":function($event){_vm.closeMsg(1); _vm.$router.push({ name: 'CheckOut' })}}},[_vm._v(" "+_vm._s(_vm.$t("goCheckout"))+" ")]):_vm._e(),(_vm.msgOptions.close || _vm.msgOptions.action == 'goCheckout')?_c('button',{staticClass:"px-0 py-1",class:_vm.msgOptions.condition == 3 ? 'btn-pri-sm' : 'btn-def-sm',on:{"click":_vm.closeMsgModal}},[_vm._v(" "+_vm._s(_vm.msgOptions.action == 'goCheckout' ? _vm.$t("clearAnyway") : _vm.$t('accept'))+" ")]):_vm._e()])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }