<template>
  <div class="no-places mb-4" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
    <img v-if="loading || modalLoading || profileLoading || imgWith" height="120px"
      :src="img || require('../../assets/images/logo/loading.gif')" />
    <h2 v-if="!loading && !modalLoading && !profileLoading">{{ msg }}</h2>
    <h2 v-else>{{ $t("Waiting For The Data") }}</h2>
    <!-- <button class="btn-pri-lg">{{btnMsg}}</button> -->
  </div>
</template>

<script>
export default {
  props: ["msg", "btnMsg", "imgWith", "img"],
  computed: {
    isMobile() {
      return this.$store.getters.width == "sm";
    },
    loading() {
      return this.$store.getters.loading;
    },
    profileLoading() {
      return this.$store.getters.profileLoading;
    },
    modalLoading() {
      return this.$store.getters.modalLoading;
    },
  },
};
</script>