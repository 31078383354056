import instance from '../Helpers/PaypalInstance';

const paypal = {
  state: {
    paypalToken: null,
  },

  getters: {
    paypalToken: (state) => state.paypalToken,
  },

  mutations: {
    setToken(state, payload) {
      state.paypalToken = payload;
    }
  },

  actions: {
    createOrder({commit}, payload) {
      return new Promise((resolve, reject) => {
        instance.post(`v2/checkout/orders`, {
          intent: 'CAPTURE',
          purchase_units: payload.purchase_units
        })
          .then(res => {
            if(res.data.status == 'CREATED') {
              resolve(res);
            }else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createPayment({commit}, payload) {
      return new Promise((resolve, reject) => {
        instance.post(`v1/payments/payment`, {
          intent: 'sale',
          payer: {
            payment_method: payload.payment_method,
          },
          transactions: payload.transactions,
          redirect_urls: payload.redirect_urls
        })
          .then(res => {
            if(res.data.state == 'created') {
              resolve(res);
            }else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    showOrderDetails({commit}, payload) {
      return new Promise((resolve, reject) => {
        instance.get(`v2/checkout/orders/${payload}`)
          .then(res => {
            if(res.data.status == 'COMPLETED') {
              resolve(res);
            }else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    getToken({commit}, payload) {
      const params = new URLSearchParams();
      params.append('grant_type', 'client_credentials');
      return new Promise((resolve, reject) => {
        instance.post('v1/oauth2/token', params, {
          auth: {
            username: payload.client_id,
            password: payload.client_secret
          },
        }).then(res => {
          commit('setToken', res.data.access_token);
          resolve(res);
        }).catch(err => reject(err))
      })
    },
    captureOrder({commit}, payload) {
      return new Promise((resolve, reject) => {
        instance.post(`v2/checkout/orders/${payload.id}/capture`, null, {
          headers: {
            'content-type': 'application/json',
            // 'Authorization': `Bearer ${payload.access}`
          }
        }).then(res => {
          console.log(res)
          if(res.data.status == "COMPLETED") {
            resolve(res);
          }else reject(res)
        }).catch(err => reject(err))
      })
    },
    executePayment({commit}, payload) {
      return new Promise((resolve, reject) => {
        instance.post(`v1/payments/payment/${payload.id}/execute`, {
          payer_id: payload.payer_id,
        })
          .then(res => {
            resolve(res)
          }).catch(err => reject(err))
      })
    },
    executeRequest({commit}, payload) {
      return new Promise((resolve, reject) => {
        instance.get(`${payload}`, {
          // payer_id: payload.payer_id,
        })
          .then(res => {
            resolve(res)
          }).catch(err => reject(err))
      })
    }
  }
}


export default paypal;