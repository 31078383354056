
import Home from '../views/Home.vue';

export default [
  {
    path: '',
    name: 'Home',
    component: Home,
    meta: {
      requireAuth: 'none',
    },
  },
  {
    path: 'about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: 'privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: 'login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../components/forms/UserLogin.vue'),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: 'verify-account',
    name: 'VerifyAccount',
    component: () => import(/* webpackChunkName: "login" */ '../components/forms/VerifyAccount.vue'),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: 'register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../components/forms/Register.vue'),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: 'directory',
    name: '',
    component: () => import(/* webpackChunkName: "listing" */ '../views/SearchResults.vue'),
    children: [
      {
        path: '',
        name: 'SearchResults',
        component: () => import(/* webpackChunkName: "listing" */ '../components/Listings/SearchList.vue')
      },
    ]
  },
  {
    path: 'bc-:categoryId?',
    name: 'CategoryId',
    component: () => import(/* webpackChunkName: "listing" */ '../views/SearchResults.vue'),
    redirect: {
      name: 'CategoryFetch',
    },
    children: [
      {
        path: '',
        name: 'CategoryFetch',
        component: () => import(/* webpackChunkName: "listing" */ '../components/DataFetchers/CategoryFetch.vue')
      },
      {
        path: ':CName?',
        name: 'CategoryList',
        component: () => import(/* webpackChunkName: "listing" */ '../components/Listings/SearchList.vue')
      },
    ]
  },

  {
    path: 'all-categories',
    name: 'AllCategories',
    component: () => import(/* webpackChunkName: "categories" */ '../views/AllCategories.vue')
  },
  // {
  //   path: ':id(\\d+)/reserve',
  //   name: 'reserve',
  //   component: () => import(/* webpackChunkName: "forms" */ '../components/order/Reserve.vue'),
  // },
  {
    path: ':id(\\d+)/checkin',
    name: 'Checkin',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/ReservationLayout.vue'),
  },
  {
    path: ':id(\\d+)/reservation',
    name: 'Reservation',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/ReservationLayout.vue'),
  },
  {
    path: ':id(\\d+)/donate',
    name: 'Donate',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/ReservationLayout.vue'),
  },
  {
    path: 'tickets',
    name: 'Tickets',
    component: () => import(/* webpackChunkName: "reservation" */ '../components/order/Tickets.vue'),
  },
  {
    path: 'order-attach',
    name: 'AttachOrder',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/AttachOrderLayout.vue'),
  },
  {
    path: ':id(\\d+)/dinein/:hallName/:tableId(\\d+)',
    name: 'DineIn',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/AttachOrderLayout.vue'),
  },
  // {
  //   path: ':id(\\d+)/:BName?',
  //   name: 'BusinessInfoByName',
  //   component: () => import(/* webpackChunkName: "profile" */ '../views/BusinessInfoByName.vue')
  // },
  {
    path: ':id(\\d+)',
    name: 'BusinessProfile',
    component: () => import(/* webpackChunkName: "profile" */ '../components/misc/BusinessWraper.vue'),
    redirect: {
      name: 'BusinessFetch'
    },
    children: [
      {
        path: 'view/',
        name: 'BusinessFetch',
        component: () => import(/* webpackChunkName: "profile" */ '../components/DataFetchers/BusinessFetch.vue')
      },
      {
        path: ':BName?/:tabID?/:TName?/:preventer?',
        name: 'BusinessInfo',
        component: () => import(/* webpackChunkName: "profile" */ '../views/BusinessProfile.vue')
      },
      {
        path: ':BName?/:tabID/products',
        name: 'BusinessProducts',
        component: () => import(/* webpackChunkName: "profile" */ '../views/BusinessProfile.vue'),
        children: [
          {
            path: 'pid-:productId(\\d+)',
            name: 'ProductWrapper',
            component: () => import(/* webpackChunkName: "product" */ '../components/misc/BusinessWraper.vue'),
            redirect: {
              name: 'ProductFetch',
            },
            children: [
              {
                path: '',
                name: 'ProductFetch',
                component: () => import(/* webpackChunkName: "product" */ '../components/DataFetchers/ProductFetch.vue'),
              },
              {
                path: ':PName',
                name: 'ProductList',
                component: () => import(/* webpackChunkName: "product" */ '../components/order/ProductDetails.vue'),

              }
            ]
          },

        ]
      },
    ]
  },
  // {
  //   path: 'product/:id',
  //   name: 'Product',
  //   component: () => import(/* webpackChunkName: "profile" */ '../views/BusinessProfile.vue')
  // },
  //access business profile

  {
    path: 'order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "order" */ '../views/OrderLayout.vue'),
    meta: {
      requireAuth: false,
    },
    children: [
      // {
      //   path: 'reservation/:id?',
      //   name: 'Reservation',
      // component: () => import(/* webpackChunkName: "forms" */ '../components/order/Reservation.vue'),
      //
      //
      //
      //Here Is The Other COmponent
      //   component: () => import(/* webpackChunkName: "forms" */ '../components/order/Reserve.vue'),
      // }, 
      {
        path: 'cart',
        name: 'Cart',
        component: () => import(/* webpackChunkName: "cart" */ '../components/order/Cart.vue'),
      },
      {
        path: 'my-orders',
        name: 'MyOrders',
        component: () => import(/* webpackChunkName: "order" */ '../components/order/MyOrders.vue'),
      },
      {
        path: 'checkout',
        name: 'CheckOut',
        component: () => import(/* webpackChunkName: "checkout" */ '../components/order/CheckOut.vue'),
      },
      {
        path: 'payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: "checkout" */ '../components/order/Payment.vue'),
      },
    ]
  },
  {
    path: 'auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "forms" */ '../views/OrderLayout.vue'),
    children: [
      {
        path: 'forgot-password',
        name: 'Forgot',
        component: () => import(/* webpackChunkName: "forms" */ '../components/forms/ForgotPassword.vue'),
      },
      {
        path: 'review',
        name: 'Review',

        component: () => import(/* webpackChunkName: "forms" */ '../components/forms/WriteReview.vue'),
      },
      {
        path: 'issue',
        name: "Issue",
        component: () => import(/* webpackChunkName: "forms" */ '../components/forms/Issue.vue')
      }
    ]
  },
  {
    path: 'profile',
    name: 'UserProfile',
    component: () => import(/* webpackChunkName: "userprofile" */ '../views/ProfileLayout.vue'),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: 'info',
        name: 'ProfileInfo',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileInfo.vue'),
      },
      {
        path: 'settings',
        name: 'ProfileSettings',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileSettings.vue'),
      },
      {
        path: 'business',
        name: 'ProfileBusiness',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileBusiness.vue'),
      },
      {
        path: 'favourites',
        name: 'ProfileFavourites',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileFavourites.vue'),
      },
      {
        path: 'order-history',
        name: 'OrderHistory',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/order/MyOrders.vue'),
      },
      {
        path: 'qr-code',
        name: 'ProfileQR',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileQR.vue'),
      },
      {
        path: 'payment-methods',
        name: 'ProfileMethods',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileMethods.vue'),
      },
      {
        path: 'appintments',
        name: 'ProfileAppintments',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileAppointments.vue'),
      },
      {
        path: 'products',
        name: 'ProfileProducts',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileProducts.vue'),
      },
    ],
  }


];
