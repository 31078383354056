<template>
  <div class="container">
    <div class="row px-3">
      <div class="col-12">
        <div class="row my-3 mt-5">
          <div class="col-12 data-sub" :class="t_ar">
            {{ $t("CookiesUsedOnSite") }}
            <div class="data-label text-underline pointer">
              {{ $t("LearnMore") }}
            </div>
          </div>
        </div>
        <div class="row my-3 mt-5">
          <div class="col-12" :class="t_ar">
            <div class="mb-4 privacy-header">{{ $t("NecessaryCookies") }}</div>
            <p class="data-sub">
              {{ $t("SomeCookiesAreRequired") }}
            </p>
          </div>
        </div>
        <div class="row my-3 mt-5">
          <div class="col-12 table-responsive">
            <table class="table table-borderless">
              <thead>
                <tr class="table-headers">
                  <th scope="col">{{ $t("col_name") }}</th>
                  <th scope="col">{{ $t("hostName") }}</th>
                  <th scope="col">{{ $t("Path") }}</th>
                  <th scope="col">{{ $t("Expiry") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-card my-3">
                  <th>csrf_token</th>
                  <td>beefree.io</td>
                  <td>/</td>
                  <td>Session</td>
                </tr>
                <div class="p-4 px-5 data-label" style="background: white">
                  https:/snawnaw.com/cookies-policy/
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row" : "flex-row-reverse";
    },
    flex_ar_reverse() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "flex-row";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },
};
</script>