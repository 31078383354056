<template>
            <!-- <div class="day">{{event.date.split('-')[2]}}</div>
            <div class="month">{{monthText(event.date.split('-')[2]).abbr}}</div> -->
  <div class="card-item">
    <div class="card-header">
      <img src="../../assets/images/backgrounds/1.jpg" alt="">
      <div class='item-favourite'>
        <i class="fa fa-heart-o"></i>
      </div>
      <div class="item-address">
        <button class='btn-def btn text-ellipsis'>Free</button>
      </div>
    </div>
    <div class="card-details">
      <div class='row'>
        <div class="col-xs-2">
          <div class="details-date text-center">
            Jan
            <span class="details-day">
              12
            </span>
          </div>

        </div>
        <div class="col-xs-10">
          <div 
            class="details-title"
            :class="ar ? 'text-right' : 'text-left'"  
          >
            <span class="text-ellipsis ">sadasdasdasdasdasASDASD</span>
          </div>
          <div 
            class="event-details"
            :class="ar ? 'text-right' : 'text-left'"  
          >
              orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ind
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item'],
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    }
  }

}
</script>