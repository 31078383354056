import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import md5 from 'md5';
import cookies from 'vue-cookies';

const guest = {
  state: {

  },
  
  getters: {

  },

  mutations: {

  },

  actions: {
    checkGuest() {
      return new Promise((resolve, reject) => {
        if (cookies.isKey('guest')) {
          resolve(cookies.get('guest')); 
        }else {
          reject('No Guest');
        }
      })
    },
    makeGuest() {
      return new Promise((resolve, reject) => {
        let key = md5(uuidv4() + navigator.userAgent);

        Axios.post(`/guest/auth`, {
          device_id: key
        }).then(res => {
          cookies.set('guest', res.data.data.token);
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })
    },
    makeSecret(context, payload) {
      return new Promise((resolve, reject) => {
        let secret = md5(`${payload.created_at}|${payload.id}|${payload.payment_id}|${payload.business_id}`);

        resolve(secret);
      })
    }
  },
}

export default guest;