import store from '../store';
import Axios from 'axios';


var profileURLs = (url) => {
  return url.split('/').includes('business')
}
var modalURLs = (url) => {
  return ['showProduct', 'showOrder'].find(el => url.split('?').includes(el)) ? true : false;
} 

export default function setup() {

  Axios.interceptors.request.use(con => {
    if(profileURLs(con.url)) {
      store.dispatch('startProfileLoading');
    }else if(modalURLs(con.url)) {
      store.dispatch('startModalLoading');
      store.commit('setProduct', {})
    }else {
      store.dispatch('startLoading');
    }
    return con;
  }, err => {
    return Promise.reject(err);
  })
  
  
  Axios.interceptors.response.use(res => {
    store.dispatch('stopLoading');
    return res;
  }, err => {
    return Promise.reject(err);
  })
}
