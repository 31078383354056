<template>
  <nav class="
      navbar
      fixed-top
      navbar-expand-lg
      app-header
      p-0
    " style="padding-bottom: 5px !important" :class="locale == 'ar' ? 'flex-row-reverse' : ''">
    <div class="container  p-1 py-2">
      <router-link :to="{ name: 'Home', params: { locale: $i18n.locale } }">
        <a class="navbar-brand p-0" @click="CloseNav()">
          <img v-if="!businessHome" class="home-brand w-100" src="../assets/images/logo/logo3.png" />
          <div v-else>
            <img :src="businessHome.logo" style="width: 50px; vertical-align: middle" class="busi" />
            <!-- <span class="business-logo-name pl-3">{{businessHome.name}}</span> -->
          </div>
        </a>
      </router-link>
      <span class="d-none d-md-block">
        <LocationForm v-if="width != 'sm' && !businessHome" />
      </span>
      <!-- <div v-if="false" class="header-download d-none d-md-block d-lg-none"
        :class="locale == 'ar' ? 'ml-auto' : 'ml-auto'">
        <div class="store-icons row d-flex justify-content-around" v-if="false">
          <i class="fa fa-android pointer mx-3" @click="goStore"></i>
          <i class="fa fa-apple pointer mx-3" @click="goApple"></i>
          <span class="appgallery pointer mx-3" @click="goGallery">
            <img src="../assets/images/logo/appgallery.png" />
          </span>
        </div>
      </div> -->
      <div class="d-flex align-items-center justify-content-start">
        <!-- <Languages v-if="!businessHome" :class="locale == 'ar' ? ['ml-auto', 'mr-md-0'] : ['ml-auto', 'ml-md-0']"
          class="in-header-mob" /> -->
        <Languages :class="locale == 'ar' ? ['ml-auto', 'mr-md-2'] : ['ml-auto', 'ml-md-2']"
          class="in-header-mob d-block d-lg-none" />
        <!-- <Languages :class="locale == 'ar' ? ['ml-auto', 'mr-md-0'] : ['ml-auto', 'ml-md-0']" class="in-header-mob" /> -->
        <div v-if="width == 'md' || width == 'sm'" class="pointer btn-icon-sec-square"
          :class="locale == 'ar' ? ['ml-auto', 'mr-md-0'] : ['ml-auto', 'ml-md-0']"
          @click="$router.push({ name: 'Cart' })">
          <i class="fa fa-shopping-cart"></i>
          <span class="badge">{{ cartItems ? cartItems.length : 0 }}</span>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
          aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa fa-bars mdicon"></i>
        </button>
      </div>
      <div class="collapse navbar-collapse align-items-center justify-content-center pb-2 pb-md-0" id="navbarText">
        <ul class="navbar-nav" :class="[
          locale == 'ar' && width != 'sm' && width != 'md'
            ? 'flex-row-reverse'
            : '',
          businessHome ? 'mx-auto' : '',
        ]">
          <li class="nav-item" @click="CloseNav()">
            <router-link :to="{ name: 'Home', params: { locale: $i18n.locale } }" class="pointer nav-link"
              v-if="width == 'lg'"> {{ $t("Home") }} </router-link>
          </li>
          <li class="nav-item" v-if="!businessHome" @click="CloseNav()">
            <router-link :to="{ name: `SearchResults`, params: { locale: $i18n.locale } }"
              class="pointer nav-link readmore"> {{ $t("Reservations") }} </router-link>
            <!-- <router-link
              :to="{ name: 'SearchResults', params: { locale: $i18n.locale }}"
              class="pointer nav-link"
            >
              {{$t('Reservations')}}
          </router-link> -->
          </li>
          <li v-if="businessHome" class="nav-item" @click="CloseNav()">
            <router-link :to="{ name: 'Privacy', params: { locale: $i18n.locale } }" class="pointer nav-link"> {{
                $t("privacy")
            }} </router-link>
          </li>
          <!-- <li class="nav-item dropdown" v-if="false">
            <a class="nav-link dropdown-toggle pointer" href="#" id="navbarDropdownMenuLink" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               {{$t('languages')}} 
              <span>
                <img class="langs-select" width="20px" :src="flag($i18n.locale)" />
                <span>{{ $i18n.locale.toUpperCase() }}</span>
              </span>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item pointer" v-for="(lang, index) in locales" :key="index">
                <div @click="
  localeSwitch(lang);
CloseNav();
                ">
                  <i width="20px" class="fa fa-circle" v-show="locale == lang"></i>
                  <img class="langs-select" width="20px" :src="flag(lang)" />
                  <span>{{ langText(lang) }}</span>
                </div>
              </a>
            </div>
          </li> -->
          <!-- <li class="nav-item pl-3 pt-2">
            <form @submit.prevent='search(text)' class="row justify-content-center" v-if="$route.name != 'Home' && $route.name != 'Login' && $route.name != 'Register' && $route.name != 'Privacy'">
             <div class="input-group">
               
               <input 
                  :class=" locale == 'ar' ? 'text-right' : 'text-left'"
                  class='form-control' 
                  v-model='text'
                  :placeholder="$t('line1')"  
                />
              <div class="input-group-append">
                 <button class="btn btn-icon-sec-square m-0" style="height: 38px;padding: 0px;" type="submit" id="button-addon1"><i class="fa fa-search"></i></button>               
              </div>
             </div>
                
            </form>
        </li> -->
          <!-- <div class="header-download" v-if="widthPx > 1280 && false">
            <div class="store-icons row d-flex justify-content-around">
              <i class="fa fa-android pointer mx-3" @click="goStore"></i>
              <i class="fa fa-apple pointer mx-3" @click="goApple"></i>
              <span class="appgallery pointer mx-3" @click="goGallery">
                <img src="../assets/images/logo/appgallery.png" />
              </span>
            </div>
          </div> -->
        </ul>
        <Languages :class="locale == 'ar' ? ['ml-auto', 'mr-md-0'] : ['ml-auto', 'ml-md-0']"
          class="in-header-mob d-none d-lg-block" />
        <!-- {{loggedIn}} -->
        <div class="d-flex d-lg-none justify-content-center" :class="locale == 'ar' ? 'flex-row-reverse' : ''">
          <div v-if="profileRoute" class="pointer search-square-btn" @click="searchVisible()">
            <i class="fa fa-search"></i>
          </div>
          <button v-if="myOrders && myOrders.length > 0" class="pointer btn-def justify-content-center px-2 px-xl-4"
            @click="$router.push({ name: 'MyOrders' })"> {{ $t("myOrders") }}({{ myOrders.length }}) </button>
          <button v-if="!loggedIn" class="pointer btn-def justify-content-center px-2 px-xl-4"
            @click="$router.push({ name: 'Login' })"> {{ $t("logIn") }} </button>
          <div v-if="width == 'lg' || width == 'xl'" class="pointer btn-icon-sec-square"
            @click="$router.push({ name: 'Cart' })">
            <i class="fa fa-shopping-cart"></i>
            <span class="badge">{{ cartItems ? cartItems.length : 0 }}</span>
          </div>
          <div class="btn-group" v-if="loggedIn">
            <button class="profile-btn" :class="isVerified ? '' : 'missing-data'" type="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <img class="nav-avatar" :src="user.media && user.media[0] ? user.media[0].url : placeHolder" />
              <i class="fa fa-chevron-down text-dark" />
            </button>
            <div class="dropdown-menu account-menu" :class="t_ar">
              <div class="dropdown-item pointer" @click="$router.push({ name: 'ProfileInfo' })"> {{ $t("Profile") }} <i
                  class="fa fa-warning text-error py-1" :class="f_ar_reverse" v-if="!isVerified"></i>
              </div>
              <div class="dropdown-item pointer" @click="$router.push({ name: 'OrderHistory' })"> {{ $t("myOrders") }}
              </div>
              <div class="dropdown-item pointer" @click="$router.push({ name: 'ProfileAppintments' })"> {{
                  $t("Reservations")
              }} </div>
              <div class="dropdown-item pointer" @click="logout()"> {{ $t("logout") }} </div>
            </div>
          </div>
        </div>
        <MainIcons class="in-header" v-if="width == 'sm'" :inheader="true" />
      </div>
      <div class="justify-content-center d-lg-flex d-none border-bottom-1" :class="locale == 'ar' ? '' : ''">
        <div v-if="profileRoute" class="pointer search-square-btn" @click="searchVisible()">
          <i class="fa fa-search"></i>
        </div>
        <button v-if="myOrders && myOrders.length > 0" class="pointer btn-def justify-content-center px-0 px-xl-4"
          @click="$router.push({ name: 'MyOrders' })"> {{ $t("myOrders") }} ({{ myOrders.length }})</button>
        <button v-if="!loggedIn" class="pointer btn-def justify-content-center px-0 px-xl-4"
          @click="$router.push({ name: 'Login' })"> {{ $t("logIn") }} </button>
        <div v-if="width == 'lg' || width == 'xl'" class="pointer btn-icon-sec-square"
          @click="$router.push({ name: 'Cart' })">
          <i class="fa fa-shopping-cart"></i>
          <span class="badge">{{ cartItems ? cartItems.length : 0 }}</span>
        </div>
        <div class="btn-group" v-if="loggedIn">
          <button class="profile-btn" :class="isVerified ? '' : 'missing-data'" type="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img class="nav-avatar" :src="user.media && user.media[0] ? user.media[0].url : placeHolder" />
            <i class="fa fa-chevron-down text-dark" />
          </button>
          <div class="dropdown-menu account-menu" :class="t_ar">
            <div class="dropdown-item pointer" :class="!isVerified ? 'd-flex align-items-center' : ''"
              @click="$router.push({ name: 'ProfileInfo' })"> {{ $t("Profile") }} <i
                class="fa fa-warning text-error py-1" :class="f_ar_reverse" v-if="!isVerified"></i>
            </div>
            <div class="dropdown-item pointer" @click="$router.push({ name: 'OrderHistory' })"> {{ $t("myOrders") }}
            </div>
            <div class="dropdown-item pointer" @click="$router.push({ name: 'ProfileAppintments' })"> {{
                $t("Reservations")
            }} </div>
            <div class="dropdown-item pointer" @click="logout()"> {{ $t("logout") }} </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { pluginOptions } from "/vue.config";
import LocationForm from "@/components/forms/LocationForm.vue";
import { mapGetters, mapActions } from "vuex";
import cookies from "vue-cookies";
import MainIcons from "./MainIcons.vue";
import Languages from "./Languages.vue";

export default {
  name: "AppHeader",
  components: {
    LocationForm,
    MainIcons,
    Languages
  },
  watch: {
    $route() {
      this.CloseNav();
    },
  },
  data() {
    return {
      text: "",
      city: "",
      placeHolder: require("../assets/images/miscellaneous/user-filler.png"),
    };
  },
  props: ["searchVisible", "searchForm"],
  mounted() {
    this.getMyOrders()
    window.addEventListener("scroll", this.CloseNav);
    this.getLoggedIn();
    // console.log(this.loggedIn)
    if (this.loggedIn) {
      this.retrieveUser(cookies.get("user_token")).then((res) => { });
    }
    const header = document.querySelector(".navbar-expand-lg");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 120 && header) {
        header.style.backgroundColor = "#fff";
        header.style.boxShadow = "0 4px 24px 0 rgba(34, 41, 47, .1)";
      } else {
        header.style.boxShadow = "unset";
        // header.style.backgroundColor = "rgba(255,255,255,.8)";
      }
    });
  },
  computed: {
    ...mapGetters([
      "cartItems",
      "width",
      "widthPx",
      "loggedIn",
      "user",
      "isVerified",
      "userAddresses",
      "myOrders"
    ]),
    profileRoute() {
      return this.$route.name == "BusinessProfile";
    },

    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    locale() {
      return this.$i18n.locale;
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    businessHome() {
      return pluginOptions.businesses &&
        pluginOptions.businesses[location.hostname]
        ? pluginOptions.businesses[location.hostname]
        : null;
    },
  },

  methods: {
    ...mapActions([
      "retrieveUser",
      "userLogout",
      "getLoggedIn",
      "getUserAddresses",
      "getMyOrders",

    ]),
    checkDefaultAddress(add) {
      if (
        add.longitude &&
        add.longitude == this.user.longitude &&
        add.lattitude &&
        add.lattitude == this.user.latitude
      ) {
        return true;
      } else return false;
    },

    goLogin() {
      this.$router.push({
        name: "Login",
      });
    },
    goSignup() {
      this.$router.push({
        name: "Register",
      });
    },
    search(text) {
      if (this.$route.name == "SearchResults") {
        this.$router.replace({
          name: "SearchResults",
          params: {
            text: text,
            cityId: this.cityId,
            categoryId: this.categoryId,
            page: this.page,
            type: this.type,
          },
        });
      } else {
        this.$router.push({
          name: "SearchResults",
          params: {
            text: text,
            cityId: this.cityId,
            categoryId: this.categoryId,
            page: this.page,
            type: this.type,
          },
        });
      }
    },
    CloseNav() {
      $("#navbarText").collapse("hide");
    },
    expand() {
      if ($("#langDropdown").hasClass("open")) {
        $("#langDropdown").removeClass("open");
      } else $("#langDropdown").addClass("open");
    },
    // goStore() {
    //   window.open(
    //     this.businessHome
    //       ? this.businessHome.googlePlay
    //       : "https://play.google.com/store/apps/details?id=com.snawnawapp",
    //     "_blank"
    //   );
    // },
    // goApple() {
    //   window.open(
    //     this.businessHome
    //       ? this.businessHome.ios
    //       : "https://apps.apple.com/us/app/id1530699560",
    //     "_blank"
    //   );
    // },
    // goGallery() {
    //   window.open(
    //     this.businessHome
    //       ? this.businessHome.huawei
    //       : "https://appgallery.huawei.com/#/app/C103728625",
    //     "_blank"
    //   );
    // },

    logout() {
      // console.log(cookies.get('user_token'))
      this.userLogout({
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.$router.go();
          this.showMsg({
            type: "success",
            text: this.$t("logoutSuccessful"),
            close: false,
          });
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>


 