<template>
  <div class="container">
    <div class="row px-3">
      <div class="col-12">
        <div class="row my-3 mt-5">
          <div class="col-12 data-sub" :class="t_ar">
            {{ $t("CookiesUsedOnSite") }}
            <div class="data-label text-underline pointer">
              {{ $t("LearnMore") }}
            </div>
          </div>
        </div>
        <div class="row my-3 mt-5 mx-n4">
          <div class="col-12 d-flex" :class="flex_ar_reverse">
            <button class="btn-pri-square">{{ $t("AllowAllCookies") }}</button>
            <button class="btn-sec-squared">{{ $t("DenyAll") }}</button>
          </div>
        </div>
        <div class="row my-3 mt-5 data-sub">
          <div
            class="col-6 my-3"
            v-for="(setting, index) in settings"
            :key="index"
          >
            <div>
              <div
                class="custom-control custom-switch d-flex"
                :class="[flex_ar_reverse, $i18n.locale == 'ar' ? 'mr-5' : '']"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="index"
                  v-model="setting.value"
                />
                <label
                  class="
                    custom-control-label custom-cont
                    rol-label-switch
                    privacy-header
                  "
                  :for="index"
                  :class="$i18n.locale == 'ar' ? 'custom-control-label-ar' : ''"
                >
                  <span class="mx-2">{{ setting.label }}</span>
                </label>
              </div>

              <p class="px-5 my-3" :class="t_ar">
                {{ settingsText(index) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: [
        { value: true, label: this.$t("settingLabel1") },
        { value: true, label: this.$t("settingLabel2") },
        { value: true, label: this.$t("settingLabel3") },
        { value: true, label: this.$t("settingLabel4") },
      ],
    };
  },

  methods: {
    settingsText(id) {
      let allText = [
        this.$t("setting1"),
        this.$t("setting2"),
        this.$t("setting3"),
        this.$t("setting4"),
      ];

      return allText[id];
    },
  },

  computed: {
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row" : "flex-row-reverse";
    },
    flex_ar_reverse() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "flex-row";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },
};
</script>