<template>
  <div id="app" class="pt-5 mt-4">
    <main :class="[myWidth == 'sm' ? 'pb-9' : '']" class="pb-3">
      <AppHeader
        :searchVisible="searchVisible"
        :class="$route.name != 'Home' ? '' : 'nav-layout'"
      />
      <!-- v-if="$route.name != 'BusinessProfile'" -->
      <div
        class="container py-3"
        v-if="!businessHome && $route.name != 'Home' && profileRouteBtn()"
        :class="$route.name != 'Home' ? ['nav-sec'] : ''"
      >
        <form @submit.prevent="search(text)" class="row justify-content-center">
          <div class="search-contain-sec px-0 m-0 col-sm-8">
            <div class="input-group">
              <input
                :class="locale == 'ar' ? 'text-right' : 'text-left'"
                class="form-control home-text-input"
                v-model="text"
                :placeholder="$t('line1')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-icon-sec-square m-0"
                  style="width: 70px; height: 50px"
                  type="submit"
                  id="button-addon1"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div
          class="modal fade pb-9 at-front"
          :class="myWidth == 'sm' ? 'pr-3 ' : ''"
          id="appmodal"
        >
          <div
            class="mx-2 modal-dialog w-100"
            :class="myWidth == 'sm' ? '' : ['modal-xl', 'mx-auto']"
          >
            <div
              class="modal-content filter-contain"
              :class="myWidth != 'sm' ? 'px-3' : 'px-1'"
            >
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <div class="">
                <DownloadApp />
                <CookiesModal v-if="cookiesModal == true" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal fade pb-9 at-front" v-if='myWidth == "sm"' :class="myWidth == 'sm' ? 'pr-3 ' : ''" id="msgModal">
            <div class="mx-2 modal-dialog w-100" :class='myWidth == "sm" ? "" : ["modal-xl", "mx-auto"]'>
              <div class="modal-content filter-contain" :class="myWidth != 'sm' ? 'px-3' : 'px-1'">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i></button>
                </div>
                <div class="">
                  <MsgModal  :closeSecondModal='closeMsgModal' :showModal='showModal'/>
                </div>
                </div>
              </div>
            </div> -->
      </div>
      <!-- <transition enter-active-class="animate__animated animate__zoomIn"
        leave-active-class="animate__animated animate__zoomOut"> -->
      <router-view :searchForm="searchForm" v-slot="{ Component }">
        <component :is="Component"></component>
      </router-view>
      <!-- </transition> -->
      <Msg :showModal="showModal" />
      <MainIcons v-if="myWidth != 'sm'" />
    </main>
    <footer>
      <AppFooter v-if="myWidth != 'sm'" />
      <!-- <div v-else class="fixed-footer">
        <DownloadApp />
      </div> -->
    </footer>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import DownloadApp from "@/components/misc/DownloadApp.vue";
import Msg from "@/components/misc/msg.vue";
import MsgModal from "@/components/misc/MsgModal.vue";
import CookiesModal from "@/components/misc/CookiesModal.vue";
import cookies from "vue-cookies";
import { pluginOptions } from "/vue.config";
import MainIcons from "./components/MainIcons.vue";
import { data } from "dom7";

export default {
  components: {
    AppHeader,
    AppFooter,
    Msg,
    MsgModal,
    DownloadApp,
    CookiesModal,
    MainIcons,
  },

  // metaInfo: {
  //   titleTemplate: this.computed.businessHome() ? "Snawnaw  %s  " : "",
  // },

  data() {
    return {
      locales: this.$i18n.availableLocales,
      width: window.innerWidth,
      Rtl: this.$i18n.locale == "ar",
      text: "",
      city: "",
      searchForm: false,
    };
  },
  watch: {
    locale: function (newlocale) {
      if (this.businessHome && this.businessHome != null) {
        cookies.set(`${this.businessHome.style}_lang`, newlocale);
      } else {
        cookies.set("lang", newlocale);
      }
    },
    $route() {
      const appHeader = document.querySelector(".app-header");
      appHeader.classList.remove("active-menu");
      // if (window.location.href.startsWith("http://")) {
      //   try {
      //     window.history.pushState(
      //       {},
      //       null,
      //       window.location.href.replace("http://", "https://")
      //     );
      //   } catch (err) {
      //     window.open(
      //       window.location.href.replace("http://", "https://"),
      //       "_self"
      //     );
      //   }
      // }
    },
  },
  created() {
    if (this.businessHome !== null) {
      const favicon = document.getElementById("favicon");
      favicon.href = this.businessHome.favicon;
      document.title = this.businessHome.name;
      import(`./assets/scss/${this.businessHome.style}/all.scss`);
    }
    this.$loadScript("../../js/bootstrap.bundle.js").then((res) => {
      if (!cookies.isKey("agreed")) {
        // this.showMsgModal()
        this.$store.commit("showMsg", {
          condition: 3,
          close: true,
          text: this.$t("usesCookies") || this.$t("AboutCookies"),
          desc: this.$t("WeUseCookiesToCollect"),
          // type: 'warning'
        });
      }
      if (
        !document.cookie.split("; ").find((row) => row.startsWith("suggest"))
      ) {
        this.showModal();
        document.cookie = "suggest=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
      }
      $("#homeCarousel").carousel({
        interval: 2000,
      });
      $("#businessCarousel").carousel({
        interval: 2000,
      });
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.$store.state.width == this.width;
    this.$store
      .dispatch("checkGuest")
      .then((res) => {})
      .catch((err) => {
        if (!cookies.get("user_token")) {
          this.$store
            .dispatch("makeGuest")
            .then((res) => {})
            .catch((err) => console.error(err));
        }
      });
    this.$store.dispatch("getAllCartItems");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  destroyed() {},
  computed: {
    profileRoute() {
      return this.$route.name == "BusinessProfile";
    },
    locale() {
      return this.$i18n.locale;
    },
    currentPath() {
      return this.$route.name;
    },
    myWidth() {
      return this.$store.getters.width;
    },
    ar() {
      return this.$i18n.locale == "ar";
    },
    cookiesModal() {
      return this.$store.getters.cookiesModal;
    },
    businessHome() {
      return pluginOptions.businesses &&
        pluginOptions.businesses[location.hostname]
        ? pluginOptions.businesses[location.hostname]
        : null;
    },
    modalOpen() {
      return !$("#appmodal").hasClass("shown");
    },
    searchDisappearRoutes() {
      return this.$rotue.name == "Login" || this.$route.name == "Register";
    },
  },
  methods: {
    localeSwitch(locale) {
      if (locale !== this.$i18n.locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: { locale } });
        this.$router.push(to.location);
        this.$router.go();
      }
    },
    handleResize() {
      this.$store.state.width = window.innerWidth;
    },

    flag(lang) {
      return require("./assets/images/flags/" + lang + "circle.png");
    },
    showModal() {
      $("#appmodal").modal("show");
    },
    showMsgModal() {
      $("#msgModal").modal("show");
    },
    closeMsgModal() {
      $("#msgModal").modal("hide");
    },
    search(text) {
      if (this.$route.name == "CategoryList") {
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
          },
          query: {
            text: this.text,
          },
        });
      } else {
        this.$router.push({
          name: "SearchResults",
          params: {
            ...this.$route.params,
          },
          query: {
            text: this.text,
          },
        });
      }
    },
    searchVisible() {
      this.$store.state.searchForm = !this.$store.state.searchForm;
    },
    profileRouteBtn() {
      if (
        this.profileRoute ||
        this.$route.name == "Login" ||
        this.$route.name == "Register"
      ) {
        return this.$store.state.searchForm;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang='scss'>
@import "./assets/fonts/icons/css/import-icons.css";
@import "./assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
@import "./assets/css/swiper-bundle.css";
@import "./assets/css/bootstrap.css";
@import "./assets/all.scss";

// body{
//   background-color: #eeeeee;
// }
.langs-select {
  margin: 0 10px;
}

.navbar-inverse .navbar-nav > li > a {
  color: white;
}

.logo {
  margin: 8px 8px;
}

.list-langs {
  cursor: default;
}

.user-lock::before {
  content: "\ea21";
}

.ma-4 {
  margin: 20px;
  font-size: 20px;
  color: white;
  z-index: 100;
}

@media (max-width: 767px) {
  header .logo {
    position: absolute;
    width: 60%;
    left: 80px;
  }
}
</style>


:class="Rtl ? ['rtl-body',  myWidth == 'sm' ? 'pb-12': ''] : myWidth == 'sm' ? 'pb-12': ''"


