import Axios from 'axios';

const dashboard = { 
  state: {
    myProducts: {},
    allergents: [],
    brands: [],
    listCategories: [],
  },

  getters: {
    myProducts: (state) => state.myProducts, 
    brands: (state) => state.brands,
    allergents: (state) => state.allergents,
    listCategories: (state) => state.listCategories,
  },

  mutations: {
    setBrands(state, payload) {
      state.brands = [...payload]
    },
    setListCategories(state, payload) {
      state.listCategories = [...payload]
    },
    setAllergents(state, payload) {
      state.allergents = [...payload]
    },
    addAllergents(state, payload) {
      state.allergents.push(payload)
    },
    editAllergent(state, payload) {
      let index = state.allergents.indexOf(state.allergents.find(el => el.id == payload.id));
      state.allergents.splice(index, 1, payload);
    },
    deleteAllergent(state, payload) {
      let index = state.allergents.indexOf(state.allergents.find(el => el.id == payload));
      state.allergents.splice(index, 1);
    },
    setMyProducts(state, payload) {
      state.myProducts = {...payload}
    },
    editProduct(state, payload) {
      let index = state.myProducts.data.indexOf(state.myProducts.data.find(el => el.id == payload.id));
      state.myProducts.data.splice(index, 1, payload);
    },
    deleteProduct(state, payload) {
      let index = state.myProducts.data.indexOf(state.myProducts.data.find(el => el.id == payload));
      state.myProducts.data.splice(index, 1);
    },
  },
  
  actions: {
    listBrands({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`business/brands`, {
          api_token: payload,
        }).then(res => {
          if (res.data.success == true) {
            commit('setBrands', res.data.data);
            resolve(res);
          }else reject(res)
        }).catch(err => reject(err));
      })
    },
    listCategories({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`categories`, {
          tree: 1,
        }).then(res => {
          if (res.data.success) {
            commit('setListCategories', res.data.data);
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err));
      })
    },
    listAllergents({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`allergents/list`, {
          api_token: payload.api_token,
          lang: payload.lang,
          pref: true
        }).then(res => {
          if (res.data.success) {
            commit('setAllergents', res.data.data);
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err));
      })
    },
    createAllergent({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`business/allergents`, {
          api_token: payload.api_token,
          business_id: payload.business_id,
          details: {
              title: {
                en: payload.title.en,
                ar: payload.title.ar,
                de: payload.title.de,
              },
              additive: {
                en: payload.additive.en,
                ar: payload.additive.ar,
                de: payload.additive.de,
              },
              allergents: {
                en: payload.allergents.en,
                ar: payload.allergents.ar,
                de: payload.allergents.de,
              }
          }
        })
      }).then(res => {
        if (res.data.success == true) {
          commit('addAllergent', res.data.data);
          resolve(res);
        }else reject(res);
      }).catch(err => reject(err));
    },
    updateAllergent({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`business/allergents/${payload.id}`, {
          api_token: payload.api_token,
          business_id: payload.business_id,
          details: {
              title: {
                en: payload.title.en,
                ar: payload.title.ar,
                de: payload.title.de,
              },
              additive: {
                en: payload.additive.en,
                ar: payload.additive.ar,
                de: payload.additive.de,
              },
              allergents: {
                en: payload.allergents.en,
                ar: payload.allergents.ar,
                de: payload.allergents.de,
              }
          }
        })
      }).then(res => {
        if (res.data.success == true) {
          commit('editAllergents', res.data.data)
          resolve(res);
        }else reject(res);
      }).catch(err => reject(err));
    },
    deleteAllergent({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`business/allergents/remove/${payload.allergent_id}`, {
          api_token: payload.api_token,
        })
      }).then(res => {
        if (res.data.success == true) {
          commit('removeAllergent', payload.allergent_id)
          resolve(res);
        }else reject(res);
      }).catch(err => reject(err));
    },
    getMyProducts({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`business/myproducts`, {
          api_token: payload.api_token,
          lang: payload.lang,
          page: payload.page || 1,
          category_id: payload.category_id,
          business_id: payload.business_id,
          status: payload.status,
          featured: payload.featured,
          keywords: payload.keywords,
          hotdeals: payload.hotdeals,
          quantity: payload.quantity,
          quantityBy: payload.quantityBy
        }).then(res => {
          if (res.data.success == true)   {
            commit('setMyProducts', res.data.data);
            resolve(res);
          }else reject(err);
        }).catch(err => reject(err));
      })
    },
    createProduct({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`business/myproducts/create`, {
            api_token: payload.api_token,
            lang: payload.lang,
            names: {
              en: payload.names.en,
              ar: payload.names.ar,
              de: payload.names.de,
            },
            descriptions: {
              en: payload.descriptions.en,
              ar: payload.descriptions.ar,
              de: payload.descriptions.de,
            },
            sku: payload.sku,
            brand_id: payload.brand_id,
            business_id: payload.business_id,
            categories_ids: [...payload.categories_ids],
            tax: payload.tax,
            status: payload.status,
            max_orders: payload.max_orders,
            featured: payload.featured,
            image: payload.image,    
            price: payload.price,
            quantity: payload.quantity,
            price_after_sale: payload.price_after_sale,
            options: {...payload.options},
            default_price_id: payload.default_price_id,
            allergents_ids: [...payload.allergents_ids]
        }).then(res => {
          if(res.data.success == true) {
            resolve(res);
          }else reject(res)
        }).catch(err => reject(err));
      })
    },
    updateProduct({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`business/myproducts/${payload.product_id}`, {
            api_token: payload.api_token,
            lang: payload.lang,
            names: {
              en: payload.names.en,
              ar: payload.names.ar,
              de: payload.names.de,
            },
            descriptions: {
              en: payload.descriptions.en,
              ar: payload.descriptions.ar,
              de: payload.descriptions.de,
            },
            sku: payload.sku,
            brand_id: payload.brand_id,
            business_id: payload.business_id,
            categories_ids: [...payload.categories_ids],
            tax: payload.tax,
            status: payload.status,
            max_orders: payload.max_orders,
            featured: payload.featured,
            image: payload.image,    
            price: payload.price,
            quantity: payload.quantity,
            price_after_sale: payload.price_after_sale,
            options: {...payload.options},
            default_price_id: payload.default_price_id,
            allergents_ids: [...payload.allergents_ids]
        }).then(res => {
          if(res.data.success == true) {
            commit('editProduct', res.data.data);
            resolve(res);
          }else reject(res)
        }).catch(err => reject(err));
      })
    },
    updateProductImage({commit}, payload) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('image', payload.image);
        Axios.post(`business/myproducts/${payload.product_id}`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          }
        }).then(res => {
          if(res.data.success == true) {
            resolve(res);
          }
        }).catch(err => reject(err))
      })
    },
    deleteProduct({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`business/myproducts/${payload.product_id}/delete`, {
            api_token: payload.api_token,
        }).then(res => {
          if(res.data.success == true) {
            commit('deleteProduct', payload.product_id)
            resolve(res);
          }else reject(res)
        }).catch(err => reject(err));
      })
    },
  },
  

}

export default dashboard;