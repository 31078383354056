<template>
  <form class="review-form" @submit.prevent="submitReview">
    <div class="form-group">
      <textarea
        class="form-control"
        rows="5"
        :placeholder="$t('DescribeExp')"
        v-model="review"
        :class="ar ? 'dir-rtl' : 'dir-ltr'"
      >
      </textarea>
    </div>
    <p class="text-danger text-center sub-title" v-if="msg">{{ msg }}</p>
    <div class="d-flex justify-content-between align-items-center">
      <div class="form-group">
        <input
          type="submit"
          class="btn-pri-lg p-md-2 px-md-5 p-1 px-4"
          :value="$t('Leave Feedback')"
        />
      </div>
      <div class="form-group d-flex align-items-center rater-box">
        <star-rating
          :star-size="30"
          v-model="rate"
          :show-rating="false"
          :increment="0.5"
          :border-width="0.5"
        ></star-rating>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import StarRating from "vue-star-rating";
export default {
  name: "WriteReview",
  data() {
    return {
      rate: 3.5,
      review: null,
      msg: null,
      starCount: 4,
      errorAuth: false,
      business_id: this.$route.params.id,
      stars: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
      ],
    };
  },
  methods: {
    ...mapActions(["postReview"]),
    submitReview() {
      if (this.review && this.rate >= 0 && this.business_id) {
        this.postReview({
          business_id: this.business_id,
          review: this.review,
          rate: this.rate,
        }).catch((err) => {
          if (err && err.data && err.data.data && err.data.data.errorAuth) {
            this.msg = this.$t("signInFirst");
            setTimeout(() => {
              this.msg = "";
            }, 4000);
          } else {
            this.msg = this.$t("hadError");
            setTimeout(() => {
              this.msg = "";
            }, 4000);
          }
        });
      } else {
        this.msg = this.$t("dataMissing");
        setTimeout(() => {
          this.msg = "";
        }, 4000);
      }
    },
    addStar() {
      if (this.starCount == 5) {
        return false;
      } else {
        this.starCount++;
        this.setRate(this.starCount);
        this.$forceUpdate();
      }
    },
    removeStar() {
      if (this.starCount <= 0) {
        return false;
      } else {
        this.starCount--;
        this.setRate(this.starCount);
        this.$forceUpdate();
      }
    },
    setRate(rate) {
      this.rate = rate;
    },
  },
  computed: {
    ar() {
      return this.$i18n.locale == "ar";
    },
  },
  components: { StarRating },
};
</script>