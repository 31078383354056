<template>
  <div class="chip-def mt-0 mb-0" @click='goSearchResults(item.id)'>
    <a class="text-ellipsis"> {{ htmlDecode(item.name) }} </a>
  </div>
</template>

<script>
import { helpers } from '../../Helpers';
export default {
  mixins: [helpers],
  props: ['item', 'goSearchResults']
}
</script>