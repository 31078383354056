<template>
  <nav aria-label="">
    <ul class="pagination justify-content-center" :class="$i18n.locale == 'ar' ? 'flex-row-reverse' : 'flex-row'">
      <li :class="currentPage == 1 ? ['', 'disabled'] : ''" :disabled='currentPage == 1' v-show='false'><a
          class="page-link pointer" @click='previous'>{{ $t('previous') }}</a></li>
      <li class=''><a class="page-link pointer" @click='showPage(1)'>{{ $t('first') }}</a></li>
      <!-- <li class=''><a class="page-link pointer">...</a></li> -->
      <li :class="currentPage == page ? ['', 'active'] : ''" v-for='(page, index) in pagesRange' :key='index'>
        <a class="page-link pointer" @click='showPage(page)'>{{ page }}</a>
      </li>
      <!-- <li class=''><a class="page-link pointer">...</a></li> -->
      <li class=''><a class="page-link pointer" @click='showPage(lastPage)'>{{ $t('last') }}</a></li>
      <li :class="currentPage == lastPage ? ['', 'disabled'] : ''" :disabled='currentPage == lastPage' v-show='false'>
        <a class="page-link pointer" @click='next'>{{ $t('next') }}</a></li>
    </ul>
    <div class="text-center privacy-color mouse"> [ {{ currentPage }} / {{ lastPage }} ] </div>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',

  props: ['currentPage', 'showPage', 'lastPage'],
  methods: {
    next() {
      if (this.currentPage == this.lastPage) {

      } else {
        this.showPage(this.currentPage += 1)
      }
    },
    previous() {
      if (this.currentPage === 1) {

      } else {
        this.showPage(this.currentPage -= 1)
      }
    },
  },
  computed: {
    pagesRange() {
      if (this.lastPage > 4) {
        if (this.currentPage == 1) {
          return [this.currentPage, this.currentPage + 1, this.currentPage + 2, this.currentPage + 3];
        } else if (this.currentPage == 2) {
          return [this.currentPage - 1, this.currentPage, this.currentPage + 1, this.currentPage + 2, this.currentPage + 3];
        } else if (this.currentPage == this.lastPage - 1) {
          return [this.currentPage - 3, this.currentPage - 2, this.currentPage - 1, this.currentPage, this.currentPage + 1];
        } else if (this.currentPage == this.lastPage) {
          return [this.currentPage - 3, this.currentPage - 2, this.currentPage - 1, this.currentPage];
        } else return [this.currentPage - 2, this.currentPage - 1, this.currentPage, this.currentPage + 1, this.currentPage + 2];
      } else return this.lastPage

    }
  }
}
</script>

<style scoped>
.pagination {
  border: black
}

/* .active>a {
  background-color : rgb(186,19,126);
  border-color: black;

}
.active>a:hover {
  background-color: grey;
}

.page-link {
  color: rgb(186,19,126);
} */
</style>