<template>
  <div class="card rev-contain mb-2 p-2">
    <div class="row no-gutters d-flex" :class="ar ? 'flex-row-reverse' : 'flex-row'">
      <div class="col-12 col-md-2  px-0 mt-3 text-center">
        <img class="rev-image"
          :src="item.user && item.user.media.length > 0 ? item.user.media[0].icon : 'https://www.pngitem.com/pimgs/m/592-5922762_user-icon-material-design-hd-png-download.png'" />
        <Stars class="text-center mt-2" :stars='item.rate' />
      </div>
      <div class="col-12 col-md-10  px-0">
        <div class="card-body px-2">
          <div class=" rev-name" :class="ar ? 'text-right' : ''" :stars='item.rate'>{{ item.user ? item.user.name :
              $t('Guest User')
          }}</div>
          <div class="my-xl-2 review-msg my-1" :class="ar ? 'text-right' : ''" v-html="item.review"></div>
          <div class="rev-created" :class="ar ? 'text-right' : 'text-left'">{{ item.updated_at.split(' ')[0] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from '@/components/misc/Stars';

export default {
  components: {
    Stars
  },
  props: ['item'],
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    }
  }
}
</script>