<template>
  <div class="mt-3 " v-if='msgOptions.condition == 3'> asd <div class="row opt-name">
      <div class="col-12">
        <div class="col-12">
          <div class="col-12 col-md-7 align-self-center">
            <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'">
              <i class="fa fa-check-circle" v-if='msgOptions.type == "success"'></i>
              <i class="fa fa-warning" v-else-if='msgOptions.type == "error"'></i>
              <i class="fa fa-info-circle" v-else-if='msgOptions.type == "warning"'></i>
              <span class="msg-text m-2"> {{ msgOptions.text }} </span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div v-if='msgOptions && msgOptions.condition == 3'
            :class="[$i18n.locale == 'ar' ? 'text-right' : 'text-left',]" class="my-4 px-3 data-sub"> {{ msgOptions.desc
            }} <div class="data-label text-underline pointer">{{ $t('LearnMore') }}</div>
          </div>
        </div>
        <div class="col-12" :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'">
          <button class="w-100  mt-2" :class="msgOptions.condition == 3 ? 'btn-pri-sm' : 'btn-def-sm'"
            v-if='msgOptions.condition == 3' @click="$router.push({ name: 'Privacy' })"> {{ $t('privacy') }} </button>
          <button class="w-100  mt-2" :class="msgOptions.condition == 3 ? 'btn-pri-sm' : 'btn-def-sm'"
            v-if='msgOptions.close' @click="closeMsgModal"> {{ $t('accept') }} </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  props: ['showModal', 'closeSecondModal'],
  computed: {
    ...mapGetters(['msgOptions', 'width']),
    containType() {
      if (this.msgOptions.type == 'success') {
        return 'msg-contain-success';
      } else if (this.msgOptions.type == 'error') {
        return 'msg-contain-error';
      } else if (this.msgOptions.type == 'warning') {
        return 'msg-contain-warning';
      } else {
        return 'msg-contain';
      }
    }
  },
  methods: {
    ...mapActions(['closeMsg']),
    ...mapMutations(['showMsg']),
    closeMsgModal() {
      this.closeSecondModal()
      this.closeMsg(this.msgOptions.condition)
        .then(res => {
          if (res == 3) {
          } else if (res == 4) {
            $('#productModal').modal('hide');
            $('#profileModal').modal('hide');
            this.$router.go(-1);
          } else if (res == 5) {
          } else if (res == 6) {
            $('#orderModal').modal('hide');
          }
        }).catch(err => {
          this.showMsg({
            close: false,
            text: this.$t('someWrong'),
            type: 'error'
          })
        })
    }
  }
}
</script>