<template>
  <div class="container px-0">
    <div v-if="product.media && !modalLoading" class="row pro-details">
      <div class="col-12 px-0">
        <div class="row mainBody-card p-0 px-lg-3">
          <div class="col-12 px-0 col-md-12 col-lg-5 col-xl-5
          d-flex align-items-start justify-content-center
          ">
            <!-- {{options}} -->
            <div class="image">
              <img :src="
                product.media && product.media.length > 0
                  ? product.media[0].url
                  : product.business.media[0].url
              " />
              <div v-if="loggedIn" class="main-fav-btn bottom" :class="$i18n.locale"
                @click="favourited ? favouriteDelete() : favouriteAdd(product)">
                <i class="fa" :class="favourited ? 'fa-heart' : 'fa-heart-o'"></i>
              </div>
            </div>
          </div>
          <div class="pro-header mb-0 pb-3 col-sm-12 col-lg-7 col-xl-7" :class="t_ar">
            <div class="row align-items-center" :class="$i18n.locale == 'ar' ? 'flex-row-reverse' : ''">
              <div class="col-8 px-0 text-ellipsis">{{ product.name }}</div>
              <div class="pro-options-price col-4 px-0" v-if="showPrice == 'Yes'"
                :class="[$i18n.locale == 'ar' ? 'text-left' : 'text-right']">
                <span class="old-price mx-2" v-if="product.price_after_sale > 0"> € {{ product.price }} </span>
                <span class="mx-2"> € {{ product.price_after_sale > 0 ? product.price_after_sale : product.price }}
                </span>
              </div>
            </div>
            <div class="subheader-home mt-3" :class="t_ar" v-html="removeBrReapeted(product.description)"></div>
            <div class="allergents" v-if="product.allergents && product.allergents.length > 0">
        <div id="accordion" v-for="(allg, index) in product.allergents" :key="index">
          <div class="card px-0" v-for="(val, key) of allg.row" :key="key" v-show="key !== 'title'">
            <div class="card-header p-0 d-flex align-items-center justify-content-between collapsed"
            :class="$i18n.locale =='ar'?'flex-row-reverse':''"
            id="headingOne"
              data-toggle="collapse" :data-target="'#collapse-' + key" aria-expanded="true"
              :aria-controls="'collapse-' + key">
              <button class="btn subheader-home text-capitalize"> {{ key }} </button>
              <button class="btn">
                <i class="fa fa-chevron-up"></i>
                <!-- <i class="fa fa-chevron-up"></i> -->
              </button>
            </div>
            <div :id="'collapse-' + key" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body subheader-home sm py-0" v-html="val"> </div>
            </div>
          </div>
        </div>
      </div>
            <!-- <div class="
                form-group
                d-flex
                justify-content-between
                align-items-center
                mt-4
                mb-0
              " v-if="showPrice == 'Yes'">
 <label class="input-label">{{$t('quantity')}}:</label> 
              <button class="btn-icon-def p-0" @click="quantity++">
                <i class="fa fa-plus"></i>
              </button>
              <input type="number" v-model="quantity" class="w-25 text-input-sm mx-3 text-center"
                :placeholder="$t('How Many ?')" />
              <button class="btn-icon-def p-0" @click="decreaseQuantity">
                <i class="fa fa-minus"></i>
              </button>
            </div> -->
          </div>
        </div>
      </div>
     
      <div class="col-12 pro-options mb-2" v-if="showPrice == 'Yes'">
        <div class="row" v-if="
          product.options &&
          product.options.length > 0 &&
          product.options[0].option_values.length > 1
        ">
          <div v-for="(opt, index) in product.options" :key="opt.id"
            class="pro-options-contain col-12 px-0 col-lg-6 mt-lg-0" :class="index == 0 ? '' : 'mt-4'">
            <div class="opt-name mx-0" v-if="product.options.length > 0">
              <span class="">{{ opt.name }}</span>
            </div>
            <div class="opt-options mt-2 px-3 row" v-if="opt.type == 'radio' && opt.option_values.length > 0">
              <div class="
                  custom-radio custom-control
                  col-12 
                  d-flex
                  justify-content-between
                " :class="flex_ar_reverse" v-for="val in opt.option_values" :key="val.id">
                <div class="d-flex align-items-center" :class="flex_ar_reverse">
                  <input class="custom-control-input" type="radio" :id="val.label" :value="{
                    id: val.id,
                    label: val.label,
                    price: val.price_after_sale
                      ? val.price_after_sale
                      : val.price,
                  }" v-model="options[opt.name]" @change="appendInCart('option')" />
                  <label class="opt-label custom-control-label my-0 mx-2" :class="
                    $i18n.locale == 'ar' ? 'custom-control-label-ar' : ''
                  " :for="val.label"> {{ val.label }} </label>
                </div>
                <span class="opt-price"> € {{ val.price_after_sale ? val.price_after_sale : val.price }}</span>
              </div>
            </div>
            <div class="opt-options row mt-2 px-3" v-else-if="opt.type == 'checkbox' && opt.option_values.length > 0">
              <div class="
                  custom-checkbox custom-control
                  col-12
                  d-flex
                  justify-content-between
                " :class="flex_ar_reverse" v-for="val in opt.option_values" :key="val.id">
                <div class="d-flex" :class="flex_ar_reverse">
                  <input class="custom-control-input mx-n3 my-2" :class="f_ar" type="checkbox" :id="val.label"
                    :true-value="{
                      id: val.id,
                      price: val.price_after_sale
                        ? val.price_after_sale
                        : val.price,
                    }" false-value="null" v-model="options[val.label]" @change="appendInCart('option')" />
                  <label class="opt-label custom-control-label mx-2" :class="
                    $i18n.locale == 'ar' ? 'custom-control-label-ar' : ''
                  " :for="val.label"> {{ val.label }} </label>
                </div>
                <span class="opt-price"> € {{ val.price_after_sale ? val.price_after_sale : val.price }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="main-card mt-4 pa-2 col-12">
          <div class="form-group">
            <label class="input-label">{{$t('specialRequest')}}</label>
            <input type='text' v-model='request' class="text-input mt-2 form-control"  :placeholder="$t('specialRequest')"/>
          </div>
          <div class="form-group">
            <label class="input-label">{{$t('quantity')}}:</label>
            <input type='number' v-model='quantity' class="text-input mt-2 form-control" placeholder="How Many ?"/>
          </div>
          
        </div> -->
      </div>
      <NoPlaces class=" row w-75 p-2 mx-auto my-3" v-else :msg="$t('pricesBlocked')" />
      <div class="col-12" v-if="showPrice == 'Yes'">
        <!-- <button type="button" class=" btn-def" @click='closeModal'>{{$t('close')}}</button> -->
        <button v-if="!startAdd" type="button" class="add-to-cart-btn  in-details" @click="addToCart">
          <span class="add-to-cart-span">
            <i class="fa fa-shopping-cart add-to-cart-svg"></i> {{ $t("addToCart") }} </span>
        </button>
        <button v-if="startAdd" type="button" class="add-to-cart-btn in-details">
          <div class="qyt">
            <i class="fa fa-plus" @click="appendInCart(true)"></i>
            <input type="number" disabled v-model="quantity" class="text-center" :placeholder="$t('How Many ?')" />
            <i class="fa fa-minus" v-if="quantity != 1" @click="appendInCart(false)"></i>
            <i class="fa fa-trash" v-else @click="appendInCart(false)"></i>
          </div>
        </button>
      </div>
    </div>
    <NoPlaces v-else class="my-4 row" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import NoPlaces from "@/components/misc/NoPlaces";
import cookies from "vue-cookies";
import { pluginOptions } from "/vue.config";

export default {
  components: {
    NoPlaces,
  },
  data() {
    return {
      quantity: 1,
      options: {},
      request: "",
      favourited: false,
      favID: "",
      startAdd: false,
    };
  },
  props: ["show"],
  mounted() {
    if (!this.businessHome && !this.attachOrderRoute && !this.dineInRoute) {
      $("#productModal").modal("show");
    }
    this.checkFavourites(this.product);
    $(".modal").on("show.bs.modal", (e) => {
      if (this.$route.params.product_id) {
        this.showProduct({
          product_id: this.$route.params.product_id,
          lang: this.$i18n.locale,
        }).then((res) => {
          if (res.data.data) {
            this.checkIfInCart()
          } else {
            $(".modal").modal("hide");
          }

        });
      } else {
        this.checkIfInCart()
      }
    })
    $(".modal").on("hide.bs.modal", (e) => {
      this.options = {};
      this.quantity = 1;
      this.$route.params.productId = 0;
      this.getAllCartItems();
    });
  },
  computed: {
    ...mapGetters([
      "product",
      "loading",
      "modalLoading",
      "loggedIn",
      "favourites",
      "cartItems"
    ]),
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row" : "flex-row-reverse";
    },
    flex_ar_reverse() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "flex-row";
    },
    showPrice() {
      return this.product.business.show_prices;
    },

    businessHome() {
      return pluginOptions.businesses &&
        pluginOptions.businesses[location.hostname]
        ? pluginOptions.businesses[location.hostname]
        : null;
    },
    attachOrderRoute() {
      return this.$route.name == "AttachOrder";
    },
    dineInRoute() {
      return this.$route.name == "DineIn";
    },
  },
  watch: {
    cartItems: function () {
      this.checkIfInCart()
    }
  },
  methods: {
    ...mapMutations(["addToVCart", "cartBusinessId", "showMsg", "removeFromVCart"]),
    ...mapActions([
      "getAllCartItems",
      "showProduct",
      "addFavourite",
      "deleteFavourite",
      "isFavourite",
    ]),
    arrowDir(id) {
      const ele = document.getElementById(`${id}`);
      if (ele && ele.classList.contains('show')) {
        return 'up'
      } else {
        return 'down'
      }
    },
    closeModal() {
      $(".modal").modal("hide");
      this.$router.go(-1);
    },
    addToCart(e) {
      this.appendInCart(true);
      // if ((e.target.classList.contains('add-to-cart-btn') || e.target.classList.contains('add-to-cart-span') || e.target.classList.contains('add-to-cart-svg')) && this.startAdd == false) {
      // } else {
      //   return false
      // }
    },
    checkIfInCart() {
      const inCart = this.cartItems.find(el => el.product_id == this.product.id);
      if (inCart) {
        this.startAdd = true;
        this.quantity = inCart.quantity;
        this.options = inCart.options ? inCart.options : {};
        return true;
      } else {
        return false;
      }
    },
    appendInCart(inc) {
      const savedQyt = this.quantity;
      if (!this.startAdd && this.checkIfInCart()) {
        this.showMsg({
          close: false,
          text: this.$t("already in cart"),
          type: "success",
        });
        return
      }
      if (this.startAdd && inc === true) {
        this.quantity++;
      } else if (inc === false) {
        if (this.quantity - 1 != 0 && this.quantity != 1) {
          this.quantity--;
        } else if (inc === 'option') {
          true
        } else {
          this.checkAndRemove(this.product.id);
          return
        }
      }
      if (cookies.isKey("BusId")) {
        const inCart = this.cartItems.find(el => el.product_id == this.product.id);
        if (!inCart || (inCart && inCart.quantity != this.quantity) || (inCart && inc === 'option')) {
          if (this.$route.params.id == cookies.get("BusId")) {
            this.startAdd = true;
            let item = this.buildItem();
            this.addToVCart(item);
            this.getAllCartItems();
            this.showMsg({
              close: false,
              text: inCart && inCart.quantity == savedQyt ? this.$t("updated in cart") : this.$t("addedCart"),
              type: "success",
            });
          } else {
            let item = this.buildItem();
            this.showMsg({
              close: true,
              loaderClass: null,
              text: this.$t("cartWarning"),
              type: "error",
              action: "goCheckout",
              condition: 4,
              business_id: this.$route.params.id,
              item,
            });
          }
        } else {
          this.showMsg({
            close: false,
            text: this.$t("already in cart"),
            type: "success",
          });
        }
      } else {
        this.cartBusinessId(this.$route.params.id);
        this.appendInCart(true);
      }
    },
    buildItem() {
      if (Object.entries(this.options).length > 0) {
        for (const key in this.options) {
          if (Object.hasOwnProperty.call(this.options, key)) {
            const element = this.options[key];
            if (element == "null" || element == false) {
              delete this.options[key]
            }
          }
        }
        var item = {
          name: this.product.name,
          product_id: this.product.id,
          options: this.options,
          quantity: this.quantity,
          tax: this.product.tax != null ? this.product.tax : 0,
        };
      } else {
        var item = {
          name: this.product.name,
          product_id: this.product.id,
          price:
            this.product.price_after_sale > 0
              ? this.product.price_after_sale
              : this.product.price,
          quantity: this.quantity,
          tax: this.product.tax != null ? this.product.tax : 0,
        };
      }
      return item;
    },
    decreaseQuantity() {
      if (this.quantity - 1 != 0 && this.quantity != 1) {
        this.quantity--;
      } else {
        this.checkAndRemove(this.product.id);
      }
    },
    checkFavourites(item) {
      let check = this.favourites.find((el) =>
        el.product_id == item.id
      );
      console.log(check);
      // console.log("🚀 ~ file: Highlited.vue ~ line 102 ~ checkFavourites ~ check", check);
      if (check) {
        this.favID = check.id;
        this.favourited = true;
        // console.log("🚀 ~ file: Highlited.vue ~ line 106 ~ checkFavourites ~ favourited", this.favourited, this.favID)
      }
    },
    checkAndRemove(id) {
      const inCart = this.cartItems.find(el => el.product_id == id);
      if (inCart) {
        this.removeFromVCart(id);
        this.showMsg({
          close: false,
          text: this.$t("removed from cart"),
          type: "error",
        });
        this.startAdd = false;
        this.quantity = 1;
        this.options = {}
        this.getAllCartItems();
        // $(".modal").modal("hide");
      }
    },
    removeBrReapeted(desc) {
      const formated = desc.split("<br>");
      return formated.join(" ").split("\r").join(" ");
    },
    favouriteAdd(item) {
      this.addFavourite({
        product_id: item.id,
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("AddedtoFavourites"),
          });
          this.favID = res.data.data.id;
          this.favourited = true;
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("AddtoFavouritesError"),
          });
        });
    },
    favouriteDelete() {
      this.deleteFavourite({
        id: this.favID,
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("FavItemDeleted"),
          });
          this.favID = "";
          this.favourited = false;
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("FavItemDeletedError"),
          });
        });
    },
  },
};
</script>


