import Axios from 'axios';
import store from '../store/index';

const instance = Axios.create({
  baseURL: 'https://api-m.paypal.com/'
  // baseURL: 'https://api-m.sandbox.paypal.com/'
});


instance.interceptors.request.use((req) => {
  const paypalToken = store.getters.paypalToken;
  // const paypalToken = 'A21AAIHv772k9D3H7gZI1f_vyPKch5r38TBm6FW59cyQvJsGfTdDYlqW8otZHd-rv-iQTs-UiDCOZQlDDX8QP7ppwWYDwv2EA';
  req.headers.Authorization = `Bearer ${paypalToken}`;
  store.dispatch('startLoading');
  return req;
});

instance.interceptors.response.use(res => {
  store.dispatch('stopLoading');
  return res;
}, err => {
  return Promise.reject(err);
})


export default instance;