<template>
  <div v-if="
    showBusinessProfile &&
    business.categories &&
    business.categories.length > 0
  " class="container px-0">
    <div class="row text-center px-0">
      <SideFilter class="mb-1" :productCategories="business.categories" :pageReset="pageReset" />
      <div v-if="!categoryName == ''" class="m-0 px-0 row w-100">
        <h2 class="filter-heading m-0 mb-3">{{ categoryName }}</h2>
        <div class="row w-100 m-0" :class="flex_ar">
          <div class="col-6 col-md-4 px-2" v-for="item in paginatedProducts" :key="item.id">
            <PopProduct :show="business.show_prices" :item="item" :openModal="openModal" :large="true" />
          </div>
        </div>
      </div>
      <NoPlaces v-else :msg="$t('noProducts')"></NoPlaces>
      <div class="col-12">
        <Pagination :showPage="showPage" :lastPage="lastPage" :currentPage="currentPage" class="pt-4" />
      </div>
    </div>
    <div class="modal fade pb-9" :class="width == 'sm' ? 'pr-3 ' : ''" id="productModal">
      <div class="mx-2 modal-dialog" :class="width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']">
        <div class="modal-content filter-contain">
          <div class="modal-header">
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body"
            :class="!businessHome && !attachOrderRoute && !dineInRoute && !$route.params.product_id ? '' : 'p-0'">
            <router-view v-if="!businessHome && !attachOrderRoute && !dineInRoute && !$route.params.product_id">
            </router-view>
            <ProductDetails :show="$route.params.show" v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SideFilter from "@/components/Listings/SideFilter";
import Pagination from "@/components/misc/Pagination";
import PopProduct from "@/components/Listings/PopProduct";
import ProductDetails from "@/components/order/ProductDetails.vue";
import NoPlaces from "@/components/misc/NoPlaces";
import { pluginOptions } from "/vue.config";
import cookies from "vue-cookies";
export default {
  name: "BusinessProducts",
  components: {
    SideFilter,
    PopProduct,
    Pagination,
    ProductDetails,
    NoPlaces,
  },
  data() {
    return {
      showBusinessProfile: true,
      currentPage: 1,
      page_size: 16,
      categoryName: "",
    };
  },
  props: ["business", "productParam"],
  watch: {
    business: function (newPros, olPros) {
      if (olPros.categories != newPros.categories) {
        this.getBusinessProducts({
          business_id: this.$route.params.id,
          category_id: this.business.categories[0].id,
          lang: this.$i18n.locale,
        });
      } else {
        if (!this.business.categories || this.business.categories == 0) {
          this.showBusinessProfile = false;
        } else this.showBusinessProfile = true;
      }
    },
    $route: function (newRoute, oldRoute) {
      if (!newRoute.params.productId) {
        $(".modal").modal("hide");
      }
    },
  },
  mounted() {
    console.log(this.business);
    $(".modal").modal("hide");
    if (this.$route.params.productId && this.$route.params.productId !== 0) {
      this.openModal(this.$route.params.productId);
    } else {
      $(".modal").modal("hide");
    }
  },
  computed: {
    ...mapGetters(["businessProducts", "width", "loading"]),
    lastPage() {
      if (
        this.businessProducts.length / this.page_size < 1 ||
        this.businessProducts.length == this.page_size
      ) {
        return 1;
      } else if (
        this.businessProducts.length / this.page_size > 1 &&
        this.businessProducts.length % this.page_size > 0
      ) {
        return Math.floor(this.businessProducts.length / this.page_size) + 1;
      } else return this.businessProducts.length / this.page_size;
    },
    paginatedProducts() {
      return this.businessProducts.slice(
        (this.currentPage - 1) * this.page_size,
        this.currentPage * this.page_size
      );
    },
    businessHome() {
      return pluginOptions.businesses &&
        pluginOptions.businesses[location.hostname]
        ? pluginOptions.businesses[location.hostname]
        : null;
    },
    attachOrderRoute() {
      return this.$route.name == "AttachOrder";
    },
    dineInRoute() {
      return this.$route.name == "DineIn";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
  },
  methods: {
    ...mapActions([
      "showProduct",
      "getBusinessProducts",
      "getBusinessById",
      "getUserFavourites",
    ]),
    openModal(id) {
      if (this.businessHome || this.attachOrderRoute || this.dineInRoute) {
        this.showProduct({
          lang: this.$i18n.locale,
          product_id: id,
        })
          .then((res) => {
            $("#productModal").modal("show");
          })
          .catch((err) => console.error(err));
      } else {
        this.$router.push({
          name: "ProductWrapper",
          params: {
            productId: id,
            show: this.$route.params.show,
            tabID: this.$route.params.tabID
          },
        });
      }
    },
    closeModal() {
      $(".modal").modal("hide");
      if (!this.businessHome && !this.attachOrderRoute && !this.dineInRoute) {
        this.$router.go(-1);
      }
    },
    showPage(page) {
      this.currentPage = page;
      return this.businessProducts.slice(
        (this.currentPage - 1) * this.page_size,
        this.currentPage * this.page_size
      );
    },
    pageReset(name) {
      this.currentPage = 1;
      this.categoryName = name;
    },
  },
};
</script>