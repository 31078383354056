<template>
  <div class="container px-0">
    <div class="row">
      <div class="col-12 px-0">
        <div
          class="row d-flex mt-2"
          :class="$i18n.locale == 'ar' ? 'flex-row-reverse' : 'flex-row'"
        >
          <div class="col-12 col-sm-12 col-lg-8">
            <div v-if="business.description">
              <h3
                class="listing-section"
                :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
              >
                {{ $t("info") }}
              </h3>
              <p
                class="opt-label height-info-protect"
                :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
                v-if="business.description"
                v-html="removeBrReapeted(business.description)"
              ></p>
              <NoPlaces v-else :msg="$t('noInfo')" />
            </div>
            <Amenities :business="business" class="px-n3 my-3" />
            <div class="row mt-2" v-if="hotDeals.length > 0">
              <div class="col-12 px-0">
                <h3
                  class="listing-section"
                  :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
                >
                  {{ $t("hotDeals") }}
                </h3>
                <div
                  class="row"
                  v-if="hotDeals.length > 0"
                  :class="$i18n.locale == 'ar' ? 'flex-row-reverse' : ''"
                >
                  <div
                    class="px-1 col-6 pointer"
                    v-for="item in hotDeals"
                    :key="item.id"
                  >
                    <PopProduct
                      :show="$route.params.show"
                      :item="item"
                      :openModal="openModal"
                      :large="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-2 row"
              v-if="business.gallery && business.gallery.length > 0"
            >
              <div class="col-12 px-0">
                <h3
                  class="listing-section"
                  :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
                >
                  {{ $t("gallery") }}
                  <!-- <span class="rating-no ">({{business.gallery.length}})</span> -->
                </h3>
                <div
                  class="row"
                  :class="[$i18n.locale == 'ar' ? 'flex-row-reverse' : '']"
                  v-if="business.gallery && business.gallery.length > 0"
                >
                  <div
                    class="my-2 pointer col-auto px-0"
                    v-for="img in business.gallery"
                    :key="img.id"
                    @click="openModal(0, img.url)"
                  >
                    <img class="gallery-image border-eff" :src="img.url" />
                  </div>
                </div>
                <div v-else class="col-xs-12">
                  <NoPlaces :msg="$t('noGallery')" />
                </div>
              </div>
            </div>
            <div
              class="mt-2 row"
              v-if="business.menus && business.menus.length > 0"
            >
              <div class="col-12 px-0">
                <h3
                  class="listing-section"
                  :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
                >
                  {{ $t("menus") }}
                  <!-- <span class="rating-no ">({{business.gallery.length}})</span> -->
                </h3>
                <div
                  class="row"
                  :class="[$i18n.locale == 'ar' ? 'flex-row-reverse' : '']"
                  v-if="business.menus && business.menus.length > 0"
                >
                  <div
                    class="col-auto my-2 px-0 pointer"
                    v-for="img in business.menus"
                    :key="img.id"
                    @click="openModal(0, img.url)"
                  >
                    <img class="menu-image" :src="img.url" />
                  </div>
                </div>
                <div v-else class="col-xs-12">
                  <NoPlaces :msg="$t('noMenu')" />
                </div>
              </div>
            </div>
            <div class="mt-2 row" v-if="business.reviews.length > 0">
              <div class="col-12 px-0">
                <h3
                  class="listing-section"
                  :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
                >
                  {{ $t("reviews") }}
                  <span class="rating-no">({{ business.reviews.length }})</span>
                </h3>
                <div
                  class="row justify-content-center"
                  v-if="business.reviews.length > 0"
                >
                  <!-- <WriteReview /> -->
                  <div
                    class="col-12 my-2"
                    v-for="rev in business.reviews.slice(0, 5)"
                    :key="rev.id"
                  >
                    <Review :item="rev" />
                  </div>
                  <button
                    class="btn-pri-lg mt-4"
                    :class="$i18n.locale == 'ar' ? 'f-right' : 'f-left'"
                    @click="changeTab('rev', true)"
                  >
                    {{ $t("readMore") }}
                  </button>
                  <!-- End Review Author Block -->
                </div>
                <div v-else class="col-xs-12">
                  <NoPlaces :msg="$t('noReviews')" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              socials-card
              col-12 col-sm-12 col-lg-4
              p-2
              pt-5
              p-md-4
              pt-md-5
            "
          >
            <div
              class="text-center mb-3"
              v-if="
                business.location.facebook ||
                business.location.twitter ||
                business.location.youtube
              "
            >
              <button
                @click="goLink(business.location.facebook)"
                v-if="business.location.facebook"
                class="btn-icon-pri"
              >
                <i class="fa fa-facebook"></i>
              </button>
              <button
                @click="goLink(business.location.twitter)"
                v-if="business.location.twitter"
                class="btn-icon-pri"
              >
                <i class="fa fa-twitter"></i>
              </button>
              <button
                @click="goLink(business.location.youtube)"
                v-if="business.location.youtube"
                class="btn-icon-pri"
              >
                <i class="fa fa-youtube"></i>
              </button>
            </div>
            <div class="list-data">
              <div
                class="
                  d-flex
                  align-items-start
                  list-address
                  text-underline
                  mb-1
                  p-0
                "
                v-if="business.location.address"
                @click="goLink(business.location.address_link)"
              >
                <span class="icon mx-2">
                  <i class="fa fa-map-marker"></i>
                </span>
                <span> {{ business.location.address }} </span>
              </div>
              <div
                class="
                  list-phone
                  pointer
                  d-flex
                  align-items-start
                  text-underline
                  mb-1
                  p-0
                "
                v-if="business.location.phone"
                @click="goPhone(business.location.phone)"
              >
                <span class="icon mx-2">
                  <i class="fa fa-phone"></i>
                </span>
                <span> {{ business.location.phone }} </span>
              </div>
              <div
                class="list-online-data d-flex align-items-start mb-1 p-0"
                v-if="business.location.website"
              >
                <span class="icon mx-2">
                  <i class="fa fa-link"></i>
                </span>
                <a
                  :href="business.location.website"
                  class="mx-3"
                  target="_blank"
                  >{{ $t("website") }}</a
                >
              </div>
            </div>
            <!-- <h2 class="list-address" :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
              v-if="business.postal_codes">{{ $t('diliveryZones') }}</h2>
            <table class="table table-hover w-100" :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
              v-if="business.postal_codes">
              <thead>
                <th scope="col">{{ $t('to') }}</th>
                <th scope="col">{{ $t('from') }}</th>
                <th scope="col">{{ $t('time') }}</th>
              </thead>
              <tbody>
                <tr v-for="day, index in business.postal_codes" :key="index">
                  <td>{{ day.to }}</td>
                  <td>{{ day.from }}</td>
                  <td dir="rtl">{{ day.deliveryTime }}  {{ $t(day.deliveryTimeType) }}</td>
                </tr>
              </tbody>
            </table> -->
            <h2
              class="list-address"
              :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
              v-if="business.workingHours"
            >
              {{ $t("workingHouers") }}
            </h2>

            <table
              class="table table-hover w-100"
              v-if="$i18n.locale == 'ar' && business.workingHours"
              :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
            >
              <thead>
                <th scope="col">{{ $t("to") }}</th>
                <th scope="col">{{ $t("from") }}</th>
                <th scope="col">{{ $t("day") }}</th>
              </thead>
              <tbody>
                <tr v-for="(day, ind) in business.workingHours" :key="ind">
                  <td :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                    {{
                      tConvert(
                        day
                          .replace("-", "")
                          .split(" ")
                          .filter((e) => e.trim())[2]
                      )
                    }}
                  </td>
                  <td :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                    {{
                      tConvert(
                        day
                          .replace("-", "")
                          .split(" ")
                          .filter((e) => e.trim())[1]
                      )
                    }}
                  </td>
                  <td>
                    {{
                      day
                        .replace("-", "")
                        .split(" ")
                        .filter((e) => e.trim())[0]
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-hover w-100"
              :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
              v-else-if="business.workingHours"
            >
              <thead>
                <th scope="col">{{ $t("day") }}</th>
                <th scope="col">{{ $t("from") }}</th>
                <th scope="col">{{ $t("to") }}</th>
              </thead>
              <tbody>
                <tr v-for="(day, ind) in business.workingHours" :key="ind">
                  <td>
                    {{
                      day
                        .replace("-", "")
                        .split(" ")
                        .filter((e) => e.trim())[0]
                    }}
                  </td>
                  <td :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                    {{
                      tConvert(
                        day
                          .replace("-", "")
                          .split(" ")
                          .filter((e) => e.trim())[1]
                      )
                    }}
                  </td>
                  <td :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                    {{
                      tConvert(
                        day
                          .replace("-", "")
                          .split(" ")
                          .filter((e) => e.trim())[2]
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade pb-9"
      :class="width == 'sm' ? 'pr-3 ' : ''"
      id="profileModal"
    >
      <div
        class="mx-2 modal-dialog"
        :class="width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']"
      >
        <div class="modal-content filter-contain">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body p-0">
            <ProductDetails
              v-if="showImage != true"
              :show="business.show_prices"
            />
            <div class="row" v-else :img="business.show_prices">
              <div class="col-12 px-0">
                <img class="modal-image" :src="clickedImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Amenities from "@/components/Listings/Amenities";
import Review from "@/components/Listings/Review.vue";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import PopProduct from "@/components/Listings/PopProduct";
import { mapActions } from "vuex";
import ProductDetails from "@/components/order/ProductDetails.vue";

import { helpers } from "../../Helpers";
export default {
  name: "Profile",
  components: {
    Amenities,
    Review,
    PopProduct,
    NoPlaces,
    ProductDetails,
  },
  data() {
    return {
      showImage: false,
      clickedImage: "",
    };
  },
  mounted() {},
  props: ["business", "hotDeals", "changeTab"],
  mixins: [helpers],
  methods: {
    ...mapActions(["showProduct"]),
    goLink(link) {
      if (link && link != null) {
        window.open(link, "_blank");
      }
      // window.location.href = link;
    },
    removeBrReapeted(desc) {
      const formated = desc.split("<br>");
      return formated.join(" ").split("\r").join(" ");
    },
    tConvert(time) {
      // time = time
      //   .toString()
      //   .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      // if (time.length > 1) {
      //   time = time.slice(1);
      //   time[5] = +time[0] < 12 ? this.$t("am") : this.$t("pm");
      //   time[0] = +time[0] % 12 || 12;
      // }
      return time;
    },
    openModal(id, img) {
      $("#profileModal").modal("show");
      if (id !== 0) {
        this.showImage = false;
        this.showProduct({
          lang: this.$i18n.locale,
          product_id: id,
          show: this.business.show_prices,
        })
          .then()
          .catch((err) => console.error(err));
      } else {
        this.showImage = true;
        this.clickedImage = img;
      }
    },
    goPhone(link) {
      window.location.href = `tel:${link}`;
    },
  },
  computed: {
    width() {
      return this.$store.getters.width;
    },
  },
};
</script>

