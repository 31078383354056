module.exports = {
  pluginOptions: {
    i18n: {
      locale: 'en',
      fallbackLocale: 'en',
      localeDir: 'locales',
      enableInSFC: false
    },
    businesses: {
      'alnabulsi.de': {
        id: 32,
        name: 'Nabulsi Market',
        logo: 'https://app.snawnaw.com/storage/app/public/6922/56344501.jpg',
        links: {
          googlePlay: 'https://play.google.com/store/apps/details?id=com.nabulsi.market',
          ios: 'https://apps.apple.com/pe/app/id1558343303',
          huawei: 'https://appgallery.huawei.com/#/app/C104001035',
        },
        style: 'alnabulsi',
        favicon: 'https://app.snawnaw.com/storage/app/public/6922/56344501.jpg',
        slides: 'nabulsi_web_main',
      },
      'gemini-restaurant.de': {
        id: 6386,
        name: 'Gemini Restaurant',
        logo: 'https://app.snawnaw.com/storage/app/public/28611/conversions/gimmini-icon.jpg',
        links: {
          instagram: 'https://instagram.com/gemini_dresden',
          // ios: 'https://apps.apple.com/pe/app/id1558343303',
          // huawei: 'https://appgallery.huawei.com/#/app/C104001035',
        },
        style: 'gimine',
        favicon: 'https://app.snawnaw.com/storage/app/public/28611/conversions/gimmini-icon.jpg',
        slides: 'gemini_web_main',
      },
      'damaskusrestaurant.de': {
        id: 6387,
        name: 'Damaskus Center Restaurant',
        logo: 'https://app.snawnaw.com/storage/app/public/28720/291241644_110130181758476_857065102369742833_n.jpg',
        links: {
          // instagram: 'https://instagram.com/gemini_dresden',
          facebook: "https://www.facebook.com/orientalisches.restaurant.dresden"
          // ios: 'https://apps.apple.com/pe/app/id1558343303',
          // huawei: 'https://appgallery.huawei.com/#/app/C104001035',
        },
        style: 'damaskus',
        favicon: 'https://app.snawnaw.com/storage/app/public/28720/291241644_110130181758476_857065102369742833_n.jpg',
        slides: 'damaskus_web_main',
      },
    },
  },
  devServer: {
    disableHostCheck: true
  }
}
