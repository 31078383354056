<template>
  <section class="container px-0 ">
    <div class="row">
      <div class="col-12 ">
        <div class="d-flex justify-content-end mb-4" :class="$i18n.locale == 'ar' ? 'flex-row' : 'flex-row-reverse'">
          <div class="align-self-end d-flex" v-if="false">
            <button class="
                btn-icon-black
                d-flex
                align-items-center
                justify-content-center
              " @click="ar ? swiper.slideNext() : swiper.slidePrev()" v-if="width != 'sm'">
              <i class="fa fa-chevron-left"></i>
            </button>
            <button class="
                btn-icon-black
                d-flex
                align-items-center
                justify-content-center
              " @click="ar ? swiper.slidePrev() : swiper.slideNext()" v-if="width != 'sm'">
              <i class="fa fa-chevron-right"></i>
            </button>
          </div>
          <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'" class="mouse">
            <div class="header-home pointer" @click="goSearch"> {{ $t("aroundMe") }} </div>
            <div class="subheader-home"> {{ $t("subheader") }} </div>
          </div>
        </div>
      </div>
      <div class="col-12 px-0">
        <NoPlaces v-if="!exist" :msg="$t('noPlaces')" :imgWith="true" :img="require('../assets/images/empty.png')" />
        <div v-else :dir="$i18n.locale == 'ar' ? 'rtl' : ''" class="w-100 ">
          <Swiper class="swiper around-places-slider pb-4 px-0 " :options="swiperOptions"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
            <SwiperSlide style="padding: 0 12px !important" class="px-0 col-auto order-now-product-slide"
              v-for="item in aroundMe" :key="item.id">
              <Highlited :item="item" />
            </SwiperSlide>
            <SwiperSlide class="col-auto" style="padding: 0 12px !important">
              <div class="mainBody-High shipping-card-more-btn w-100" @click="goSearch">
                <div class="text-center justify-content-center d-flex">
                  <span> {{ $t("SeeMore") }} </span>
                  <i class="fa align-self-center mx-2" :class="
                    $i18n.locale == 'ar'
                      ? ['fa-arrow-left', '']
                      : 'fa-arrow-right '
                  "></i>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <!-- <div class="col-auto my-auto mx-5" v-if="false">
            <div class="mainBody-High shadow-eff">
              <div class="text-center justify-content-around d-flex">
                <span> {{ $t("SeeMore") }} </span>
                <i class="fa align-self-center" :class="
                  $i18n.locale == 'ar'
                    ? ['fa-arrow-left', '']
                    : 'fa-arrow-right '
                "></i>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="text-center my-5">
          <button @click="goSearch" class="
              btn-icon-sec-square
              pointer
              d-flex
              align-items-center
              justify-content-center
              px-5
              w-100
              mx-auto
            " style="max-width: 300px"> {{ $t("SeeMore") }} </button>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import NoPlaces from "@/components/misc/NoPlaces.vue";
import { helpers } from "../Helpers";
import Highlited from "./Listings/Highlited.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "AroundMe",

  mixins: [helpers],
  components: {
    NoPlaces,
    Highlited,
    Swiper,
    SwiperSlide,
  },

  watch: {
    AroundMe: function (newAround, oldAround) {
      // console.log(this.aroundMe, this.AroundMe)
    },
  },

  mounted() {
    // console.log(this.aroundMe, this.AroundMe)
  },
  data() {
    return {
      swiperOptions: {
        autoplay: false,
        slidesPerView: "auto",
      },
    };
  },

  props: ["aroundMe", "exist"],
  computed: {
    width() {
      return this.$store.getters.width;
    },
    swiper() {
      return this.$refs.AroundMeSlider.$swiper;
    },
    AroundMe() {
      return this.$store.getters.aroundMe;
    },
    ar() {
      return this.$i18n.locale == "ar";
    },
  },

  methods: {
    goSearch() {
      localStorage.clear();
      this.$router.push({
        name: "SearchResults",
      });
    },
  },
};
</script>

<style scoped>
.avatar {
  border-radius: 50%;
  width: 100px;
}

.cat-icon {
  top: -35px;
  left: -30px;
}
</style>

