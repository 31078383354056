import Axios from 'axios';

const search = {
  state: {
    searchResults: [],
    cities: [],
    categories: [],
    searched: false,
    all_categories: [],
    listProducts: {},
    allTags: [],
  },

  getters: {
    searchResults: (state) => state.searchResults,
    cities: (state) => state.cities,
    categories: (state) => state.categories,
    all_categories: (state) => state.all_categories,
    allTags: (state) => state.allTags,
    listProducts: (state) => state.listProducts,
  },

  mutations: {
    setSearchResults(state, payload) {
      state.searchResults = { ...payload };
    },
    setAllCategories(state, payload) {
      state.categories = [...payload];
    },
    setAll_Categories(state, payload) {
      state.all_categories = [...payload];
    },
    setAllCities(state, payload) {
      state.cities = [...payload];
    },
    setAllTags(state, payload) {
      state.allTags = { ...payload };
    },
    setListedProducts(state, payload) {
      state.lisProducts = { ...payload }
    },
  },

  actions: {
    getSearchResults({ commit }, payload) {
      const params = {
        lang: payload.locale,
        text: payload.text,
        category_id: payload.categoryId,
        city_id: payload.cityId,
        page: payload.page,
        paginate: 10,
        booking_only: payload.booking,
        has_products: payload.has_products,
        cities: true,
        categories: true,
      }
      payload.booking ? false : delete params.booking_only;
      payload.categoryId ? false : delete params.category_id;
      payload.categoryId ? false : delete params.city_id;
      payload.has_products ? false : delete params.has_products;
      payload.text ? false : delete params.text;
      return new Promise((resolve, reject) => {
        Axios.post(`searchBusinesses`, null, {
          params: params
        }).then(res => {
          commit('setSearchResults', res.data.data);
          commit('setAllCategories', res.data.categories);
          commit('setAllCities', res.data.cities);
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      })
    },
    getAllCategories({ commit }, payload) {
      Axios.get(`businessCategories`, {
        params: {
          lang: payload.lang,
          tree: payload.tree,
          // parent_id: payload.parent_id
        }
      }).then(res => {
        commit('setAll_Categories', res.data.data)
      }).catch(err => console.log(err))
    },
    getAllTags({ commit }, payload) {
      // let token =  cookies.get('user_token');
      Axios.post(`business/tags?api_token=${payload.api_token}`, {
        params: {
          lang: payload.lang,
          api_token: payload.api_token,
        }
      }).then(res => {
        commit('setAllTags', res.data.data)
      }).catch(err => console.log(err))
    },
    // getAllCities({commit}, payload) {
    //   Axios.get(`cities`, {
    //     params: {
    //       lang: payload
    //     }
    //   }).then(res => {
    //     commit('setAllCities', res.data.data)
    // }
  },
  listProducts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Axios.post(`listProducts`, {
        business_id: payload.business_id,
        business_category_id: payload.business_category_id,
        category_id: payload.category_id,
        page: payload.page,
        limit: payload.limit,
        sort_by: payload.sort_by,
        sort_as: payload.sort_as,
        lang: payload.lang,
        brand_id: payload.brand_id,
        api_token: payload.api_token,

      }).then(res => {
        if (res.data.success == ture) {
          commit('setListedProducts', res.data.data)
          resolve(res)
        } else reject(res);
      }).catch(err => reject(err));

    })
  },


}

export default search;