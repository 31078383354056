<template>
  <div class="search-pos">
    <div class="container  form-searchable ">
      <div class="row search-contain px-0 py-md-3 p-1 justify-content-center">
        <form @submit.prevent="search(text, cityId)" class="col-12 px-0">
          <div class="row justify-content-center align-items-end px-0" :class="ar ? 'flex-row-reverse' : 'flex-row'">
            <div class="form-group m-0 col-12 px-0 col-md-6 px-md-2">
              <label class="home-search-label d-none d-md-block pointer"
                :class="[ar ? 'f-right' : 'f-left', ar ? 'before-right' : '']" for="whatInput"> {{ $t("find") }}
              </label>
              <input id="whatInput" :class="ar ? 'text-right' : 'text-left'" class="form-control home-text-input"
                v-model="text" :placeholder="$t('line1')" />
            </div>
            <!-- <div class="ver-divider col-md-1"></div> -->
            <div class="form-group m-0 col-12 col-md-5 px-0 px-md-2">
              <label class="home-search-label d-none d-md-block pointer"
                :class="[ar ? 'f-right' : 'f-left', ar ? 'before-right' : '']" for="locSeletor"> {{ $t("location") }}
              </label>
              <select v-model="cityId" name="type" id="locSeletor" class="form-control home-text-input p-2"
                :class="$i18n.locale == 'ar' ? 'text-right' : ''">
                <option class="text-secondary" value="" disabled selected> {{ $t("PlaceCity") }} </option>
                <option value="">None</option>
                <option v-for="city in cities" :key="city.id" :value="city.id"> {{ city.name }} </option>
              </select>
            </div>
            <div class="col-12 col-md-1 m-0  my-2 px-0">
              <button class="home-submit m-0 p-0 mx-auto" type="submit">
                <i class="fa fa-search"></i>
                <span class="d-md-none"> {{ $t("search") }} </span>
              </button>
              <div class="or" v-if="width == 'sm'">{{ $t('or') }}</div>
              <LocationForm v-if="width == 'sm'" class="mob-interface" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="justify-content-center row mt-md-5 mt-4" :class="ar ? 'flex-row-reverse' : 'flex-row'"
      v-if="getInspired">
      <Chip class="pointer" v-for="item in chipsData" :key="item.id" :item="item" :goSearchResults="goSearchResults" />
    </div>
  </div>
</template>


<script>
import Chip from "@/components/misc/Chip";
import LocationForm from "./LocationForm.vue";
export default {
  components: {
    Chip,
    LocationForm
  },
  data() {
    return {
      text: "",
      cityId: "",
    };
  },
  mounted() {
    this.$store.dispatch("getSearchResults", {
      locale: this.$i18n.locale,
    });
  },
  props: ["search", "getInspired", "goSearchResults"],
  computed: {
    cities() {
      return this.$store.getters.cities;
    },
    ar() {
      return this.$i18n.locale == "ar";
    },
    width() {
      return this.$store.getters.width;
    },
    chipsData() {
      return [
        {
          id: 50,
          name: this.$t("restaurant"),
        },
        {
          id: 21,
          name: this.$t("supermarket"),
        },
        {
          id: 14,
          name: this.$t("mosque"),
        },
      ];
    },
  },
};
</script>