import Axios from 'axios';

const place = {
  state: {
    categoryBusinesses: {},
    busHotDeals: {},
    business: {},
    businessProducts: [],
  },

  getters: {
    categoryBusinesses: (state) => state.categoryBusinesses,
    busHotDeals: (state) => state.busHotDeals,
    business: (state) => state.business,
    businessProducts: (state) => state.businessProducts,
  },

  mutations: {
    setCategoryResults(state, payload) {
      state.categoryBusinesses = { ...payload };
    },
    setAllProducts(state, payload) {
      state.busHotDeals = [...payload];
    },
    setBusiness(state, payload) {
      state.business = { ...payload };
    },
    setBusinessProducts(state, payload) {
      state.businessProducts = [...payload]
    }
  },

  actions: {
    getCategoryBusinesses({ commit }, payload) {
      Axios.post(`searchBusinesses`, null, {
        params: {
          category_id: payload,
          paginate: 10
        }
      }).then(res => {
        commit('setCategoryResults', res.data);
      }).catch(err => console.error(err));
    },
    postBusinessById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`searchBusinesses`, null, {
          params: {
            lang: payload.locale,
            text: payload.text,
            category_id: payload.categoryId,
            city_id: payload.cityId,
            page: payload.page,
            paginate: 10,
            booking_only: payload.booking,
            halls: true,
            times: true,
            business_id: payload.business_id,
            withTags: true,
            withCategories: true,
            // categories: true
          }
        }).then(res => {
          commit('setBusiness', res.data.data.data[0]);
          resolve(res);
        }).catch(err => {
          console.error(err);
          reject(err);
        });
      })
    },
    getBusinessById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.get(`business/${payload.business_id}?lang=${payload.lang}`)
          .then(res => {
            commit('setBusiness', res.data.data);
            resolve(res);
          }).catch(err => {
            console.error(err);
            reject(err);
          });
      })
    },
    getHotDeals({ commit }, payload) {
      Axios.post(`listProducts`, null, {
        params: {
          lang: payload.lang,
          page: payload.page || 1,
          business_id: payload.business_id,
          hotdeals: 1,
          limit: 8
        }
      }).then(res => {
        commit('setAllProducts', res.data.data.data);
      }).catch(err => console.error(err))
    },
    getBusinessProducts({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`listCategoryProducts`, null, {
          params: {
            lang: payload.lang,
            business_id: payload.business_id,
            category_id: payload.category_id
          }
        }).then(res => {
          commit('setBusinessProducts', res.data.data);
          resolve(res);
        }).catch(err => reject(err));
      })
    },
    postReview({ commit }, paylaod) {
      return new Promise((resolve, reject) => {
        Axios.post('business-reviews-store', null, {
          params: {
            business_id: paylaod.business_id,
            api_token: paylaod.user_token,
            review: paylaod.review,
            rate: paylaod.rate
          }
        }).then(res => {
          console.log(res);
          if (res.data.success == true) {
            commit('addReview', res, data, data)
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(err => {
          reject(err);
        })
      })
    }
  }
}

export default place;