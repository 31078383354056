import Axios from 'axios';
import cookies from 'vue-cookies';

// Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.common['Accept'] = 'application/json';

const order = {
  state: {
    product: {},
    shipMethods: [],
    payMethods: [],
    postCodes: [],
    myOrders: [],
    myReservations: {},
    order: {},
    cartItems: [],
    canceled: [],
    // myAddress: {},
    userAddresses: [],
    availableBlocks: 0,
    bookDetails: {},
    services: [],
    profileBusiness: [],
    privateKey: '',
    reserveResults: {},
  },

  getters: {
    product: (state) => state.product,
    cartItems: (state) => state.cartItems,
    canceled: (state) => state.canceled,
    shipMethods: (state) => state.shipMethods,
    payMethods: (state) => state.payMethods,
    postCodes: (state) => state.postCodes,
    myOrders: (state) => state.myOrders,
    myReservations: (state) => state.myReservations,
    order: (state) => state.order,
    // myAddress: (state) => state.myAddress,
    userAddresses: (state) => state.userAddresses,
    availableBlocks: (state) => state.availableBlocks,
    bookDetails: (state) => state.bookDetails,
    services: (state) => state.services,
    profileBusiness: (state) => state.profileBusiness,
    privateKey: (state) => state.privateKey,
    reserveResults: (state) => state.reserveResults,
  },

  mutations: {
    setProduct(state, payload) {
      state.product = { ...payload };
    },
    addToVCart(state, payload) {
      if (localStorage.getItem('__CIs')) {
        var items = JSON.parse(localStorage.getItem('__CIs'));
      } else {
        var items = [];
      }
      try {
        const inCart = items.findIndex(i => i.product_id == payload.product_id);
        if (inCart != -1) {
          items[inCart] = payload
        } else {
          items.push(payload);
        }
      } catch (error) {
        console.log(error);
        var items = [];
        items.push(payload);
      }
      localStorage.setItem('__CIs', JSON.stringify(items));
    },
    cartBusinessId(state, payload) {
      cookies.set('BusId', payload)
    },
    removeFromVCart(state, payload) {
      let items = JSON.parse(localStorage.getItem('__CIs'));
      let index = items.findIndex(el => el.product_id == payload);
      let indexInSt = state.cartItems.findIndex(el => el.product_id == payload);
      if (index == 0 && items.length == 1) {
        localStorage.removeItem('__CIs')
        cookies.remove('BusId');
      } else {
        items.splice(index, 1);
        // localStorage.setItem('__CIs', JSON.stringify(items))
        localStorage.setItem('__CIs', JSON.stringify(items))
      }
      if (indexInSt != false || indexInSt != -1) {
        state.cartItems.splice(indexInSt, 1);
      }
    },
    destroyVCart(state) {
      localStorage.removeItem('__CIs')
      cookies.remove('BusId');
      state.postCodes = [];
      state.shipMethods = [];
      state.payMethods = [];
    },
    setMethods(state, payload) {
      state.shipMethods = [...payload.shipping_methods];
      state.payMethods = [...payload.payment_methods];
      if (payload.postal_codes != null) {
        state.postCodes = [...payload.postal_codes];
      }
    },
    addHoldId(state, payload) {
      cookies.set('hold', payload.id);
      if (payload.postal_codes != null) {
        cookies.set('postal', JSON.stringify(payload.postal_codes))
      }
    },
    addOrderId(state, payload) {
      if (cookies.isKey('orders')) {
        var items = JSON.parse(cookies.get('orders'));
      } else {
        var items = [];
      }
      items.push(payload);
      cookies.set('orders', JSON.stringify(items));
    },
    removeOrderId(state, payload) {
      if (cookies.isKey('canceled')) {
        var items = JSON.parse(cookies.get('canceled'));
      } else {
        var items = [];
      }
      items.push(payload);
      state.canceled = [...items];
      cookies.set('canceled', JSON.stringify(items));

    },
    setMyOrders(state, payload) {
      if (payload.length > 0) {
        state.myOrders = [...payload];
      }
    },
    setOrder(state, payload) {
      state.order = { ...payload }
    },
    setCartItems(state, payload) {
      if (payload && payload.length > 0) {
        state.cartItems = [...payload];
      } else {
        state.cartItems = [];
      }
    },
    setCanceled(state, payload) {
      if (payload && payload.length > 0) {
        state.canceled = [...payload];
      }
    },
    addAddress(state, payload) {
      cookies.set('addressId', payload.id);
      // cookies.set('addressObj', payload);
    },
    // setAddress(state, payload) {
    //   if(cookies.isKey('addressObj')){
    //     state.myAddress = cookies.get('addressObj');
    //   }else return 0
    // },
    setUserAddresses(state, payload) {
      state.userAddresses = [...payload];
    },
    updateUserAddress(state, payload) {
      let index = state.userAddresses.indexOf(state.userAddresses.find(el => el.id == payload.id));
      state.userAddresses.splice(index, 1, payload);
    },
    deleteUserAddress(state, payload) {
      let index = state.userAddresses.indexOf(state.userAddresses.find(el => el.id == payload));
      state.userAddresses.splice(index, 1);
    },
    setAvailableBlocks(state, payload) {
      state.availableBlocks = payload;
    },
    setBookDetails(state, payload) {
      state.bookDetails = { ...payload };
      state.services = [...payload.business.times];
    },
    deleteAllAvailables(state) {
      state.availableBlocks = 0;
    },
    setMyReservations(state, payload) {
      state.myReservations = { ...payload };
    },
    setProfileBusiness(state, payload) {
      state.profileBusiness = { ...payload };
    },
    setPrivateKey(state, payload) {
      state.privateKey = payload;
    },
    setReservationResults(state, payload) {
      state.reserveResults = { ...payload };
    },
  },

  actions: {
    postCheckIn({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post('attends/checkin', {
          business_id: payload.buisness_id,
          source_id: 3,
          attenders: payload.attenders,
          // user_id: 22
        }).then(res => {
          if (res.data.success) {
            resolve(res);
          }
        }).catch(err => reject(err));
      })
    },
    postReservation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post('buisness/booking/book', null, {
          params: {
            buisness_id: payload.buisness_id,
            source_id: 3,
            attenders: payload.attenders,
            // user_id: payload.user_id
          }
        }).then(res => {
          if (res.success) {
            resolve(res);
          }
        }).catch(err => reject(err));
      })
    },

    /// Product related Ordering 
    showProduct({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`showProduct`, {
          lang: payload.lang,
          product_id: payload.product_id
        }).then(res => {
          if (res.data.success == true) {
            commit('setProduct', res.data.data)
            resolve(res);
          } else reject(res);
        }).catch(err => reject(err));
      })
    },
    addToCart({ commit }, payload) {
      let token = cookies.get('user_token') || cookies.get('guest');
      let BusId = cookies.get('BusId');
      let products = JSON.stringify(payload.products)
      let price = JSON.stringify(payload.price)
      let quantity = JSON.stringify(payload.quantity)
      return new Promise((resolve, reject) => {
        Axios.post(`product_orders?api_token=${token}`, {
          lang: payload.lang,
          business_id: BusId,
          products: products,
          price: price,
          quantity: quantity,
        }, {}).then(res => {
          if (res.data.success == true) {
            commit('addHoldId', res.data.data)
            commit('setMethods', res.data.data);
            resolve(res);
          } else reject(res);
        }).catch(err => reject(err));
      })
    },
    checkOutOrder({ commit }, payload) {
      let payment = () => {
        if (payload.payment == 'cod') return 1;
        if (payload.payment == 'paypal') return 2;
        if (payload.payment == 'credit on delivery') return 3;
      };
      let token = cookies.get('guest') || cookies.get('user_token');
      let BusId = cookies.get('BusId');
      let hold = cookies.get('hold');
      let addressId = cookies.get('addressId');
      return new Promise((resolve, reject) => {
        Axios.post(`orders`, {
          amount: payload.total,
          tax: payload.tax,
          buisness_id: BusId,
          order_id: hold,
          payment_id: payment(),
          shipping_id: payload.shipping,
          delivery_cost: payload.deliveryCost,
          client_name: payload.name,
          client_phone: payload.phone,
          api_token: payload.api_token || token,
          // postal_code: payload.postal_code,
          // street_name: payload.street,  
          // apartment_no: payload.houseNo,
          coupon_id: payload.coupon_code,
          hint: payload.comment,
          user_address_id: addressId,
          table_number: payload.table_number,
          hall_id: payload.hall_id,
          // coupon_id: ,

        }).then(res => {
          if (res.data.success == true) {
            commit('addOrderId', cookies.get('hold'));
            resolve(res);
          } else reject(res);
        }).catch(err => reject(err))
      })
    },
    getMyOrders({ commit }) {
      var token = cookies.get('user_token') || cookies.get('guest');
      return new Promise((resolve, reject) => {
        if (cookies.get('guest') && cookies.get('guest') != null) {
          if (cookies.isKey('orders') && JSON.parse(cookies.get('orders')).length > 0) {
            var items = JSON.parse(cookies.get('orders'));
          } else {
            reject('No Orders Found');
            return 0;
          }
        }
        Axios.post(`myOrders?api_token=${token}`, {
          order_ids: items
        })
          .then(res => {
            if (res.data.success == true) {
              commit('setMyOrders', res.data.data);
              resolve(res);
            } else reject(res);
          }).catch(err => reject(err));
      })
    },
    getOrder({ commit }, payload) {
      let token = cookies.get('guest') || cookies.get('user_token');
      return new Promise((resolve, reject) => {
        Axios.post(`showOrder?api_token=${token}`, {
          order_id: payload,
        })
          .then(res => {
            if (res.data.success == true) {
              commit('setOrder', res.data.data);
              resolve(res);
            } else reject(res);
          }).catch(err => reject(err));
      })
    },
    cancelOrder({ commit }, payload) {
      let token = cookies.get('guest') || cookies.get('user_token');
      return new Promise((resolve, reject) => {
        Axios.post(`orders/cancel/${payload}?api_token=${token}`)
          .then(res => {
            if (res.data.success == true) {
              commit('removeOrderId', payload);
              resolve(res);
            } else reject(res);
          }).catch(err => reject(err));
      })
    },
    getAllCanceled({ commit }) {
      commit('setCanceled', JSON.parse(cookies.get('canceled')))
    },
    getAllCartItems({ commit }) {
      commit('setCartItems', JSON.parse(localStorage.getItem('__CIs')))
    },
    checkPhone({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`validate/phone/${payload}`)
          .then(res => {
            if (res.data.success == true) {
              resolve(res);
            } else reject(res)
          }).catch(err => reject(err));
      })
    },
    setDefaultAddress({ commit, dispatch, getters }, payload) {
      let token = cookies.get('user_token');
      dispatch('getGeolocation', payload.postal_code)
        .then(res => {
          console.log(payload)
          if (res.data.results.length > 0) {
            return new Promise((resolve, reject) => {
              Axios.post(`registerAddress`, {
                api_token: payload.api_token || token,
                longitude: getters.geolocation.lng,
                latitude: getters.geolocation.lat,
              }).then(res => {
                if (res.data.success == true) {
                  commit('setUser', res.data.data)
                  resolve(res);
                } else reject(res)
              }).catch(err => reject(err))
            })
          } else new Error('NoResultsFound');
        }).catch(err => new Error(err));

    },
    createAddress({ dispatch, commit }, payload) {
      let token = cookies.get('user_token') || cookies.get('guest');
      return new Promise((resolve, reject) => {
        Axios.post(`addAddress`, {
          api_token: payload.api_token || token,
          postal_code: payload.postal_code,
          apartment_no: payload.apartment_no,
          street_name: payload.street_name,
          address: payload.address,
          phone: payload.phone,
          longitude: payload.longitude,
          lattitude: payload.latitude,
        }).then(res => {
          if (res.data.success == true) {
            if (cookies.get('user_token')) {
              dispatch('getUserAddresses', payload.api_token);
            }
            commit('addAddress', res.data.data);
            resolve(res);
          } else reject(res)
        }).catch(err => reject(err))
      })
    },
    updateAddress({ commit }, payload) {
      let token = cookies.get('user_token') || cookies.get('guest');
      return new Promise((resolve, reject) => {
        Axios.post(`updateAddress`, {
          api_token: payload.api_token || token,
          postal_code: payload.postal_code,
          apartment_no: payload.apartment_no,
          street_name: payload.street_name,
          address: payload.address,
          address_id: payload.id || payload.address_id,
          phone: payload.phone,
          longitude: payload.longitude,
          lattitude: payload.latitude,
        }).then(res => {
          if (res.data.success == true) {
            commit('updateUserAddress', res.data.data);
            resolve(res);
          } else reject(res)
        }).catch(err => reject(err))
      })

    },
    deleteAddress({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`destroyAddress`, {
          api_token: payload.api_token,
          address_id: payload.address_id,
        }).then(res => {
          if (res.data.success == true) {
            commit('deleteUserAddress', payload.address_id);
            resolve(res);
          } else reject(res);
        }).catch(err => reject(err))
      })
    },
    getUserAddresses({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`listAddresses`, {
          api_token: payload || cookies.get('user_token')
        }).then(res => {
          if (res.data.success == true) {
            commit('setUserAddresses', res.data.data);
            resolve(res);
          } else reject(res);
        }).catch(err => reject(err))
      })
    },
    updatePayment({ commit }, payload) {
      console.log('updatePayment', payload)
      let token = cookies.get('user_token') || cookies.get('guest');
      return new Promise((resolve, reject) => {
        Axios.post(`orders/update?api_token=${token}`, {
          order_id: payload.order_id,
          payment_id: payload.payment_id,
          secret_word: payload.secret_word,
          status: payload.status,
          transaction_id: payload.transaction_id,
          fees: payload.fees,
          _method: "PATCH",
        }).then(res => {
          if (res.data.success == true) {
            resolve(res)
          } else reject(res);
        }).catch(err => reject(err))
      })
    },
    validateCoupon({ commit }, payload) {
      let token = cookies.get('user_token') || cookies.get('guest');
      return new Promise((resolve, reject) => {
        Axios.post(`validateCoupon?api_token=${token}`, {
          business_id: payload.business_id,
          coupon_code: payload.coupon_code
        }).then(res => {
          if (res.data.success == true) {
            resolve(res)
          } else reject(res);
        }).catch(err => reject(err))
      })
    },
    getAvailableBlocks({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`business/booking/${payload.business_id}/search`, {
          count: true,
          from_date: payload.from_date,
          time_id: payload.time_id,
          hall_id: payload.hall_id,
          time: payload.time,
        }).then(res => {
          if (res.data.success == true) {
            commit('setAvailableBlocks', res.data.data)
            resolve(res)
          } else reject(res);
        }).catch(err => reject(err))
      })
    },
    reservationTimeID({ commit }, payload) {
      let token = cookies.get('user_token');
      // console.log(payload)
      return new Promise((resolve, reject) => {
        Axios.post(`business/booking/book`, {
          business_id: payload.business_id,
          api_token: payload.api_token || token,
          source_id: 3,
          attenders: [...payload.attenders],
          hall_id: payload.hall_id,
          persons: payload.persons,
          on_date: payload.on_date,
          time_id: payload.time_id,
          total_amount: payload.total_amount,
          from: payload.from,
        }).then(res => {
          if (res.data.success == true) {
            commit('setReservationResults', res.data.data)
            resolve(res);
          } else reject(res);
        }).catch(err => reject(err))
      })
    },
    attendsCheckin({ commit }, payload) {
      let token = cookies.get('user_token');
      console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post(`attends/checkin`, {
          source_id: 3,
          api_token: payload.api_token || token,
          business_id: payload.business_id,
          attenders: [...payload.attenders]
        }).then(res => {
          if (res.data.success == true) {
            resolve(res);
          } else reject(res);
        }).catch(err => reject(err))
      })
    },
    updateReservePay({ commit }, payload) {
      let token = cookies.get('user_token');
      return new Promise((resolve, reject) => {
        Axios.post(`business/booking/payment`, {
          reservation_id: payload.reservation_id,
          api_token: payload.api_token || token,
          secret_word: payload.secret_word,
          status: payload.status,
          total_paid: payload.total_paid,
          method: payload.method,
          transaction_id: payload.transaction_id,
        }).then(res => {
          if (res.data.success == true) {
            resolve(res);
          } else reject(res);
        }).catch(err => reject(err))
      })
    },
    getBookingDetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`business/booking/${payload.business_id}/fetch`, {
          lang: payload.lang,
          id: payload.hall_id,
        }).then(res => {
          if (res.data.success == true) {
            commit('setBusiness', res.data.data.business);
            commit('setBookDetails', res.data.data);
            resolve(res);
          } else reject(res)
        }).catch(err => reject(err));
      })
    },
    emptyAvailableBlocks({ commit }) {
      commit('deleteAllAvailables');
    },
    getMyReservations({ commit }, payload) {
      let token = cookies.get('user_token');
      return new Promise((resolve, reject) => {
        Axios.post(`business/booking/reservations`, {
          page: payload.page,
          api_token: payload.api_token || token,
          lang: payload.lang,
          order_id: payload.reservation_id
        })
          .then(res => {
            if (res.data.success == true) {
              commit('setMyReservations', res.data.data);
              resolve(res);
            } else reject(res)
          }).catch(err => reject(err));
      })
    },
    attackOrderToReservation({ commit }, payload) {
      let token = cookies.get('user_token');
      return new Promise((resolve, reject) => {
        Axios.post(`business/booking/order`, {
          api_token: payload.api_token || token,
          reservation_id: payload.reservation_id,
          order_id: payload.order_id,
        }).then(res => {
          if (res.data.success == true) {
            resolve(res);
          } else reject(res)
        }).catch(err => reject(err));
      })
    },
    getProfileBusiness({ commit }, payload) {
      let token = cookies.get('user_token');
      return new Promise((resolve, reject) => {
        Axios.get(`listBusinesses?own=1&api_token=${payload.api_token}&page=${payload.page}`, {
          api_token: payload.api_token || token,
          own: 1,
          page: payload.page,
        }).then(res => {
          if (res.data.success == true) {
            commit('setProfileBusiness', res.data.data);
            resolve(res);
          } else reject(res)
        }).catch(err => reject(err));
      })
    },
    createPrivateKey({ commit }, payload) {
      let token = cookies.get('user_token');
      return new Promise((resolve, reject) => {
        Axios.post(`private/create`, {
          api_token: payload.api_token || token,
        }).then(res => {
          if (res.data.success == true) {
            commit('setPrivateKey', res.data.data.p_key);
            resolve(res);
          } else reject(res)
        }).catch(err => reject(err));
      })
    },


  }
}

export default order;