import Home from '../views/Home.vue';

export default [
  {
    path: '',
    name: 'Home',
    component: Home
  },
  {
    path: 'business',
    name: 'BusinessProfile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/BusinessProfile.vue'),
  },
  {
    path: 'login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../components/forms/UserLogin.vue'),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: 'verify-account',
    name: 'VerifyAccount',
    component: () => import(/* webpackChunkName: "login" */ '../components/forms/VerifyAccount.vue'),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: 'register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../components/forms/Register.vue'),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: 'about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: 'privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: 'order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "forms" */ '../views/OrderLayout.vue'),
    meta: {
      requireAuth: true,
    },
    children: [

      {
        path: 'cart',
        name: 'Cart',
        component: () => import(/* webpackChunkName: "forms" */ '../components/order/Cart.vue'),
      },
      {
        path: 'my-orders',
        name: 'MyOrders',
        component: () => import(/* webpackChunkName: "forms" */ '../components/order/MyOrders.vue'),
      },
      {
        path: 'checkout',
        name: 'CheckOut',
        component: () => import(/* webpackChunkName: "forms" */ '../components/order/CheckOut.vue'),
      },
    ]
  },
  {
    path: 'auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "forms" */ '../views/OrderLayout.vue'),
    children: [
      {
        path: 'forgot-password',
        name: 'Forgot',
        component: () => import(/* webpackChunkName: "forms" */ '../components/forms/ForgotPassword.vue'),
      },
      {
        path: 'review',
        name: 'Review',

        component: () => import(/* webpackChunkName: "forms" */ '../components/forms/WriteReview.vue'),
      },
      {
        path: 'issue',
        name: "Issue",
        component: () => import(/* webpackChunkName: "forms" */ '../components/forms/Issue.vue')
      }
    ]
  },
  {
    path: 'profile',
    name: 'UserProfile',
    component: () => import(/* webpackChunkName: "userprofile" */ '../views/ProfileLayout.vue'),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: 'info',
        name: 'ProfileInfo',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileInfo.vue'),
      },
      {
        path: 'settings',
        name: 'ProfileSettings',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileSettings.vue'),
      },
      {
        path: 'business',
        name: 'ProfileBusiness',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileBusiness.vue'),
      },
      {
        path: 'favourites',
        name: 'ProfileFavourites',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileFavourites.vue'),
      },
      {
        path: 'order-history',
        name: 'OrderHistory',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/order/MyOrders.vue'),
      },
      {
        path: 'qr-code',
        name: 'ProfileQR',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileQR.vue'),
      },
      {
        path: 'payment-methods',
        name: 'ProfileMethods',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileMethods.vue'),
      },
      {
        path: 'appintments',
        name: 'ProfileAppintments',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileAppointments.vue'),
      },
      {
        path: 'products',
        name: 'ProfileProducts',
        component: () => import(/* webpackChunkName: "userprofile" */ '../components/profile/ProfileProducts.vue'),
      },
    ],
  }
]