<template>
  <div
    class="home-icons"
    v-if="
      businessHost && Object.entries(businessHost.links).length > 0
        ? true
        : !businessHost
        ? true
        : false
    "
  >
    <div
      class="download-app text-center d-block"
      v-if="inheader"
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    >
      {{ $t("download app") }}
    </div>
    <div
      class="
        icons-wrapper
        px-3
        py-2
        px-1
        d-flex
        flex-column
        pointer
        align-items-center
      "
      :class="openMenu ? 'active' : ''"
    >
      <div
        class="toggler"
        :class="openMenu ? 'active' : ''"
        @click="toggleMainIcons"
      >
        <i class="fa fa-chevron-right"></i>
      </div>
      <div class="my-3" @click="goApple">
        <i class="fa fa-apple"></i>
      </div>
      <div class="my-3" @click="goAndroid">
        <i class="fa fa-android"></i>
      </div>
      <div class="my-3" @click="goGallery">
        <img src="../assets/images/logo/appgallery.png" />
      </div>
      <!-- <div class="my-3" @click='goGoogle'> 
        <i class="fa fa-google"></i>
                  </div> -->
      <div
        class="my-3"
        @click="goInstagram"
        v-if="businessHost && businessHost.links.instagram ? true : false"
      >
        <i class="fa fa-instagram"></i>
      </div>
      <div
        class="my-3"
        @click="goFacebook"
        v-if="
          businessHost && businessHost.links.facebook
            ? true
            : !businessHost
            ? true
            : false
        "
      >
        <i class="fa fa-facebook"></i>
      </div>
      <!-- <Languages class="in-menu-icons" /> -->
      <!-- <div class="my-3" @click='goTwitter'>
                    <i class="fa fa-twitter"></i>
                  </div> -->
    </div>
  </div>
</template>

<script>
// import Languages from './Languages.vue';
import { pluginOptions } from "./../../vue.config";

export default {
  props: ["inheader"],
  data() {
    return {
      openMenu: false,
      businessHost: null,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    $route() {
      this.openMenu = false;
    },
  },
  methods: {
    toggleMainIcons() {
      this.openMenu = !this.openMenu;
    },
    goApple() {
      if (this.businessHost) {
        window.open(this.businessHost.links.ios, "_blank");
      } else {
        window.open("https://apps.apple.com/us/app/id1530699560", "_blank");
      }
    },
    goAndroid() {
      if (this.businessHost) {
        window.open(this.businessHost.links.googlePlay, "_blank");
      } else {
        // window.open("https://apps.apple.com/us/app/id1530699560", "_blank");
        window.open(
          "https://play.google.com/store/apps/details?id=com.snawnawapp",
          "_blank"
        );
      }
    },
    goGallery() {
      if (this.businessHost) {
        window.open(this.businessHost.links.huawei, "_blank");
      } else {
        window.open("https://appgallery.huawei.com/#/app/C103728625", "_blank");
      }
    },
    goInstagram() {
      window.open(this.businessHost.links.instagram, "_blank");
    },
    goTwitter() {},
    goGoogle() {},
    goFacebook() {
      window.open(
        this.businessHost.links.facebook ||
          "https://www.facebook.com/snawnawapp",
        "_blank"
      );
    },
  },
  mounted() {
    this.openMenu = true;
    setTimeout(() => {
      this.openMenu = false;
    }, 10000);
    window.addEventListener("scroll", () => {
      this.openMenu = false;
    });
    if (
      pluginOptions.businesses &&
      pluginOptions.businesses[location.hostname]
    ) {
      this.businessHost = pluginOptions.businesses[location.hostname];
      this.$route.params.id = this.businessHost.id;
      this.$store.dispatch("getSlides", this.businessHost.slides);
    }
  },
  // components: { Languages }
};
</script>

<style>
</style>