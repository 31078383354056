import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import * as moment from 'moment';
import LoadScript from 'vue-plugin-load-script';
import * as $ from 'jquery';
import VueCookies from 'vue-cookies'
import VueTheMask from 'vue-the-mask'
import mdiVue from 'mdi-vue'
import * as mdijs from '@mdi/js';
import VueMeta from 'vue-meta';

import interceptorsSetup from '../src/Helpers/interceptors';


// and running it somewhere here
interceptorsSetup();

window.moment = moment;
Vue.use(LoadScript);
Vue.config.productionTip = false;
window.$ = $;
window.jQuery = $;
require('bootstrap');
Vue.use(VueCookies);
Vue.use(VueTheMask)
Vue.use(mdiVue, { icons: mdijs })
Vue.use(VueMeta)

Vue.$cookies.config('1y');


new Vue({
    router,
    store,
    i18n,
    created() {
        this.$root.$moment = moment;
        this.$root.$domain = window.baseURL;

        let funs = {
            unique: function (str) {
                return Math.random().toString(36).substr(2, 9);
            },
            empty: function (val) {
                return !val || (isString(val) && val.trim() == "") || val == "undefined" || val == "0" || val == NaN || val == '0000-00-00';
            },
            isString: function (i) {
                if (i && i != "undefined" && i != undefined)
                    if (typeof i == "string" || typeof i == "STRING")
                        return true;
            },
            copy: function (object) {
                return Object.assign({}, object || {});
            },
            inArray: function (val, arr) {
                if (Array.isArray(val)) {
                    var status = false;

                    for (var i in val) {
                        if (arr.indexOf(val[i]) > -1) {
                            status = true;
                            return true;
                        }
                    }

                    return status;
                }
                else return Array.isArray(arr) && arr.indexOf(val) > -1 ? true : false;
            },
            gotoContent: function (element, obj, speed) {
                if (!obj)
                    obj = {};
                if (jQuery(element).length == 0 || !jQuery(element).offset() || (obj.target && ($(obj.target).length == 0))) {
                    if (obj.callBack) obj.callBack();
                    return;
                }

                var top = jQuery(element).offset().top || 0;
                var left = jQuery(element).offset().left || 0;
                if (obj.top)
                    top += obj.top;
                if (obj.left)
                    left += obj.left;

                jQuery(obj.target || 'html, body').animate(
                    {
                        scrollTop: obj.freezeTop || top,
                        scrollLeft: obj.freezeLeft || left
                    }, (speed || "slow"), null, function () {
                        if (obj.callBack) obj.callBack();
                    });
            },
            isObject: function (i) {
                if (i && i != "undefined" && i != undefined)
                    if (i instanceof Object)
                        return true;
            },
            len: function (target) {
                if (empty(target)) return 0;
                if (isObject(target))
                    return Object.keys(target).length;
                else if (isArray(target) || isString(target))
                    return (target).length;
                else
                    return (target).toString().length;
            },
            isNumeric: function (n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            }
        };

        for (var key in funs) {
            window[key] = this.$root['$' + key] = funs[key];
        }
    },
    render: h => h(App)
}).$mount('#app')
