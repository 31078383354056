<template>
  <div class="container px-2 mt-4">
    <!-- Start Header Centralizer -->
    <div class="row">
      <div class="col-12 px-0">
        <div class="row">
          <div class="col-12 px-0" v-if="slides && slides.length > 0">
            <div id="homeCarousel" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators" v-if="width != 'sm'">
                <li data-target="#homeCarousel" v-for="(slide, index) in slides" :class="index == 0 ? 'active' : ''"
                  :key="index" :data-slide-to="index"></li>
              </ol>
              <div class="carousel-inner my-carousel">
                <div class="carousel-item pointer" data-interval="3000" v-for="(slide, index) in slides"
                  :key="(index + 1) * 100" :class="index == 0 ? 'active' : ''" @click="goLink(slide)">
                  <img :src="slide.url" />
                </div>
              </div>
              <a class="carousel-control-prev" href="#homeCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#homeCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
              <!-- <div>
                    <button class="btn-icon-def carousel-right-icon" @click='carouselNext'><i class="fa fa-arrow-right"></i></button>
                    <button class="btn-icon-def carousel-left-icon" v-if='width != "sm" || width != "md"' @click='carouselPrev'><i class="fa fa-arrow-left"></i></button>
                  </div> -->
              <!-- <div class="justify-content-center row" v-if='width != "sm"'>
                <Chip 
                        class="pointer"
                        v-for='item in getInspired.slice(sliceNumber)' :key='item.id'
                        :item='item'
                        :goSearchResults='goSearchResults'
                      />      
                    </div>      -->
            </div>
            <HomeSearch :search="search" :getInspired="getInspired" :goSearchResults="goSearchResults" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Ends Hero Header -->
</template>

<script>
import { helpers } from "../Helpers";
import HomeSearch from "@/components/forms/HomeSearch";
// import Chip from '@/components/misc/Chip';

export default {
  name: "Banner",
  components: {
    HomeSearch,
    // Chip
  },
  mixins: [helpers],

  props: ["getInspired"],
  data() {
    return {
      text: "",
      categoryId: "",
      cityId: "",
      page: 1,
      type: "",
    };
  },

  mounted() {
    this.$store.dispatch("getSlides");
    $("#homeCarousel").carousel({
      interval: 3000,
    });
    this.$forceUpdate();
  },
  methods: {
    goSearchResults(categoryId, cityId) {
      localStorage.removeItem("text");
      this.$router.push({
        name: "CategoryId",
        params: {
          categoryId: categoryId,
        },
        query: {
          cityId: cityId,
        },
      });
    },
    goCat(id) {
      this.$router.push({
        name: "CategoryId",
        params: {
          categoryId: id,
          locale: this.$i18n.locale,
        },
      });
    },
    goProfile(id, tab = 1) {
      this.$router.push({
        name: "BusinessProfile",
        params: {
          locale: this.$i18n.locale,
          id,
          tab,
          BName: tab == 3 ? "products" : "",
        },
      });
    },
    goProduct(id, tab = 3, productId) {
      this.$router.push({
        name: "BusinessProfile",
        params: {
          locale: this.$i18n.locale,
          id,
          tab,
          tabID: tab == 3 ? "rel" : "",
          productId,
        },
      });
    },

    search(text, cityId) {
      this.$router.push({
        name: "SearchResults",
        query: {
          text: text,
          cityId: cityId,
        },
      });
    },
    chooseCity(id) {
      this.cityId = id;
    },
    carouselNext() {
      $("#homeCarousel").carousel("next");
    },
    carouselPrev() {
      $("#homeCarousel").carousel("prev");
    },

    goGallery() {
      window.open("https://appgallery.huawei.com/#/app/C103728625", "_blank");
    },
    goLink(link) {
      if (link.type == "business_category_id") {
        this.goCat(link.value);
      }
      if (link.type == "business_id") {
        this.goProfile(link.value);
      }
      if (link.type == "product_id") {
        this.goProduct(link.source.business_id, 3, link.value);
      }
      if (link.type == "external_url") {
        window.open(link.value, "_blank");
      }
    },
  },

  computed: {
    cities() {
      return this.$store.getters.cities;
    },
    width() {
      return this.$store.getters.width;
    },
    sliceNumber() {
      return this.width == "lg" ? 1 : 2;
    },
    slides() {
      return this.$store.getters.slides;
    },
  },
};
</script>

<style scoped>
.search-categories {
  padding: 0;
}

.body-position {
  padding: 27rem 3rem;
  position: relative;
  width: 100%;
  margin: 50px auto;
  margin-top: 90px;
  /* border-radius: 20px; */
}

.inform {
  display: inline-block;
  width: 50%;
}

.inform-2 {
  display: inline-block;
  width: 50%;
  border: 0;
  height: 50px;
  background: transparent;
  box-shadow: none;
  color: rgb(186, 19, 126);
  font-family: "Roboto";
  font-size: 17px;
}

.inform-2:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.divider {
  border: 4px solid black;
  border-radius: 20px;
  display: inline;
  margin-inline: 5px;
}

.avatar {
  border-radius: 50%;
}

.category-text {
  margin: 5px 10px;
}

.hero-header:after {
  bottom: -1px;
  border-radius: 20px;
}

.hero-header:after,
.pg-header:after,
.app:after {
  background-image: unset;
}

@media only screen and (min-width: 900px) {
  .more-categories {
    display: none !important;
  }
}

@media (max-width: 750px) {
  .body-position {
    padding: 20rem 3rem;
  }

  .header-centralizer {
    margin-top: 80px;
  }
}
</style>
