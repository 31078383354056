<template>
  <div>
    <!-- :class="width != 'sm' ? 'mx-9' : ''" -->
    <div v-if="!businessHome">
      <!-- <LocationForm v-if="width == 'sm'" /> -->
      <Banner :getInspired="getInspired" />
      <Categories :categories="getInspired" />
      <!-- <Events :events='events' :exist='events.length > 0'/> -->
      <AroundMe :aroundMe="aroundMe" :exist="aroundMe.length > 0" />
      <HotDeals :hotDeals="hotDeals" :exist="hotDeals.length > 0" />
      <OrderNow :orderNow="orderNow" :exist="orderNow.length > 0" />
    </div>
    <BusinessProfile v-else />
  </div>
</template>

<script>
// @ is an alias to /src
import LocationForm from "@/components/forms/LocationForm.vue";
import Banner from "@/components/Banner.vue";
import Categories from "@/components/Categories";
import AroundMe from "@/components/AroundMe.vue";
import OrderNow from "@/components/OrderNow.vue";
import HotDeals from "@/components/HotDeals.vue";
import Events from "@/components/Events.vue";
import { mapGetters, mapActions } from "vuex";
import { pluginOptions } from "/vue.config";
import BusinessProfile from "./BusinessProfile";
import cookies from "vue-cookies";

export default {
  name: "Home",
  components: {
    LocationForm,
    Banner,
    Categories,
    AroundMe,
    OrderNow,
    HotDeals,
    BusinessProfile,
    Events,
  },

  metaInfo() {
    const description = this.$t("SiteDescription");
    const locale = this.$i18n.locale;
    const BHome = this.businessHome;
    if (!BHome) {
      return {
        title: "Snawnaw",
        meta: [
          { name: "description", content: description },
          { property: "og:type", content: "website" },
          { property: "og:url", content: `https://snawnaw.com/${locale}` },
          {
            property: "og:image",
            content: "https://snawnaw.com/img/logo3.ae147c78.png",
          },

          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:description", content: description },
          { name: "twitter:url", content: "https://snawnaw.com/${locale}" },
          {
            name: "twitter:image:src",
            content: "https://snawnaw.com/img/logo3.ae147c78.png",
          },
        ],
      };
    }
  },

  watch: {
    locale: function (newLocale) {
      this.getHomeData({
        locale: newLocale,
        all_categories: true,
        latitude: this.latitude,
        longitude: this.longitude,
      })
        .then()
        .catch((err) => console.error(err));
    },

    // longitude: function(newPos) {
    //   this.getHomeData({
    //     locale: this.$i18n.locale,
    //     latitude: this.latitude,
    //     longitude: this.longitude
    //   })
    //     .then(res => {
    //       console.log(res)

    //     })
    //     .catch(err => console.error(err));
    // },
  },

  created() {
    this.$loadScript("../../../js/bootstrap.bundle.js");
    this.getLocation();
    this.getUserFavourites({
      api_token: cookies.get("user_token"),
      locale: this.$i18n.locale,
    });
  },

  data() {
    return {
      longitude: "",
      latitude: "",
    };
  },

  computed: {
    ...mapGetters([
      "aroundMe",
      "events",
      "getInspired",
      "orderNow",
      "events",
      "hotDeals",
      "width",
      "favourites",
    ]),
    locale() {
      return this.$i18n.locale;
    },
    businessHome() {
      return pluginOptions.businesses &&
        pluginOptions.businesses[location.hostname]
        ? pluginOptions.businesses[location.hostname]
        : null;
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },

  methods: {
    ...mapActions([
      "getHomeData", //also supports payload `this.nameOfAction(amount)`
      "getUserFavourites",
    ]),

    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.success, this.error);
      } else {
        console.log("error");
        if (!this.businessHome) {
          this.getHomeData({
            locale: this.$i18n.locale,
            all_categories: true,
            latitude: this.latitude,
            longitude: this.longitude,
          })
            .then((res) => { })
            .catch((err) => console.error(err));
        }
      }
    },

    success(position) {
      this.longitude = position.coords.longitude;
      this.latitude = position.coords.latitude;
      if (!this.businessHome) {
        this.getHomeData({
          locale: this.$i18n.locale,
          latitude: this.latitude,
          all_categories: true,
          longitude: this.longitude,
        })
          .then((res) => { })
          .catch((err) => console.error(err));
      }
    },

    error() {
      if (!this.businessHome) {
        this.getHomeData({
          locale: this.$i18n.locale,
          latitude: this.latitude,
          longitude: this.longitude,
        })
          .then((res) => { })
          .catch((err) => console.error(err));
      }
    },
  },
};
</script>
