<template>
  <div class="mouse container px-0">
    <div class="row mt-lg-4 mt-2 px-0" v-if="businessHost">
      <div class="col-12 px-0" v-if="slides && slides.length > 0">
        <div class="row">
          <div class="col-12 px-1">
            <div
              id="businessCarousel"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#businessCarousel"
                  data-interval="3000"
                  v-for="(slide, index) in slides"
                  :key="index"
                  :data-slide-to="index"
                  :class="index == 0 ? 'active' : ''"
                ></li>
              </ol>
              <div class="carousel-inner my-carousel" style="height: 100%">
                <div
                  class="carousel-item pointer"
                  v-for="(slide, index) in slides"
                  :key="(index + 1) * 100"
                  :class="index == 0 ? 'active' : ''"
                  @click="goLink(slide.value)"
                >
                  <img :src="slide.url" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 px-0"
        v-else-if="business && business.media && businessHost"
      >
        <div
          class="profile-header py-5 in-template"
          :style="
            business.media && business.media[1]
              ? 'background: url(' +
                business.media[1].url +
                ');background-repeat: no-repeat;   background-size: cover; background-position: center;'
              : null
          "
        >
          <div class="container">
            <div
              class="row px-0"
              :class="{
                ' justify-content-center':
                  !business.media || !business.media[1],
              }"
              v-if="businessHost"
            >
              <div class="col-12 px-0">
                <div class="profile-image my-3 mb-5 text-center">
                  <img
                    :src="
                      business.media && business.media.length > 0
                        ? business.media[0].url
                        : 'https://images.squarespace-cdn.com/content/v1/5387997be4b0bd6d1373cbff/1610119502963-EYNJP5CB5847764TVFRR/ke17ZwdGBToddI8pDm48kDAv91l_EVpAtt3T82Wm1m9Zw-zPPgdn4jUwVcJE1ZvWEtT5uBSRWt4vQZAgTJucoTqqXjS3CfNDSuuf31e0tVGRdKQdAvYPFeoNHcl0B9NbYhRtIalXltUl4wqyICqU51hQUjdct07RUVzezzWpvVo/GraySquare.jpg'
                    "
                  />
                </div>
                <div class="header-profile my-4">
                  {{ business.name }}
                  <div
                    v-show="business.is_open_now"
                    class="badge-pri"
                    style="vertical-align: middle"
                  >
                    {{ $t("open") }}
                  </div>
                  <div
                    class="header-profile-cats pointer my-2 mt-md-3"
                    v-html="business.category.name"
                    @click="
                      businessHost ? goMainCates() : goCat(business.category.id)
                    "
                  ></div>
                </div>
                <div
                  class="d-flex justify-content-center"
                  style="align-items: center"
                >
                  <Stars :stars="parseInt(business.rate)" class="px-2" />
                  <div class="px-2 list-sub-white">{{ business.rate }}</div>
                  <span
                    class="del-badge px-2 d-none d-lg-block"
                    v-if="
                      business &&
                      business.shipping_methods &&
                      business.shipping_methods[0] &&
                      business.shipping_methods[0].id == 2
                    "
                  >
                    <i class="fa fa-check-circle"></i> {{ $t("delivery") }}
                  </span>
                  <span
                    class="del-badge px-2 d-none d-lg-block"
                    v-if="
                      business &&
                      business.shipping_methods &&
                      business.shipping_methods[1] &&
                      business.shipping_methods[1].id == 1
                    "
                  >
                    <i class="fa fa-check-circle"></i> {{ $t("takeAway") }}
                  </span>
                  <!-- <div
                  class="badge-share p-2 pointer"
                  style="vertical-align: middle"
                  @click="shareBusiness(business)"
                >
                  <i class="fa fa-share-alt-square"></i>
                </div> -->
                </div>
                <div
                  class="d-flex align-items-center justify-content-center mt-2"
                >
                  <button
                    class="btn-def my-1"
                    @click="goReserve"
                    v-if="business.allow_booking"
                  >
                    {{ $t("Reserve") }}
                  </button>
                  <button
                    class="btn-def my-1"
                    @click="goCheckin"
                    v-if="business.allow_checkin"
                  >
                    {{ $t("Checkin") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul
      class="nav tabs businessTabs in-template profile-tabs-bs"
      :class="[
        $i18n.locale == 'ar' ? 'flex-row-reverse' : 'flex-row',
        businessHost ? ['', 'mt-n2'] : '',
      ]"
      v-if="business && business.categories && businessHost"
    >
      <li
        class="nav-item tabs-link"
        :class="openedTab == 'desc' ? 'active' : ''"
        @click="
          changeTab('desc');
          !businessHome ? goFetch('desc') : '';
        "
        role="tab"
      >
        {{ $t("info") }}
      </li>
      <li
        class="nav-item tabs-link"
        @click="
          changeTab('rev');
          !businessHome ? goFetch('rev') : '';
        "
        :class="openedTab == 'rev' ? 'active' : ''"
        role="tab"
      >
        {{ $t("reviews") }}
      </li>
      <!-- <span class='info-sec tabs-link' >categories</span> -->
      <li
        class="nav-item tabs-link"
        @click="
          changeTab('rel');
          !businessHome ? goFetch('rel') : '';
        "
        v-if="
          (business.profile_style
            ? business.profile_style.key == 'productWithPrices' ||
              business.profile_style.key == 'productWithoutPrices'
              ? true
              : false
            : true) && business.categories.length > 0
        "
        :class="openedTab == 'rel' ? 'active' : ''"
        role="tab"
      >
        {{ $t("menu") }}
      </li>
    </ul>
    <!-- <NoPlaces class="mt-5" v-else /> -->
    <div
      class="mt-2 container mx-auto px-0"
      :class="[width != 'sm' ? '' : '', businessHost ? 'mt-4' : '']"
    >
      <Profile
        id="desc"
        v-if="openedTab == 'desc' && business.media && !profileLoading"
        :changeTab="changeTab"
        :business="business"
        :hotDeals="busHotDeals"
        class="px-3"
      />
      <Reviews id="rev" v-if="openedTab == 'rev'" :business="business" />
      <BusinessProducts
        id="rel"
        v-if="openedTab == 'rel'"
        :business="business"
      />
    </div>
  </div>
</template>

<script>
import Profile from "@/components/Listings/Profile.vue";
import Amenities from "@/components/Listings/Amenities.vue";
import Reviews from "@/components/Listings/Reviews.vue";
// import ProductsList from '@/components/Listings/ProductsList.vue';
import BusinessProducts from "@/components/Listings/BusinessProducts.vue";
// import BusinessGallery from '@/components/Listings/BusinessGallery.vue';
import NoPlaces from "@/components/misc/NoPlaces.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Stars from "@/components/misc/Stars";
import cookies from "vue-cookies";
import { mapActions, mapGetters } from "vuex";
import { helpers } from "../Helpers";
import { pluginOptions } from "/vue.config";
// import SideFilter from "../components/Listings/SideFilter.vue";

export default {
  name: "BusinessProfile",
  metaInfo() {
    let myBus = this.business;
    let locale = this.$i18n.locale;
    return {
      title: myBus.name,
      meta: [
        {
          name: "description",
          content: myBus.description
            ? this.myhtmlDecode(myBus.description)
            : myBus.name,
        },
        // { property: 'og:title', content: myBus.name },
        // { property: 'og:description', content: myBus.description },
        { property: "og:type", content: "profile" },
        { property: "og:url", content: location.href },
        {
          property: "og:image",
          content: myBus.media
            ? myBus.media[0].url
            : "https://snawnaw.com/img/logo3.ae147c78.png",
        },

        { name: "twitter:card", content: "summary_large_image" },
        // { name: "twitter:title", content: myBus.name},
        // { name: "twitter:description", content:  myBus.description},
        {
          name: "twitter:url",
          content: `https://snawnaw.com/${locale}/${myBus.id}`,
        },
        {
          name: "twitter:image:src",
          content: myBus.media
            ? myBus.media[0].url
            : "https://snawnaw.com/img/logo3.ae147c78.png",
        },
      ],
    };
  },
  data() {
    return {
      openedTab: "",
      businessHost: null,
      outerCat: {
        name: "",
        id: "",
      },
      swiperOptions: {
        autoplay: false,
        slidesPerView: "auto",
        spaceBetween: 10,
      },
      placeHolderImg: require("../assets/images/logo-icon.png"),
    };
  },
  components: {
    Profile,
    Amenities,
    Reviews,
    BusinessProducts,
    // BusinessGallery,
    NoPlaces,
    Swiper,
    SwiperSlide,
    Stars,
    // SideFilter
  },
  props: ["isMobile"],
  mixins: [helpers],
  watch: {
    productCategories: function (oldCats, newCats) {
      if (oldCats && oldCats.length > 0) {
        this.chooseCat(
          this.productCategories[0].id,
          this.productCategories[0].name
        );
      }
    },
  },
  mounted() {
    // console.log(this.$route.params)
    if (
      !this.$route.params.tabID &&
      !this.businessHome &&
      this.loggedIn == true
    ) {
      this.getUserFavourites({
        api_token: cookies.get("user_token"),
        locale: this.$i18n.locale,
      });
      if (this.$route.params.BName == "products") {
        if (/^pid-/.test(this.$route.params.TName)) {
          this.goFetch("rel", this.$route.params.TName.replace(/pid-/, ""));
        } else {
          this.goFetch("rel");
        }
      } else {
        this.goFetch();
      }
      $("#businessCarousel").carousel({
        interval: 3000,
      });
    }
    this.defaultTab();
    this.changeTab(this.$route.params.tabID);
    this.$store.state.searchForm = false;
    if (
      pluginOptions.businesses &&
      pluginOptions.businesses[location.hostname]
    ) {
      this.businessHost = pluginOptions.businesses[location.hostname];
      this.$route.params.id = this.businessHost.id;
      this.$store.dispatch("getSlides", this.businessHost.slides);
    }
    this.$loadScript("../../../js/bootstrap.bundle.js");
    this.getBusinessById({
      business_id: this.$route.params.id,
      lang: this.$i18n.locale,
    })
      .then((res) => {
        // console.log(location.href   )
        if (this.business.categories > 0) {
          this.getBusinessProducts({
            business_id: this.$route.params.id,
            category_id: this.business.categories[0].id,
            lang: this.$i18n.locale,
          }).then();
        }
        this.getHotDeals({
          business_id: this.$route.params.id,
          lang: this.$i18n.locale,
        });
      })
      .catch((err) => console.log(err));
  },
  methods: {
    ...mapActions([
      "getHotDeals",
      "getSlides",
      "getBusinessProducts",
      "getBusinessById",
      "getUserFavourites",
    ]),
    goMainCates() {
      window.open("https://snawnaw.com/en/bc-50/restaurants", "_blank");
    },
    // shareBusiness() {
    //   navigator.clipboard.writeText(window.location.href);
    // },
    goReserve(id, type) {
      this.$router.push({
        name: "Reservation",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    goCheckin(id, type) {
      this.$router.push({
        name: "Checkin",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    goCat(id) {
      localStorage.clear();
      this.$router.push({
        name: "CategoryId",
        params: {
          categoryId: id,
          locale: this.$i18n.locale,
        },
      });
    },
    changeTab(id, insideComp) {
      if (insideComp) {
        this.goFetch(id);
      }
      this.openedTab = id;
    },
    goFetch(id, pid) {
      this.$router.push({
        name: "BusinessFetch",
        params: {
          locale: this.$i18n.locale,
          id: this.$route.params.id,
          BName: this.$route.params.BName,
          TName: this.$route.params.TName,
          tabID: id,
          show: this.$route.params.show,
          productId: pid || this.$route.params.productId,
        },
      });
      this.$route.params.product_id = 0;
    },
    defaultTab() {
      if (this.businessHome) {
        this.openedTab = "desc";
      }
    },
    carouselNext() {
      $("#homeCarousel").carousel("next");
    },
    carouselPrev() {
      $("#homeCarousel").carousel("prev");
    },
    goApple() {
      window.open(this.businessHost.ios, "_blank");
    },
    goAndroid() {
      window.open(this.businessHost.googlePlay, "_blank");
    },
    // goFacebook() {
    //   window.open(this.businessHost., '_blank')
    // },
    goGallery() {
      window.open(this.businessHost.huawei, "_blank");
    },
    goLink(link) {
      window.open(link, "_blank");
    },
    pageReset(name) {
      this.currentPage = 1;
      this.categoryName = name;
    },
    chooseCat(id, name) {
      if (this.businessHome) {
        this.changeTab("desc");
      }
      var elmnt = document.getElementById("rel");
      if (this.width == "sm") {
        elmnt.scrollIntoView(true);
      } else if (this.businessHome) {
        window.scroll({
          top: 700,
          left: 0,
          behavior: "smooth",
        });
      }
      this.outerCat.name = name;
      this.outerCat.id = id;
      this.$store.state.outerCat = { ...this.outerCat };
    },
  },

  computed: {
    ...mapGetters([
      "business",
      "businessProducts",
      "width",
      "busHotDeals",
      "loading",
      "profileLoading",
      "slides",
      "businessProducts",
      "favourites",
      "loggedIn",
    ]),
    businessHome() {
      return pluginOptions.businesses &&
        pluginOptions.businesses[location.hostname]
        ? pluginOptions.businesses[location.hostname]
        : null;
    },
    productCategories() {
      return this.business && this.business.categories
        ? this.business.categories
        : [];
    },
    swiper() {
      return this.$refs.categoriesSlider.$swiper;
    },
  },
};
</script>
