import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueI18nFilter from 'vue-i18n-filter'
import cookies from 'vue-cookies';
import { pluginOptions } from "../vue.config";


Vue.use(VueI18n)
Vue.use(VueI18nFilter)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const businessHome = () => {
  return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
};
const userLang = navigator.language || navigator.userLanguage;
let language;
if (userLang.startsWith('en')) {
  language = 'en'
} else if (userLang.startsWith('ar')) {
  language = 'ar'
} else {
  language = 'de'
}
let i18nObject = {};
if (businessHome() != null) {
  i18nObject = {
    locale: cookies.get(businessHome().style + '_lang') || language,
    fallbackLocale: cookies.get(businessHome().style + '_lang') || language,
    messages: loadLocaleMessages()
  }
} else {
  i18nObject = {
    locale: cookies.get('lang') || language,
    fallbackLocale: cookies.get('lang') || language,
    messages: loadLocaleMessages()
  }
}

export default new VueI18n(i18nObject)
