<template>
  <div class="container px-0">
    <form @submit.prevent="changeLocation">
      <div class="row  mb-md-0 px-0 mx-auto">
        <div class="col-12 px-0 input-group px-md-2 px-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white"><i class="fa fa-map-marker text-muted"></i></span>
          </div>
          <input type="number" :class="t_ar" class="form-control text-input-location" v-model="location" maxlength="5"
            :placeholder="$t('col_postalcode')" @change.prevent="changeLocation" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import cookies from "vue-cookies";

export default {
  data() {
    return {
      location: "",
    };
  },
  props: ["defaultAddress", "closeModal"],
  methods: {
    ...mapActions([
      "getGeolocation",
      "getHomeData",
      "setDefaultAddress",
      "retrieveUser",
    ]),
    ...mapMutations(["showMsg"]),
    changeLocation(code) {
      this.location = this.location.replace(/ /g, "");
      if (this.location.length == 0) {
        this.getHomeData({
          locale: this.$i18n.locale,
          all_categories: true,
          // latitude: this.geolocation.lat,
          // longitude: this.geolocation.lng,
        });
        this.showMsg({
          text: this.$t("locationEmpty"),
          close: false,
          type: "loader",
          loaderClass: 'success'
        });
        return 0;
      } else if (this.location.length == 4) {
        this.location = "0" + this.location;
      } else if (this.location.length != 5) {
        // console.log('postal code must be 5 numbers')
        this.showMsg({
          text: this.$t("PostalCode5Char"),
          close: false,
          type: "warning",
        });
        return 0;
      }
      this.getGeolocation(this.location)
        .then((res) => {
          this.showMsg({
            text: this.$t("LocationFound"),
            close: false,
            type: "success",
          });
          this.getHomeData({
            locale: this.$i18n.locale,
            all_categories: true,
            latitude: this.geolocation.lat,
            longitude: this.geolocation.lng,
          });
          if (this.defaultAddress) {
            this.setDefaultAddress(cookies.get("user_token"));
            this.retrieveUser();
            this.closeModal();
          }
        })
        .catch((err) => {
          this.showMsg({
            text: this.$t("LocationNotFound"),
            close: false,
            type: "warning",
          });
        });
    },
  },

  computed: {
    ...mapGetters(["geolocation"]),
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },
};
</script>