var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.businessHost && Object.entries(_vm.businessHost.links).length > 0
      ? true
      : !_vm.businessHost
      ? true
      : false
  )?_c('div',{staticClass:"home-icons"},[(_vm.inheader)?_c('div',{staticClass:"download-app text-center d-block",attrs:{"dir":_vm.$i18n.locale == 'ar' ? 'rtl' : 'ltr'}},[_vm._v(" "+_vm._s(_vm.$t("download app"))+" ")]):_vm._e(),_c('div',{staticClass:"\n      icons-wrapper\n      px-3\n      py-2\n      px-1\n      d-flex\n      flex-column\n      pointer\n      align-items-center\n    ",class:_vm.openMenu ? 'active' : ''},[_c('div',{staticClass:"toggler",class:_vm.openMenu ? 'active' : '',on:{"click":_vm.toggleMainIcons}},[_c('i',{staticClass:"fa fa-chevron-right"})]),_c('div',{staticClass:"my-3",on:{"click":_vm.goApple}},[_c('i',{staticClass:"fa fa-apple"})]),_c('div',{staticClass:"my-3",on:{"click":_vm.goAndroid}},[_c('i',{staticClass:"fa fa-android"})]),_c('div',{staticClass:"my-3",on:{"click":_vm.goGallery}},[_c('img',{attrs:{"src":require("../assets/images/logo/appgallery.png")}})]),(_vm.businessHost && _vm.businessHost.links.instagram ? true : false)?_c('div',{staticClass:"my-3",on:{"click":_vm.goInstagram}},[_c('i',{staticClass:"fa fa-instagram"})]):_vm._e(),(
        _vm.businessHost && _vm.businessHost.links.facebook
          ? true
          : !_vm.businessHost
          ? true
          : false
      )?_c('div',{staticClass:"my-3",on:{"click":_vm.goFacebook}},[_c('i',{staticClass:"fa fa-facebook"})]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }