<template>
  <div class="card card-item m-0 p-0">
    <div>
      <div class="row">
        <div class="col-12 px-0">
          <div class="card-item-image" @click="goProfile(item.id)">
            <img :src="
              item.media.length > 0
                ? item.media[0].url
                : 'https://media.tarkett-image.com/large/TH_24567081_24594081_24596081_24601081_24563081_24565081_24588081_001.jpg'
            " class="card-img-top" />
          </div>
          <div v-if="loggedIn" class="item-favourite" @click="favourited ? favouriteDelete() : favouriteAdd(item)">
            <i class="fa" :class="favourited ? 'fa-heart' : 'fa-heart-o'"></i>
          </div>
          <div class="item-address">
            <button class="btn-pri text-ellipsis">{{ item.area }}</button>
          </div>
          <div class="item-directions">
            <button class="btn-def" @click="goMap(item.location.address_link)"
              :disabled="item.location.address_link ? false : true">
              <div class="icon">
                <i class="fa fa-arrows"></i>
              </div>
              <!-- <i class="fa fa-map-marked"></i> --> {{ $t("directions") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body card-details" :class="ar ? 'text-right' : 'text-left'">
        <div class="card-title details-title mb-n1">
          <div class="d-flex justify-content-between" :class="
            $i18n.locale == 'ar' ? 'flex-row-reverse' : 'flex-row-reverse'
          ">
            <button class="btn-icon-pri-lg" :disabled="item.location.phone ? false : true"
              @click="goPhone(item.location.phone)">
              <i class="fa fa-phone"></i>
            </button>
            <div class="text-ellipsis main-title" @click="goProfile(item.id)"> {{ item.name }} </div>
          </div>
        </div>
        <div class="card-text details-categories sub-title text-ellipsis" @click="goCategory(item.category.id)"> {{
            htmlDecode(item.category.name)
        }} </div>
        <Stars :stars="item.rate" />
      </div>
    </div>
  </div>
</template>

<script>
import Stars from "@/components/misc/Stars";
import { helpers } from "../../Helpers";
import { mapActions, mapGetters, mapMutations } from "vuex";
import cookies from "vue-cookies";

export default {
  data() {
    return {
      favourited: false,
      favID: "",
    };
  },
  components: {
    Stars,
  },
  mixins: [helpers],
  props: ["item"],
  mounted() {
    this.checkFavourites(this.item);
  },
  computed: {
    ...mapGetters(["loggedIn", "user", "favourites"]),
    ar() {
      return this.$i18n.locale == "ar";
    },
  },

  methods: {
    ...mapActions(["isFavourite", "addFavourite", "deleteFavourite"]),
    ...mapMutations(["showMsg"]),
    goReserve(id) {
      this.$router.push({
        path: `/${this.$i18n.locale}/${id}`,
      });
    },
    goProfile(id) {
      this.$router.push({
        // name: 'BusinessInfoByName',
        name: "BusinessProfile",
        params: {
          locale: this.$i18n.locale,
          id: id,
        },
      });
    },
    goMap(link) {
      // window.location.href = link;
      window.open(link, "_blank");
    },
    goPhone(link) {
      // window.location.href = ;
      window.open(`tel:${link}`, "_blank");
    },
    goCategory(id) {
      this.$router.push({
        name: "CategoryId",
        params: {
          categoryId: id,
          locale: this.$i18n.locale,
        },
      });
    },
    checkFavourites(item) {
      let check = this.favourites.find((el) =>
        el.business ? el.business.id == item.id : undefined
      );
      // console.log("🚀 ~ file: Highlited.vue ~ line 102 ~ checkFavourites ~ check", check);
      if (check) {
        this.favID = check.id;
        this.favourited = true;
        // console.log("🚀 ~ file: Highlited.vue ~ line 106 ~ checkFavourites ~ favourited", this.favourited, this.favID)
      }
    },
    favouriteAdd(item) {
      this.addFavourite({
        business_id: item.id,
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("AddedtoFavourites"),
          });
          this.favID = res.data.data.id;
          this.favourited = true;
        })
        .catch((err) => {
          console.log(err);
          this.showMsg({
            type: "error",
            text: this.$t("AddtoFavouritesError"),
          });
        });
    },
    favouriteDelete() {
      this.deleteFavourite({
        id: this.favID,
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("FavItemDeleted"),
          });
          this.favID = "";
          this.favourited = false;
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("FavItemDeletedError"),
          });
        });
    },
  },
};
</script>