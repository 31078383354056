import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../i18n';
import cookies from 'vue-cookies';
// import LoadScript from 'vue-plugin-load-script';
import mainRoutes from "./main"
import businessRoutes from "./business"
import { pluginOptions } from "/vue.config"
import store from '../store/index';

Vue.use(VueRouter)
let currentRoutes = pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? businessRoutes : mainRoutes;

var supportedLocales = i18n.availableLocales;
const businessHome = () => {
  return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
};
const routes = [
  {
    path: '*',
    redirect: `/${i18n.locale}`,
  },
  {
    path: '/:id',
    beforeEnter: (to, from, next) => {
      const dest = router.resolve({
        path: `/${i18n.locale}/${to.params.id}`
      });

      if (dest.route.name == 'BusinessReservations' && Number.parseInt(to.params.id) != NaN) {
        next({
          path: `/${i18n.locale}/${to.params.id}/info`
        });
      } else {
        if (cookies.isKey('lang')) {
          if (businessHome() != null) {
            var locale = cookies.get(businessHome().style + '_lang');
          } else {
            var locale = cookies.get('lang');
          }
          if (supportedLocales.includes(to.params.id)) {
            locale = to.params.id;
          }
        } else {
          var locale = to.params.id;
        }
        next({
          name: `Home`,
          params: {
            locale,
          }
        });
        if (businessHome() != null) {
          cookies.set(businessHome().style + '_lang', locale);
        } else {
          cookies.set('lang', locale);
        }
      }

    }
  },
  {
    path: '/:locale',
    component: {
      render(c) { return c('router-view') }
    },
    beforeEnter: (to, from, next) => {
      var locale = to.params.locale;

      if (!supportedLocales.includes(locale)) return next('de');
      if (i18n.locale !== locale) {
        i18n.locale = locale;
      }
      return next();
    },
    children: currentRoutes
  }
]

function noUpTopRoutes(route) {
  const routesList = ['Privacy', 'ProductWraper', 'ProductFetch', 'ProductList'];
  return routesList.includes(route)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (noUpTopRoutes(from.name) || noUpTopRoutes(to.name)) {
      return 0;
    } else return { x: 0, y: 0 }
  },
});

router.beforeEach((to, from, next) => {
  if (!businessHome()) {
    if (to.matched.some(record => record.meta.requireAuth == true)) {
      store.dispatch('getLoggedIn');
      if (store.getters.loggedIn == false) {
        next({
          name: 'Login',
          params: {
            locale: i18n.locale,
          }
        })
      } else {
        next()
      }
    } else {
      if ((to.name == 'Login' || to.name == 'Register') && store.getters.loggedIn == true) {
        next({
          name: 'Home',
          params: {
            locale: i18n.locale
          }
        })
      } else {
        next();
      }
    }
  } else {
    next()
  }


})

export default router;
