<template>
  <div class="container" id="rev">
    <div class="row mt-2">
      <div class="col-12 px-0">
        <h3 class="listing-section" :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"> {{ $t("reviews") }}
          <span class="rating-no">({{ business && business.reviews ? business.reviews.length : 0 }})</span>
        </h3>
        <WriteReview />
        <div class="row">
          <div class="col-12 px-0" v-if="business && business.reviews && business.reviews.length > 0">
            <!-- <WriteReview /> -->
            <!-- Start Review Author Block -->
            <div class="row">
              <div class="col-12 px-0" v-for="rev in paginatedReviews" :key="rev.id">
                <Review :item="rev" />
              </div>
            </div>
            <div class="col-12 px-0">
              <Pagination :showPage="showPage" :lastPage="lastPage" :currentPage="currentPage" class="pt-4" />
            </div>
            <!-- End Review Author Block -->
          </div>
          <div v-else class="col-12 px-0">
            <NoPlaces :msg="$t('noReviews')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from "@/components/misc/Stars.vue";
import WriteReview from "@/components/forms/WriteReview.vue";
import Review from "@/components/Listings/Review";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import Pagination from "@/components/misc/Pagination.vue";

import { helpers } from "../../Helpers";
export default {
  data() {
    return {
      currentPage: 1,
      page_size: 10,
    };
  },
  name: "Profile",
  props: ["business"],
  mixins: [helpers],

  components: {
    Stars,
    Review,
    WriteReview,
    NoPlaces,
    Pagination,
  },

  mounted() {
    this.scroll();
  },

  computed: {
    lastPage() {
      if (
        this.business && this.business.reviews && this.business.reviews.length / this.page_size < 1 ||
        this.business && this.business.reviews && this.business.reviews.length == this.page_size
      ) {
        return 1;
      } else if (
        this.business && this.business.reviews && this.business.reviews.length / this.page_size > 1 &&
        this.business && this.business.reviews && this.business.reviews.length % this.page_size > 0
      ) {
        return Math.floor(this.business.reviews.length / this.page_size) + 1;
      } else return this.business.reviews.length / this.page_size;
    },
    width() {
      return this.$store.getters.width;
    },
    paginatedReviews() {
      return this.business.reviews.slice(
        (this.currentPage - 1) * this.page_size,
        this.currentPage * this.page_size
      );
    },
  },

  methods: {
    nextPage() {
      this.page++;
    },
    scroll() {
      window.onscroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          this.nextPage();
        }
      };
    },
    showPage(page) {
      this.currentPage = page;
      return this.business.reviews.slice(
        (this.currentPage - 1) * this.page_size,
        this.currentPage * this.page_size
      );
    },
  },
};
</script>

<style scoped>
.author-name {
  margin: 10px 5px;
}
</style>