<template>
  <div class="cat-card pointer" @click="goCat(category.id)">
    <!--  <div 
         class='cat-card-head'
         :class="ar ? ['text-left'] : 'text-right'"  
      >
        <i 
          class="fa"
          :class="ar ? ['fa-arrow-left'] : 'fa-arrow-right'"
        ></i>
      </div> -->
    <div class="cat-card-body p-2 px-4" :class="ar ? ['text-right'] : 'text-left'" :title="category.name">
      <div class="d-flex justify-content-end mt-2 mx-2">
        <i :class="`fa fa-arrow-${ar ? 'left' : 'right'}`"></i>
      </div>
      <div class="d-flex align-items-center flex-column justify-content-center">
        <!-- <div class="my-4 mt-8">
            <mdicon class="mdicon" height='68' width='68' :name='getIcon(category.id)' />
        </div> -->
        <!-- <div class="text-center" v-if='$route.name == "AllCategories"'>
          <img :src='category && category.icon ? category.icon  : category.image' height='70' width='70' class="my-3 mt-4" />
        </div> -->
        <!-- <div class="text-center" v-else>
          <img :src='category && category.media && category.media.length > 0 ? category.icon_full_path || category.icon || category.media[0].url || placeHolderImg : category.icon_full_path || category.icon || placeHolderImg' height='70' width='70' class="my-3 mt-4" />
        </div> -->
        <img :src="catLogoUrl" :alt="category.name" width="40px" />
        <span class="mt-2 cat-name text-ellipsis" v-html="category.name"></span>
      </div>
      <!-- <div 
          v-if='Home'
          class="text-sec">{{category.items + ' ' + $t('item')}}
        </div>
        <div v-else class="text-sec">
          {{category.text}}
        </div>-->
    </div>
  </div>
</template>

<script>
import { helpers } from "../../Helpers";
export default {
  name: "CategoryCard",
  props: ["category"],
  mixins: [helpers],
  data() {
    return {
      placeHolderImg: require("../../assets/images/logo-icon.png"),
    };
  },
  methods: {
    goCat() {
      this.$router.push({
        name: "CategoryId",
        params: {
          categoryId: this.category.id,
          locale: this.$i18n.locale,
        },
      });
    },
  },
  computed: {
    ar() {
      return this.$i18n.locale == "ar";
    },
    Home() {
      return this.$route.name == "Home";
    },
    catLogoUrl() {
      return `${this.category.media && this.category.media[0]
        ? this.category.media[0].url
        : this.category.image
          ? this.category.image
          : this.category.icon
        }`;
    },
  },
};
</script>