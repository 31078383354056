<template>
  <div class="dropdown languages">
    <span class="dropdown-toggle pointer" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      <!-- {{$t('languages')}} -->
      <span class="lang-name">
        <img class="langs-select" :src="flag($i18n.locale)" />
        <span>{{ $i18n.locale.toUpperCase() }}</span>
      </span>
    </span>
    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
      <a class="dropdown-item pointer" v-for="(lang, index) in locales" :key="index"
        :class="locale == lang ? 'active' : ''">
        <div @click="localeSwitch(lang)" class="locales">
          <!-- CloseNav(); -->
          <!-- <i class="fa fa-check-circle" v-show="locale == lang"></i> -->
          <img class="langs-select" :src="flag(lang)" />
          <span>{{ langText(lang) }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locales: this.$i18n.availableLocales,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    localeSwitch(locale) {
      if (locale !== this.$i18n.locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: { locale } });
        this.$router.push(to.location);
        this.$router.go();
      }
    },
    flag(lang) {
      return require("../assets/images/flags/" + lang + "circle.png");
    },
    langText(lang) {
      if (lang == "ar") {
        return "العربية";
      } else if (lang == "en") {
        return "English";
      } else if (lang == "de") {
        return "Deutsch";
      }
    },
  }
}
</script>