<template>
  <div
    class="listing-amenities"
    :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
    v-if="!businessHost && business.tags[0]"
  >
    <h3
      class="listing-section"
      :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
    >
      {{ $t("tags") }}
    </h3>
    <div class="">
      <div class="">
        <li class="tag-badge" v-for="tag in business.tags" :key="tag.id">
          <i class="fa fa-tag"></i>
          <span class="cat-badge-text">{{
            tag.name ? tag.name : tag.translations[0].name
          }}</span>
        </li>
      </div>
    </div>
    <!-- <NoPlaces v-else msg='No Tags For This Business' /> -->
  </div>
</template>

<script>
import { helpers } from "../../Helpers";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import { pluginOptions } from "/vue.config";

export default {
  name: "Amenities",
  props: ["business"],
  mixins: [helpers],
  data() {
    return {
      businessHost: null,
    };
  },
  mounted() {
    if (
      pluginOptions.businesses &&
      pluginOptions.businesses[location.hostname]
    ) {
      this.businessHost = pluginOptions.businesses[location.hostname];
      console.log(this.businessHost.slides);
    }
  },
  components: {
    NoPlaces,
  },
};
</script>