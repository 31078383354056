<template>
  <section class="container px-0">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between mb-4"
          :class="$i18n.locale == 'ar' ? 'flex-row' : 'flex-row-reverse'">
          <div class="align-self-end d-flex">
            <button class="
                btn-icon-black
                d-flex
                align-items-center
                justify-content-center
              " @click="ar ? swiper.slideNext() : swiper.slidePrev()" v-if="width != 'sm'">
              <i class="fa fa-chevron-left"></i>
            </button>
            <button class="
                btn-icon-black
                d-flex
                align-items-center
                justify-content-center
              " @click="ar ? swiper.slidePrev() : swiper.slideNext()" v-if="width != 'sm'">
              <i class="fa fa-chevron-right"></i>
            </button>
          </div>
          <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'" class="mouse">
            <div class="header-home pointer" @click="goSearch"> {{ $t("orderNow") }} </div>
            <div class="subheader-home"> {{ $t("subheader3") }} </div>
          </div>
        </div>
      </div>
      <div class="text-center col-12 px-0">
        <div class="row px-0">
          <div class="col px-0">
            <NoPlaces v-if="!exist" :msg="$t('noPlaces')" :imgWith="true"
              :img="require('../assets/images/empty.png')" />
            <Swiper ref="orderNowSwiper" v-else class="swiper pb-4" :options="swiperOptions"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <SwiperSlide style="padding: 0 12px !important" class="col-auto order-now-product-slide"
                v-for="item in orderNow" :key="item.id">
                <Highlited :item="item" />
              </SwiperSlide>
              <SwiperSlide class="col-auto px-0" style="padding: 0 12px !important">
                <div class="mainBody-High shipping-card-more-btn shadow-eff w-100" @click="goSearch">
                  <div class="text-center justify-content-center d-flex">
                    <span> {{ $t("SeeMore") }} </span>
                    <i class="fa align-self-center mx-2" :class="
                      $i18n.locale == 'ar'
                        ? ['fa-arrow-left', '']
                        : 'fa-arrow-right '
                    "></i>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NoPlaces from "@/components/misc/NoPlaces.vue";
import Highlited from "@/components/Listings/Highlited.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import { helpers } from "../Helpers";

export default {
  name: "OrderNow",

  components: {
    NoPlaces,
    Highlited,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiperOptions: {
        autoplay: false,
        slidesPerView: "auto",
        spaceBetween: 0,
      },
    };
  },

  mixins: [helpers],
  props: ["orderNow", "exist"],
  computed: {
    swiper() {
      return this.$refs.orderNowSwiper.$swiper;
    },
    width() {
      return this.$store.getters.width;
    },
    ar() {
      return this.$i18n.locale == "ar";
    },
  },
  methods: {
    goSearch() {
      localStorage.clear();
      this.$router.push({
        name: "SearchResults",
      });
    },
  },
  mounted() {
    setTimeout(() => {
      const slides = document.querySelectorAll(".order-now-product-slide");
      if (slides && slides.length > 0) {
        const moreBtn = document.querySelector(".shipping-card-more-btn");
        if (moreBtn) {
          moreBtn.style.height = slides[slides.length - 1].clientHeight + "px";
        }
      }
    }, 2000);
  },
};
</script>

<style scoped>
.trending:after {
  background-image: unset;
}
</style>