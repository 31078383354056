<template>
  <div
    class="row pointer shadow-eff p-lg-3 p-0"
    @click="show != 'Yes' ? openModal(item.id) : false"
    :class="[
      large ? 'pop-card-lg' : 'pop-card',
      ar ? 'flex-row-reverse' : 'flex-row',
    ]"
  >
    <div
      :class="
        large
          ? [
              'col-12',
              'product-image',
              'px-0',
              'text-center',
              placeHolderImg.includes('http') ? '' : 'placeholder',
            ]
          : [
              'col-12',
              'col-sm-5',
              'product-image',
              'px-0',
              'text-center',
              placeHolderImg.includes('http') ? '' : 'placeholder',
            ]
      "
    >
      <!-- {{ item.image }} -->
      <img @click="openModal(item.id)" :src="placeHolderImg" />
      <div class="main-fav-btn" :class="$i18n.locale" v-if="loggedIn">
        <div
          @click="favourited ? favouriteDelete() : favouriteAdd(item)"
          class=""
          :class="ar ? 'f-left' : 'f-right'"
        >
          <i class="fa" :class="favourited ? 'fa-heart' : 'fa-heart-o'"></i>
        </div>
      </div>
    </div>
    <div
      :class="large ? ['col-12'] : ['col-12', 'col-sm-7']"
      class="d-flex flex-column justify-content-between px-2"
    >
      <div
        class="
          row
          mt-2
          mb-4
          justify-content-between
          align-items-center
          flex-nowrap
        "
        :class="ar ? 'flex-row-reverse' : ''"
      >
        <div
          class="pop-head m-0 p-0 text-ellipsis"
          :dir="ar ? 'rtl' : 'ltr'"
          :class="[ar ? 'text-right' : 'text-left']"
          :title="item.name"
          @click="openModal(item.id)"
        >
          {{ item.name }}
        </div>
        <div
          v-if="show == 'Yes'"
          @click="openModal(item.id)"
          class="text-nowrap"
          :class="[
            large ? 'opt-label' : ['data-sub', 'text-center'],
            ar && myWidth != 'sm' ? 'text-right' : 'text-left',
          ]"
        >
          <span class="mx-1 my-0">
            {{
              item.price_after_sale > 0 ? item.price_after_sale : item.price
            }}
            €
          </span>
          <span
            class="old-price my-0 mx-1"
            v-if="large && item.price_after_sale > 0"
          >
            {{ item.price }} €
          </span>
        </div>
      </div>
      <div
        class="row align-items-center"
        v-if="show == 'Yes'"
        :class="ar ? '' : 'flex-row-reverse'"
      >
        <div
          class="px-0 col-12 px-md-1"
          :class="
            ar && myWidth != 'sm'
              ? 'text-right'
              : myWidth == 'sm'
              ? 'text-center mb-2'
              : 'text-left'
          "
        >
          <!-- <button class="accecpt-item-btn btn" v-if="startAdd" @click="appendInCart">
            <i class="fa fa-check"></i> </button> -->
          <button
            v-if="!startAdd"
            type="button"
            class="add-to-cart-btn starter w-100"
            @click="addToCart"
            :style="startAdd ? 'pointer-events: none' : ''"
          >
            <span class="add-to-cart-span">
              <i class="fa fa-shopping-cart add-to-cart-svg"></i>
              {{ $t("addToCart") }}
            </span>
          </button>
          <button
            v-if="startAdd"
            type="button"
            class="add-to-cart-btn w-100"
            :style="startAdd ? 'pointer-events: none' : ''"
          >
            <div class="qyt">
              <i class="fa fa-plus" @click="appendInCart(true)"></i>
              <input
                type="number"
                disabled
                v-model="quantity"
                class="text-center"
                :placeholder="$t('How Many ?')"
              />
              <i
                class="fa fa-minus"
                v-if="quantity != 1"
                @click="appendInCart(false)"
              ></i>
              <i class="fa fa-trash" v-else @click="appendInCart(false)"></i>
            </div>
          </button>
          <!-- <button class="accecpt-item-btn btn" v-if="startAdd" @click="checkAndRemove(item.id)">
            <i class="fa fa-times"></i> </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from "@/components/misc/Stars";
import { mapActions, mapGetters, mapMutations } from "vuex";
import cookies from "vue-cookies";

export default {
  mounted() {
    this.imageSrc(this.item);
    this.checkFavourites(this.item);
    this.checkIfInCart();
  },
  components: {
    Stars,
  },
  data() {
    return {
      placeHolderImg: require("../../assets/images/logo.png"),
      favourited: false,
      favID: "",
      options: {},
      startAdd: false,
      quantity: 1,
    };
  },
  watch: {
    favourites: function () {
      this.checkFavourites(this.item);
    },
    cartItems: function () {
      this.checkIfInCart();
    },
  },
  props: ["item", "openModal", "show", "large"],
  computed: {
    // ...mapGetters([]),
    startAddingToCart() {
      return this.startAdd;
    },
    ...mapGetters([
      "product",
      "loading",
      "modalLoading",
      "loggedIn",
      "favourites",
      "loggedIn",
      "cartItems",
    ]),
    myWidth() {
      return this.$store.getters.width;
    },
    ar() {
      return this.$i18n.locale == "ar";
    },
    localStorageShow() {
      return localStorage.getItem("show");
    },
  },
  methods: {
    ...mapActions([
      "addFavourite",
      "deleteFavourite",
      "isFavourite",
      "getUserFavourites",
      "getAllCartItems",
      "showProduct",
    ]),
    checkIfInCart() {
      const inCart = this.cartItems.find((el) => el.product_id == this.item.id);
      if (inCart) {
        this.startAdd = true;
        this.quantity = inCart.quantity;
        return true;
      } else {
        this.quantity = 1;
        this.startAdd = false;
        return false;
      }
    },
    ...mapMutations([
      "removeFromVCart",
      "addToVCart",
      "cartBusinessId",
      "showMsg",
    ]),
    // ...mapMutations(["addToVCart", "cartBusinessId", "showMsg"]),
    // ...mapActions([
    // "addFavourite",
    // "deleteFavourite",
    // "isFavourite",
    // ]),
    testImage(URL) {
      return new Promise((resolve, reject) => {
        var tester = new Image();
        tester.src = URL;
        tester.onload = () => {
          resolve(URL);
        };
        tester.onerror = () => {
          reject();
        };
      });
    },
    addToCart(e) {
      if (
        (e.target.classList.contains("add-to-cart-btn") ||
          e.target.classList.contains("add-to-cart-span") ||
          e.target.classList.contains("add-to-cart-svg")) &&
        this.startAdd == false
      ) {
        this.appendInCart(true);
      } else {
        return false;
      }
    },
    checkAndRemove(id) {
      const inCart = this.cartItems.find((el) => el.product_id == id);
      if (inCart) {
        this.removeFromVCart(id);
        this.showMsg({
          close: false,
          text: this.$t("removed from cart"),
          type: "error",
        });
        this.startAdd = false;
        this.getAllCartItems();

        this.quantity = 1;
      }
    },
    imageSrc(item) {
      if (item.image) {
        this.testImage(item.image)
          .then((res) => {
            this.placeHolderImg = item.image;
          })
          .catch((err) => false);
      } else if (item.photo) {
        this.testImage(item.photo.thumb)
          .then((res) => {
            this.placeHolderImg = item.photo.thumb;
          })
          .catch((err) => false);
      } else return false;
    },
    checkFavourites(item) {
      let check = this.favourites.find((el) => el.product_id == item.id);
      if (check) {
        this.favID = check.id;
        this.favourited = true;
      }
    },
    favouriteAdd(item) {
      this.addFavourite({
        product_id: item.id,
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("AddedtoFavourites"),
          });
          this.favID = res.data.data.id;
          this.favourited = true;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("AddtoFavouritesError"),
          });
        });
    },
    favouriteDelete() {
      this.deleteFavourite({
        id: this.favID,
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("FavItemDeleted"),
          });
          this.favID = "";
          this.favourited = false;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("FavItemDeletedError"),
          });
        });
    },
    buildItem() {
      if (Object.entries(this.options).length > 0) {
        var item = {
          name: this.item.name,
          product_id: this.item.id,
          options: this.options,
          quantity: this.quantity,
          tax: this.item.tax != null ? this.item.tax : 0,
        };
      } else {
        var item = {
          name: this.item.name,
          product_id: this.item.id,
          price:
            this.item.price_after_sale > 0
              ? this.item.price_after_sale
              : this.item.price,
          quantity: this.quantity,
          tax: this.item.tax != null ? this.item.tax : 0,
        };
      }
      return item;
    },
    appendInCart(inc) {
      const savedQyt = this.quantity;
      if (!this.startAdd && this.checkIfInCart()) {
        this.showMsg({
          close: false,
          text: this.$t("already in cart"),
          type: "success",
        });
        return;
      }
      if (this.startAdd && inc) {
        this.quantity++;
      } else if (inc === false) {
        if (this.quantity - 1 != 0 && this.quantity != 1) {
          this.quantity--;
        } else {
          this.checkAndRemove(this.item.id);
          return;
        }
      }
      if (cookies.isKey("BusId")) {
        const inCart = this.cartItems.find(
          (el) => el.product_id == this.item.id
        );
        if (!inCart || (inCart && inCart.quantity != this.quantity)) {
          if (this.$route.params.id == cookies.get("BusId")) {
            this.startAdd = true;
            let item = this.buildItem();
            this.addToVCart(item);
            this.getAllCartItems();
            this.showMsg({
              close: false,
              text:
                inCart && inCart.quantity == savedQyt
                  ? this.$t("updated in cart")
                  : this.$t("addedCart"),
              type: "success",
            });
            $(".modal").modal("hide");
          } else {
            let item = this.buildItem();
            this.showMsg({
              close: true,
              loaderClass: null,
              text: this.$t("cartWarning"),
              type: "error",
              action: "goCheckout",
              condition: 4,
              business_id: this.$route.params.id,
              item,
            });
          }
        } else {
          this.showMsg({
            close: false,
            text: this.$t("already in cart"),
            type: "success",
          });
        }
      } else {
        this.cartBusinessId(this.$route.params.id);
        this.appendInCart(true);
      }
    },
  },
};
</script>