<template>
  <section class="container px-0">
    <div class="row">
      <div class="col-12 px-2">
        <div class="d-flex justify-content-between  mb-4"
          :class="$i18n.locale == 'ar' ? 'flex-row' : 'flex-row-reverse'">
          <div class="align-self-end d-flex">
            <button class="
                btn-icon-black
                d-flex
                align-items-center
                justify-content-center
              " @click="ar ? swiper.slideNext() : swiper.slidePrev()" v-if="width != 'sm'">
              <i class="fa fa-chevron-left"></i>
            </button>
            <button class="
                btn-icon-black
                d-flex
                align-items-center
                justify-content-center
              " @click="ar ? swiper.slidePrev() : swiper.slideNext()" v-if="width != 'sm'">
              <i class="fa fa-chevron-right"></i>
            </button>
          </div>
          <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'">
            <div class="header-home-deals m-0" @click="goCategories"> {{ $t("categories") }} </div>
            <div class="subheader-home mouse"> {{ $t("line3") }} </div>
          </div>
        </div>
      </div>
      <div class="text-center col-12 px-0" v-if="categories.length > 0">
        <div class="row">
          <div class="col px-0">
            <Swiper class="swiper px-3 pb-3" ref="mySwiper" :options="swiperOptions"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
              <SwiperSlide class="col-auto px-0" v-for="cat in categories" :key="cat.id">
                <CategoryCard :category="cat" />
              </SwiperSlide>
              <SwiperSlide class="col-auto px-0">
                <div class="mainBody-Cat shadow-eff" @click="goCategories">
                  <div class="text-center justify-content-center d-flex">
                    <span> {{ $t("SeeMore") }} </span>
                    <i class="fa align-self-center mx-2" :class="
                      $i18n.locale == 'ar'
                        ? ['fa-arrow-left', '']
                        : 'fa-arrow-right '
                    "></i>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div v-else class="col-12">
        <NoPlaces :msg="$t('noResults')" />
      </div>
    </div>
  </section>
</template>

<script>
import CategoryCard from "@/components/Listings/CategoryCard.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import NoPlaces from "@/components/misc/NoPlaces.vue";

export default {
  props: ["categories", "all_categories"],
  components: {
    CategoryCard,
    Swiper,
    SwiperSlide,
    NoPlaces,
  },
  data() {
    return {
      swiperOptions: {
        autoplay: false,
        slidesPerView: "auto",
        spaceBetween: 20,
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    width() {
      return this.$store.getters.width;
    },
    ar() {
      return this.$i18n.locale == "ar";
    },
  },
  methods: {
    goCategories() {
      this.$router.push({
        name: "AllCategories",
      });
    },
  },
};
</script>
